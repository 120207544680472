/* eslint-disable camelcase */
import ico_agenda_share from './ico-agenda_share.svg?inline';
import ico_agenda_engrenagem from './ico-agenda-engrenagem.svg?inline';
import ico_agenda from './ico-agenda.svg?inline';
import ico_agenda2 from './ico-agenda2.svg?inline';
import ico_app_integration from './ico-app-integration.svg?inline';
import ico_avaliacoes from './ico-avaliacoes.svg?inline';
import ico_birthday from './ico-birthday.svg?inline';
import ico_calendar from './ico-calendar.svg?inline';
import ico_category_2 from './ico-category-2.svg?inline';
import ico_category from './ico-category.svg?inline';
import ico_changeOffice from './ico-changeOffice.svg?inline';
import ico_check from './ico-check.svg?inline';
import ico_clock from './ico-clock.svg?inline';
import ico_consultareagenda from './ico-consultareagenda.svg?inline';
import ico_consultas from './ico-consultas.svg?inline';
import ico_copy from './ico-copy.svg?inline';
import ico_cpf from './ico-cpf.svg?inline';
import ico_cross from './ico-cross.png';
import ico_dados_bancarios from './ico-dados-bancarios.svg?inline';
import ico_dash_atendidos from './ico-dash-atendidos.svg?inline';
import ico_dash_calendar from './ico-dash-calendar.svg?inline';
import ico_dash_canceladas from './ico-dash-canceladas.svg?inline';
import ico_dash_duracao from './ico-dash-duracao.svg?inline';
import ico_disconnect from './ico-disconnect.svg?inline';
import ico_doc from './ico-doc.png';
import ico_dollar from './ico-dollar.svg?inline';
import ico_edit from './ico-edit.svg?inline';
import ico_email from './ico-email.svg?inline';
import ico_endoscopia from './ico-endoscopia.svg?inline';
import ico_exameProcedure from './ico-exameProcedure.svg?inline';
import ico_alert from './ico-alert.svg?inline';
import ico_extrato from './ico-extrato.svg?inline';
import ico_fechar from './ico-fechar.svg?inline';
import ico_file_medical from './ico-file_medical.svg?inline';
import ico_file from './ico-file.svg?inline';
import ico_fullscreen from './ico-fullscreen.svg?inline';
import ico_google from './ico-google.svg?inline';
import ico_healthInsuranceCard from './ico-healthInsuranceCard.svg?inline';
import ico_help from './ico-help.svg?inline';
import ico_historico from './ico-historico.svg?inline';
import ico_homograma from './ico-homograma.svg?inline';
import ico_imprimir from './ico-imprimir.svg?inline';
import ico_info from './ico-info.svg?inline';
import ico_inperson_appointment from './ico-inperson-appointment.svg?inline';
import ico_inperson_office from './ico-inperson-office.svg?inline';
import ico_logout from './ico-logout.svg?inline';
import ico_lupa_agenda from './ico-lupa-agenda.svg?inline';
import ico_lupa_form from './ico-lupa-form.svg?inline';
import ico_lupa from './ico-lupa.svg?inline';
import ico_maintenance from './ico-maintenance.svg?inline';
import ico_map from './ico-map.svg?inline';
import ico_menu_agenda from './ico-menu-agenda.svg?inline';
import ico_menu_agendamentos from './ico-menu-agendamentos.svg?inline';
import ico_menu_conta from './ico-menu-conta.svg?inline';
import ico_menu_extrato from './ico-menu-extrato.svg?inline';
import ico_menu_healthplace from './ico-menu-healthplace.svg?inline';
import ico_menu_historico from './ico-menu-historico.svg?inline';
import ico_menu_modelos from './ico-menu-modelos.svg?inline';
import ico_menu_pacientes from './ico-menu-pacientes.svg?inline';
import ico_menu_relatorios from './ico-menu-relatorios.svg?inline';
import ico_menu_servicos from './ico-menu-servicos.svg?inline';
import ico_mic_off from './ico-mic-off.svg?inline';
import ico_mic_on from './ico-mic-on.svg?inline';
import ico_money from './ico-money.svg?inline';
import ico_name from './ico-name.svg?inline';
import ico_note from './ico-note.svg?inline';
import ico_notification from './ico-notification.svg?inline';
import ico_novaconsulta from './ico-novaconsulta.svg?inline';
import ico_pacientes from './ico-pacientes.svg?inline';
import ico_pagamento from './ico-pagamento.svg?inline';
import ico_painel from './ico-painel.svg?inline';
import ico_payment from './ico-payment.svg?inline';
import ico_pdf from './ico-pdf.png';
import ico_person from './ico-person.svg?inline';
import ico_plans from './ico-plans.svg?inline';
import ico_precos from './ico-precos.svg?inline';
import ico_procedimentos from './ico-procedimentos.svg?inline';
import ico_radiografia from './ico-radiografia.svg?inline';
import ico_receipt from './ico-receipt.svg?inline';
import ico_referral from './ico-referral.svg?inline';
import ico_relatorios from './ico-relatorios.svg?inline';
import ico_reserva_agenda from './ico-reserva_agenda.svg?inline';
import ico_rg from './ico-rg.svg?inline';
import ico_smiley from './ico-smiley.svg?inline';
import ico_star_avaliacao from './ico-star_avaliacao.svg?inline';
import ico_star_full from './ico-star_full.svg?inline';
import ico_star from './ico-star.svg?inline';
import ico_start_telemedicine from './ico-start-telemedicine.svg?inline';
import ico_sus from './ico-sus.svg?inline';
import ico_telemedicine_appointment from './ico-telemedicine-appointment.svg?inline';
import ico_telemedicine_office from './ico-telemedicine-office.svg?inline';
import ico_telephone from './ico-telephone.svg?inline';
import ico_time from './ico-time.svg?inline';
import ico_timer from './ico-timer.svg?inline';
import ico_tomografia from './ico-tomografia.svg?inline';
import ico_trash from './ico-trash.svg?inline';
import ico_ultrassom from './ico-ultrassom.svg?inline';
import ico_upload from './ico-upload.svg?inline';
import ico_user_times from './ico-user_times.svg?inline';
import ico_user from './ico-user.svg?inline';
import ico_usuarios from './ico-usuarios.svg?inline';
import ico_video_off from './ico-video-off.svg?inline';
import ico_video_on from './ico-video-on.svg?inline';
import ico_visibility_off from './ico-visibility_off.svg?inline';
import ico_visibility from './ico-visibility.svg?inline';
import ico_visit_link from './ico-visit-link.svg?inline';
import ico_whatsapp from './ico-whatsapp.svg?inline';
import ico_widgetCalendar from './ico-widgetCalendar.svg?inline';
import ico_widgetClose from './ico-widgetClose.svg?inline';
import ico_widgetTickfrom from './ico-widgetTick.svg?inline';
import ico_cloud from './ico-cloud.svg?inline';
import ico_bookmark from './ico-bookmark.svg?inline';
import ico_medical_test from './ico-medical-test.svg?inline';
import ico_medicamento from './ico-medicamento.svg?inline';
import ico_atestado from './ico-atestado.svg?inline';
import ico_adobe from './ico-adobe.svg?inline';
import ico_image from './ico-image.svg?inline';
import ico_discountPharmacy from './ico-discountPharmacy.svg?inline';
import ico_creditCard from './ico_creditCard.svg?inline';
import ico_boleto from './ico_boleto.svg?inline';
import ico_boletoPrint from './ico-boletoPrint.svg?inline';
import ico_boletoWindow from './ico-boletoWindow.svg?inline';
import ico_boletoPay from './ico-boletoPay.svg?inline';
import ico_pix from './ico-pix.svg?inline';
import ico_voucher from './ico-voucher.svg?inline';

export default {
  ico_agenda_share,
  ico_agenda_engrenagem,
  ico_agenda,
  ico_agenda2,
  ico_app_integration,
  ico_avaliacoes,
  ico_birthday,
  ico_calendar,
  ico_category_2,
  ico_category,
  ico_changeOffice,
  ico_check,
  ico_clock,
  ico_consultareagenda,
  ico_consultas,
  ico_copy,
  ico_cpf,
  ico_cross,
  ico_dados_bancarios,
  ico_dash_atendidos,
  ico_dash_calendar,
  ico_dash_canceladas,
  ico_dash_duracao,
  ico_disconnect,
  ico_doc,
  ico_dollar,
  ico_edit,
  ico_email,
  ico_endoscopia,
  ico_exameProcedure,
  ico_alert,
  ico_extrato,
  ico_fechar,
  ico_file_medical,
  ico_file,
  ico_fullscreen,
  ico_google,
  ico_healthInsuranceCard,
  ico_help,
  ico_historico,
  ico_homograma,
  ico_imprimir,
  ico_info,
  ico_inperson_appointment,
  ico_inperson_office,
  ico_logout,
  ico_lupa_agenda,
  ico_lupa_form,
  ico_lupa,
  ico_maintenance,
  ico_map,
  ico_menu_agenda,
  ico_menu_agendamentos,
  ico_menu_conta,
  ico_menu_extrato,
  ico_menu_healthplace,
  ico_menu_historico,
  ico_menu_modelos,
  ico_menu_pacientes,
  ico_menu_relatorios,
  ico_menu_servicos,
  ico_mic_off,
  ico_mic_on,
  ico_money,
  ico_name,
  ico_note,
  ico_notification,
  ico_novaconsulta,
  ico_pacientes,
  ico_pagamento,
  ico_painel,
  ico_payment,
  ico_pdf,
  ico_person,
  ico_plans,
  ico_precos,
  ico_procedimentos,
  ico_radiografia,
  ico_receipt,
  ico_referral,
  ico_relatorios,
  ico_reserva_agenda,
  ico_rg,
  ico_smiley,
  ico_star_avaliacao,
  ico_star_full,
  ico_star,
  ico_start_telemedicine,
  ico_sus,
  ico_telemedicine_appointment,
  ico_telemedicine_office,
  ico_telephone,
  ico_time,
  ico_timer,
  ico_tomografia,
  ico_trash,
  ico_ultrassom,
  ico_upload,
  ico_user_times,
  ico_user,
  ico_usuarios,
  ico_video_off,
  ico_video_on,
  ico_visibility_off,
  ico_visibility,
  ico_visit_link,
  ico_whatsapp,
  ico_widgetCalendar,
  ico_widgetClose,
  ico_widgetTickfrom,
  ico_cloud,
  ico_bookmark,
  ico_medical_test,
  ico_medicamento,
  ico_atestado,
  ico_adobe,
  ico_image,
  ico_discountPharmacy,
  ico_creditCard,
  ico_boleto,
  ico_boletoPrint,
  ico_boletoWindow,
  ico_boletoPay,
  ico_pix,
  ico_voucher,
};
