import http from '../httpConfig';

const payment = (
  auth,
  {
    appointmentStart = null,
    doctor = null,
    doctorOffice = null,
    type = null,
    medicalSpecialty = null,
    healthPlacePartner = null,
  } = {},
) => {
  const headers = auth;
  const endpoint = 'user/agenda';

  const startReservation = () => new Promise((resolve, reject) => {
    const data = {
      agenda: {
        appointmentStart,
        doctor,
        doctorOffice,
        type,
        medicalSpecialty,
        healthPlacePartner,
        isShareMedicalHistory: 1,
      },
    };

    http.post(endpoint, data, headers)
      .then((res) => {
        const { 'created_at': reservationStartTime, ...data } = res.data;
        resolve({ ...data, reservationStartTime });
      })
      .catch((err) => {
        reject(new Error(err?.response?.data?.errors ?? err?.response?.data));
      });
  });

  const completeReservation = (
    {
      agendaId = null,
      agendaUuid = null,
      creditCard = null,
      installments = 1,
      isShareMedicalHistory = true,
      voucher = null,
      paymentMethod = null,
    } = {},
  ) => new Promise((resolve, reject) => {
    const suffix = agendaUuid ? `?uuid=${agendaUuid}` : '';
    const url = `${endpoint}/${agendaId}/checkout${suffix}`;

    const data = {
      'user_agenda_checkout': {
        isShareMedicalHistory: isShareMedicalHistory ? 1 : 0,
        paymentMethod,
        installments,
      },
    };

    if (paymentMethod === 'CARTÃO DE CRÉDITO') {
      data['user_agenda_checkout'].creditCard = creditCard;
      data['user_agenda_checkout'].installments = installments;
    }

    if (voucher) {
      data['user_agenda_checkout'].prepaidVoucher = voucher;
    }

    http.post(url, data, headers)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(new Error(err?.response?.data?.errors ?? err?.response?.data));
      });
  });

  return {
    startReservation,
    completeReservation,
  };
};

export default payment;
