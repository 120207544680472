<template>
  <div class="form">
    <h4
      v-if="title"
      class="form__title"
    >
      {{ title }}
    </h4>

    <div
      v-for="(field, index) of localFormData"
      :key="`form-field-${index}`"
      :class="[
        field.class ? field.class : '',
        { 'form__field--hidden': field.hidden }
      ]"
      class="form__field"
    >
      <field
        v-model="field.value"
        :type="field.type"
        :options="field.options"
        :radios="field.radios"
        :label="field.label"
        :placeholder="field.placeholder"
        :mask="field.mask"
        :disabled="field.disabled || disabled"
        :is-valid.sync="field.isValid"
        :is-invalid.sync="field.isInvalid"
        :hidden.sync="field.hidden"
      />
    </div>
  </div>
</template>

<script>
import Field from '@/components/ui/Field.vue';

export default {
  name: 'Form',

  components: {
    Field,
  },

  model: {
    model: {
      prop: 'formData',
      event: 'change',
    },
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },

    validations: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: () => null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      localFormData: null,
    };
  },

  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(value) {
        this.localFormData = value;
        Object.keys(this.localFormData)
          .forEach((key) => {
            this.localFormData[key].isValid = null;
            this.localFormData[key].isInvalid = null;
          });
      },
    },

    localFormData: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('change', value);
      },
    },

    validations: {
      deep: true,
      immediate: true,
      handler() {
        this.setValidation();
      },
    },
  },

  methods: {
    setValidation() {
      Object.keys(this.validations)
        .forEach((key) => {
          if (!/\$/.test(key)) {
            if (this.validations[key].$dirty && !this.validations[key].$invalid) {
              this.localFormData[key].isValid = true;
              this.localFormData[key].isInvalid = false;
            }

            if (this.validations[key].$dirty && this.validations[key].$invalid) {
              this.localFormData[key].isValid = false;
              this.localFormData[key].isInvalid = true;
            }
          }
        });
    },
  },
};
</script>
