<template>
  <form class="form">
    <h4
      v-if="title"
      class="form__title"
    >
      {{ title }}
    </h4>

    <field
      v-for="(field, fieldName) in formData"
      :key="field.name"
      :field-name="fieldName"
      :field="field"
      :api-errors.sync="apiErrors"
      :validations="$v.formData"
      :class="field.class ? field.class : 'w-full'"
      :callback.sync="field.callback"
    />

    <slot />
  </form>
</template>

<script>
import formUtils from '@/mixins/formUtils';
import handleError from '@/mixins/handleError';
import Field from '@/components/ui/form/_field.vue';

export default {
  name: 'Form',
  mixins: [handleError, formUtils],

  components: {
    Field,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },

    apiErrors: {
      type: Object,
      default: () => {},
    },

    title: {
      type: String,
      required: false,
      default: () => null,
    },

    sending: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      localFormData: null,
      formFirstState: Object.assign(this.formData, {}),
    };
  },

  validations() {
    const obj = { formData: this.buildValidations(this.formData) };
    return obj;
  },

  watch: {
    '$v.$invalid': function (next) {
      this.$emit('valid', !next);
    },

    sending(next) {
      if (!next) {
        this.$v.$reset();
      }
    },

    'formData.ddi.value': {
      deep: false,
      immediate: true,
      handler(next, before) {
        if (next !== before) {
          const { mask, placeholder } = this.formData.ddi.options.find(
            (option) => option.iso === this.formData.ddi.value,
          );

          this.formData.contactPhone.mask = [mask];
          this.formData.contactPhone.placeholder = placeholder;
        }
      },
    },
  },

};
</script>
