<style lang="scss" scoped>
.pix {
  @apply flex flex-col items-start content-start justify-start;

  &-instructions {
    @apply flex flex-col items-start justify-center;
    @apply my-4;

    &__item {
      @apply flex flex-row items-center justify-start;
      @apply mb-6;
    }

    &__icon {
      @apply flex items-center justify-center;
      @apply rounded rounded-full;
      @apply mr-2 h-10 w-10;
      background: var(--button-primary-bg);

      svg {
        @apply pt-1;
        height: 2rem;
      }
    }

    &__text {
      @apply text-sm;
      color: var(--color-text);
    }
  }

  &__disclaimer {
    @apply text-sm leading-6 my-4;
    color: var(--color-text);
  }
}
</style>

<template>
  <section class="appointment-payment__col pix">
    <p class="pix__disclaimer">
      Confira ao lado os dados de seu agendamento e em seguida clique no
      botão REALIZAR PAGAMENTO para gerar o QR Code para o pagamento de
      sua consulta. O código gerado para o pagamento ficará válido
      pelos próximos 15 minutos.
    </p>

    <ul class="pix-instructions mb-8">
      <li class="pix-instructions__item">
        <div class="pix-instructions__icon">
          <icon
            name="pix"
            color="white"
            style="margin-top: -3px"
          />
        </div>

        <p class="pix-instructions__text">
          escaneie o QR Code e <strong>faça o pagamento</strong>
        </p>
      </li>

      <li class="pix-instructions__item">
        <div class="pix-instructions__icon">
          <icon
            name="boletoPay"
            color="white"
          />
        </div>

        <p class="pix-instructions__text">
          o prazo de validade do PIX é de <strong>{{ offsetMinutes }}</strong>
        </p>
      </li>
    </ul>
  </section>
</template>

<script>
import { PAYMENT_PIX_OFFSET_MINUTES } from '@/data/constants';
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'Pix',

  components: {
    Icon,
  },

  data() {
    return {
      offsetMinutes: `${PAYMENT_PIX_OFFSET_MINUTES} minutos`,
    };
  },
};
</script>
