import http from '../httpConfig';

const payment = (headers) => {
  const list = () => new Promise((resolve, reject) => {
    http.get('/user/credit_card', headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  const create = (data) => new Promise((resolve, reject) => {
    http.post('/user/credit_card', { credit_card: data }, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  const remove = (id) => new Promise((resolve, reject) => {
    http.delete(`/user/credit_card/${id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  const setAsDefault = (id) => new Promise((resolve, reject) => {
    http.put(`/user/credit_card/${id}/set_default`, { is_default: true }, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  return {
    list,
    create,
    remove,
    setAsDefault,
  };
};

export default payment;
