import { required, minLength } from 'vuelidate/lib/validators';
import ufs from '../uf';

const formData = {
  cep: {
    name: 'cep',
    type: 'tel',
    label: 'CEP',
    placeholder: '00000-000',
    mask: ['#####-###'],
    lazy: false,
    disabled: true,
    value: null,
    order: 1,
  },
  street: {
    name: 'street',
    type: 'text',
    label: 'Rua',
    placeholder: 'Nome da rua',
    lazy: true,
    disabled: true,
    value: null,
    order: 2,
  },
  number: {
    name: 'number',
    type: 'text',
    label: 'Número',
    placeholder: '000',
    class: 'form__field--20',
    lazy: true,
    disabled: true,
    value: null,
    order: 3,
  },
  complement: {
    name: 'complement',
    type: 'text',
    label: 'Complemento',
    placeholder: 'Bloco, apto, suíte, etc',
    class: 'form__field--80',
    lazy: true,
    disabled: true,
    value: null,
    order: 4,
  },
  district: {
    name: 'district',
    type: 'text',
    label: 'Bairro',
    placeholder: 'Nome do bairro',
    lazy: true,
    disabled: true,
    value: null,
    order: 5,
  },
  city: {
    name: 'city',
    type: 'text',
    label: 'Cidade',
    placeholder: 'Nome da cidade',
    class: 'form__field--80',
    lazy: true,
    value: null,
    disabled: true,
    order: 6,
  },
  uf: {
    name: 'uf',
    type: 'select',
    label: 'UF',
    placeholder: 'Estado',
    class: 'form__field--20',
    options: ufs,
    value: null,
    disabled: true,
    order: 7,
  },
};

const validations = {
  cep: {
    value: {
      required,
      minLength: minLength(8),
    },
  },
  street: {
    value: {
      required,
    },
  },
  number: {
    value: {
      required,
    },
  },
  district: {
    value: {
      required,
    },
  },
  city: {
    value: {
      required,
    },
  },
  uf: {
    value: {
      required,
    },
  },
};

export { formData, validations };
