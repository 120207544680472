import http from '../httpConfig';

const login = (sendTo) => {
  let user = null;
  const email = sendTo;

  const checkUser = (partnerId) => new Promise((resolve, reject) => {
    const endpoint = `/health_place_partner/${partnerId}/check_user?email=${email}`;

    http.get(endpoint)
      .then((res) => {
        user = res.data;
        const { 'contact_phone': contactPhone, email, cpf } = user;

        resolve({ contactPhone, email, cpf });
      })
      .catch(() => reject());
  });

  const sendConfirmationCode = (sendMethod, partnerId) => new Promise((resolve, reject) => {
    const endpoint = `/user/login_code?healthPlacePartnerUuid=${partnerId}`;
    const data = {
      create: {
        email,
        sendMethod,
      },
    };

    http.post(endpoint, data)
      .then((res) => {
        if (res.data) {
          resolve();
          return;
        }

        reject(new Error('Erro ao enviar o código.'));
      })
      .catch(reject);
  });

  const confirmLogin = (code) => new Promise((resolve, reject) => {
    const endpoint = `/user/login_code/check_validity?email=${encodeURIComponent(email)}&code=${code}`;

    http.get(endpoint)
      .then((res) => {
        if (res.data && 'validity' in res.data && String(res.data.validity) === 'true') {
          resolve(res.data);
          return;
        }

        reject(new Error('Código inválido.'));
      })
      .catch(reject);
  });

  const getUser = () => user;

  return {
    checkUser,
    sendConfirmationCode,
    confirmLogin,
    getUser,
  };
};

export default login;
