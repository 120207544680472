export default {
  methods: {
    createPaymentOptions(value, installments) {
      const options = [];

      for (let i = 1; i <= installments; i += 1) {
        options.push({
          value: i,
          label: `${i}x de R$ ${
            String(parseFloat(value / i).toFixed(2)).replace('.', ',')
          }`,
        });
      }

      return options;
    },

    setObjectValue(obj = {}, key = null, value = null) {
      obj[key].value = value;
      if (!value) obj[key].disabled = false;
    },

    touchFormField(formData = {}) {
      Object.keys(this[formData])
        .forEach((key) => {
          if (
            this[formData][key].value
            && key in this.$v[formData]
          ) {
            this.$v[formData][key].$touch();
          }
        });
    },

    resetFormData(formData = {}) {
      Object.keys(formData)
        .forEach((key) => {
          formData[key].value = null;
        });
    },

    enableForm(formData = {}) {
      Object.keys(formData)
        .forEach((key) => {
          if (key !== 'email') {
            this.setObjectValue(formData, key);
          }
        });
    },

    getPaymentErrorMessage(formData = []) {
      let msg = '';
      let label = '';
      let skip = false;

      formData.forEach((form) => {
        Object.keys(form)
          .sort((a, b) => {
            if (
              typeof form[a].$model === 'object'
              && typeof form[b].$model === 'object'
            ) {
              return form[a].$model.order < form[b].$model.order ? -1 : 1;
            }

            return 0;
          })
          .forEach((field) => {
            if (!/\$/gi.test(field) && !skip) {
              if (
                typeof form[field].$model === 'object'
                && 'label' in form[field].$model
              ) {
                label = form[field].$model.label;
              }

              if (
                typeof form[field].$model !== 'object'
                && !form[field].isValid
              ) {
                msg = 'É necessário concordar com os <strong>Termos & Condições.</strong>';
                skip = true;
              }

              if (!skip) {
                const validations = form[field]?.value;
                if (validations) {
                  Object.keys(validations).forEach((validation) => {
                    if (
                      !/\$/gi.test(validation)
                      && !validations[validation]
                      && !skip
                    ) {
                      switch (true) {
                        case validation === 'required':
                          msg = `O campo <strong>${label}</strong> é de preenchimento obrigatório.`;
                          skip = true;
                          break;

                        case validation === 'sameAs':
                          msg = 'Os e-mails não conferem.';
                          skip = true;
                          break;

                        case validation === 'minLength' || validation === 'isValid' || validation === 'email':
                          msg = `O valor do campo <strong>${label}</strong> é inválido.`;
                          skip = true;
                          break;

                        default:
                          msg = 'Todos os campos desse formulário são de preenchimento obrigatório.';
                      }
                    }
                  });
                }
              }
            }
          });
      });

      return msg;
    },

    findInObject(object = {}, keyName = null) {
      let result = false;

      const recursiveSearch = (obj) => {
        for (const key in obj) {
          if (key === keyName) {
            result = String(obj[key]);
            break;
          }
          recursiveSearch(obj[key]);
        }
      };

      recursiveSearch(object);

      return result;
    },
  },
};
