import http from '../httpConfig';

const registerConfirmation = () => {
  const resendConfirmationToken = (
    method = 'sms',
    email = null,
    cpf = null,
  ) => new Promise((resolve, reject) => {
    const url = method === 'sms'
      ? 'user/resend_token_by_sms'
      : 'user/resend_token_by_email';

    const queryString = email
      ? `?email=${encodeURIComponent(email)}`
      : `?cpf=${cpf}`;

    const endpoint = `${url}${queryString}`;

    http.get(endpoint)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });

  const validateToken = (
    cpf = null,
    email = null,
    token = null,
  ) => new Promise((resolve, reject) => {
    const params = [
      { query: 'cpf', value: cpf },
      { query: 'email', value: encodeURIComponent(email) },
      { query: 'appConfirmationToken', value: token },
    ];

    const queryString = params.reduce((acc, query) => {
      if (query.value) {
        if (acc === '?') {
          return `${acc}${query.query}=${query.value}`;
        }

        return `${acc}&${query.query}=${query.value}`;
      }

      return acc;
    }, '?');

    const endpoint = `/user/register_confirmation${queryString}`;

    http.get(endpoint)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });

  return {
    resendConfirmationToken,
    validateToken,
  };
};

export default registerConfirmation;
