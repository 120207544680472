<style lang="scss">
.ico {
  &--primary {
    * {
      fill: var(--color-primary);
    }
  }

  &--secondary {
    * {
      fill: var(--color-secondary);
    }
  }

  &--success {
    * {
      fill: green;
    }
  }

  &--neutral {
    * {
      fill: rgb(200, 200, 200);
    }
  }

  &--gray {
    * {
      fill: rgb(67, 67, 67);
    }
  }

  &--white {
    * {
      fill: rgb(255, 255, 255);
    }
  }
}
</style>

<template>
  <component
    :is="'ico_' + name"
    class="ico"
    :class="`ico--${color}`"
  />
</template>

<script>
import icons from '@/assets/img/icons-component/';

export default {
  components: {
    ...icons,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: () => 'primary',
    },
  },
};
</script>
