<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <section
      v-if="ui.hasLoaded"
      class="appointment-payment pt-4"
    >
      <!-- Formulário Dados do paciente -->
      <ui-form
        v-if="!logged"
        :ref="'form-personal-info'"
        class="appointment-payment__col"
        title="Dados do paciente"
        :form-data="formDataPersonal"
        :validations.sync="$v.formDataPersonal"
        :disabled="ui.isLoading"
      />

      <!-- Formulário Endereço -->
      <ui-form
        v-if="!logged"
        :ref="'form-address-info'"
        class="appointment-payment__col"
        title="Endereço"
        :form-data="formDataAddress"
        :validations.sync="$v.formDataAddress"
        :disabled="ui.isLoading"
      />

      <!-- Componente de método de pagamento -->
      <!-- :disabled="$v.formDataPersonal.$invalid || $v.formDataAddress.$invalid" -->
      <payment-method-picker
        v-if="formDataPersonalValid && formDataAddressValid && !logged"
        class="appointment-payment__col"
      />

      <payment-method-picker
        v-if="logged"
        class="appointment-payment__col"
      />

      <!-- Confirmação de pagamento -->
      <div class="appointment-payment__col appointment-payment__col--summary">
        <!-- Resumo do agendamento -->
        <transition
          name="fade"
          mode="out-in"
        >
          <summary-box v-if="appointment.id || appointment.uuid" />
        </transition>

        <span
          style="text-align: left"
          class="appointment-date__title--fuse block !text-left !m-4"
        >
          *Horário de Brasília
        </span>

        <!-- Compartilhamento de histórico médico -->
        <div class="appointment-payment__terms">
          <div class="field__checkbox">
            <input
              id="share-medical-history"
              v-model="isShareMedicalHistory"
              type="checkbox"
              class="field__checkbox-input"
            >

            <label
              for="share-medical-history"
              class="field__checkbox-label"
            >
              <i class="icon icon-check" />
            </label>
          </div>

          <span class="appointment-payment__terms-label">
            Quero compartilhar meu histórico médico
          </span>
        </div>

        <!-- Termos e condições -->
        <div class="appointment-payment__terms">
          <div class="field__checkbox">
            <input
              id="terms-conditions"
              v-model="formDataTermsConditions.value"
              type="checkbox"
              class="field__checkbox-input"
            >

            <label
              for="terms-conditions"
              class="field__checkbox-label"
            >
              <i class="icon icon-check" />
            </label>
          </div>

          <span class="appointment-payment__terms-label">
            Li e concordo com os
            <a
              class="landing-page__menu-link"
              href="#"
              @click.prevent="ui.showTermsConditions = true"
            >
              Termos & Condições
            </a>
          </span>
        </div>

        <!-- Botão de confirmação de pagamento -->
        <ui-button
          :disabled="ui.isLoading"
          :label="buttonText"
          @click="completeAppointmentReservation"
        />
      </div>

      <!-- Modal de login -->
      <login-confirmation
        :is-active.sync="ui.modalActive"
        @login-confirmed="loginUser"
      />

      <!-- Modal de termos e condições -->
      <terms-conditions
        :is-active.sync="ui.showTermsConditions"
        @close="ui.showTermsConditions = false"
      />

      <!-- Modal cadastro de endereço -->
      <transition
        name="fade"
        mode="out-in"
      >
        <modal
          v-if="ui.showAddressForm"
          @close="closeAddressForm"
        >
          <template v-slot:title>
            Cadastrar endereço
          </template>

          <template v-slot:body>
            <user-address
              class="mt-4"
              @saved="
                ui.showAddressForm = false;
                init();
              "
            />
          </template>
        </modal>
      </transition>

      <!-- Loader -->
      <transition
        name="fade"
        mode="out-in"
      >
        <loader v-if="ui.isSubmitting || ui.isLoading" />
      </transition>
    </section>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'vue-debounce';
import VueJwtDecode from 'vue-jwt-decode';
// API
import address from '@/api/appointmentScheduling/address';
import login from '@/api/appointmentScheduling/login';
import profile from '@/api/user/profile';
import unloggedScheduling from '@/api/appointmentScheduling/scheduling';
import loggedScheduling from '@/api/user/scheduling';
import userAddress from '@/api/user/address';
import payment from '@/api/user/payment';
// Form Data
import * as formDataPersonalInfo from '@/data/appointmentScheduling/formPersonalInfo';
import * as formDataAddressInfo from '@/data/appointmentScheduling/formAddress';
// Mixins
import completeStep from '@/mixins/completeStep';
import handleError from '@/mixins/handleError';
import paymentFunctions from '@/mixins/paymentFunctions';
import googleAnalytics from '@/mixins/googleAnalytics';
// Payment Methods
import PaymentMethodPicker from '@/components/appointmentScheduling/paymentMethods/paymentMethodPicker.vue';
// Components
import Loader from '@/components/ui/Loader.vue';
import UiForm from '@/components/ui/Form.vue';
import UiButton from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import LoginConfirmation from '@/components/modals/LoginConfirmation.vue';
import TermsConditions from '@/components/appointmentScheduling/TermsConditions.vue';
import SummaryBox from '@/components/appointmentScheduling/AppointmentSummaryBox.vue';
import UserAddress from '@/components/user/profile/userAddress.vue';

export default {
  name: 'AppointmentSchedulingCheckout',

  mixins: [
    completeStep,
    handleError,
    paymentFunctions,
    googleAnalytics,
  ],

  components: {
    Loader,
    UiForm,
    UiButton,
    Modal,
    LoginConfirmation,
    SummaryBox,
    TermsConditions,
    UserAddress,
    PaymentMethodPicker,
  },

  validations: {
    formDataPersonal: formDataPersonalInfo.validations,
    formDataAddress: formDataAddressInfo.validations,
    formDataTermsConditions: {
      value: {
        isValid: (value) => value,
      },
    },
  },

  data() {
    return {
      formDataPersonalValid: false,
      formDataAddressValid: false,
      formDataTermsConditionsValid: false,
      formDataPersonal: formDataPersonalInfo.formData,
      formDataAddress: formDataAddressInfo.formData,
      formDataTermsConditions: { value: false },
      login: null,
      code: null,
      voucher: null,
      ui: {
        hasLoaded: false,
        isDisabled: false,
        isLoading: false,
        modalActive: false,
        isSubmitting: false,
        showTermsConditions: false,
        disablePaymentForm: true,
        showAddressForm: false,
        showPrepaidConfirmation: false,
        showVoucherConfirmation: false,
      },
      isShareMedicalHistory: true,
      scheduling: null,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('user', ['logged', 'headers']),
    ...mapGetters('scheduling', [
      'appointment',
      'patient',
      'address',
      'payment',
      'doctor',
      'office',
    ]),

    appointmentId() {
      return this.$route.params.id || null;
    },

    initialRoute() {
      return this.logged ? 'UserScheduling' : 'AppointmentScheduling';
    },

    buttonText() {
      return this.payment?.method?.name === 'prepaid'
        ? 'Realizar agendamento'
        : 'Realizar pagamento';
    },
  },

  watch: {
    formDataPersonal: {
      deep: true,
      immediate: false,
      handler(val) {
        let errors = 0;
        for (const item in val) {
          if (!val[item].isValid) { errors += 1; }
        }

        if (errors > 1) {
          this.formDataPersonalValid = false;
        } else {
          this.formDataPersonalValid = true;
        }
        this.touchFormField('formDataPersonal');
      },
    },

    formDataAddress: {
      deep: true,
      immediate: false,
      handler(val) {
        let errors = 0;
        for (const item in val) {
          if (!val[item].isValid) { errors += 1; }
        }

        if (errors > 1) {
          this.formDataAddressValid = false;
        } else {
          this.formDataAddressValid = true;
        }
        this.touchFormField('formDataAddress');
      },
    },

    'formDataPersonal.email.value': {
      deep: false,
      immediate: false,
      handler() {
        if (!this.$v.formDataPersonal.email.$invalid) {
          this.validateUser();
        }
      },
    },

    'formDataAddress.cep.value': {
      deep: false,
      immediate: false,
      handler(cep) {
        if (cep && /^\d{5}-\d{3}$/.test(cep)) {
          this.getAddress(cep);
        }
      },
    },

    'formDataPersonal.ddi.value': {
      deep: false,
      immediate: false,
      handler(next, before) {
        if (next !== before) {
          const { placeholder, mask } = this.formDataPersonal.ddi.options.find(
            (country) => country.iso === next,
          );

          this.formDataPersonal.phoneNumber.placeholder = placeholder;
          this.formDataPersonal.phoneNumber.mask = mask;
        }
      },
    },

    '$v.formDataPersonal.$invalid': {
      deep: false,
      immediate: false,
      handler(value) {
        if (!value) {
          const cpf = this.formDataPersonal.cpf.value;
          const email = this.formDataPersonal.email.value;
          this.setPatient({ email, cpf });
        } else {
          this.setPatient({ email: null, cpf: null });
        }
      },
    },
  },

  created() {
    this.init();
  },

  mounted() {
    if (document.domain === 'paciente.drconecta.com.br') {
      if (Object.prototype.hasOwnProperty.call(window, 'gtag')) {
        window.gtag('event', 'conversion', { 'send_to': 'AW-415408362/aHCuCNXww_YBEOrBisYB' });
      }
    }

    if (this.appointmentId) {
      this.setAppointment({ id: this.appointmentId });
    }
  },

  destroyed() {
    this.resetFormData(this.formDataPersonal);
    this.resetFormData(this.formDataAddress);
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
      'completeProgressTrackerStep',
      'resetProgressTrackerStep',
    ]),
    ...mapActions('scheduling', [
      'setAppointment',
      'resetScheduling',
      'setReservationStartTime',
      'setPatient',
      'setLoginStatus',
      'setDoctor',
      'setSavedAppointment',
      'setPayment',
    ]),
    ...mapActions('user', ['setSession', 'setUser']),

    init() {
      const { date, time } = this.appointment;

      // If user is logged in
      if (this.logged) {
        const type = this.appointment.modality === 'presential'
          ? 'HEALTHPLACE'
          : 'HEALTHPLACE_TELEMEDICINA';

        // Initiate the scheduling interface with
        // logged in data
        this.scheduling = loggedScheduling(
          this.headers,
          {
            appointmentStart: `${date} ${time}`,
            doctor: this.doctor.id,
            doctorOffice: this.office.id,
            type,
            medicalSpecialty: this.appointment.specialty.id,
            healthPlacePartner: this.partner.id,
          },
        );
      }

      // If user is NOT logged in
      if (!this.logged) {
        // Initiate the scheduling interface with
        // new user data
        this.scheduling = unloggedScheduling(
          this.partner.uuid,
          this.office.uuid,
          `${date} ${time}`,
          this.appointment.specialty.id,
        );
      }

      // Fill patient data and address from partners
      this.fillUserData({ ...this.patient, ...this.address });

      // If it is a new user or it is a user that did not log in
      // in the middle of a buying process (in the payment form)
      if (!this.patient.wasUnlogged && !this.appointmentId) {
        // Starts the appointment reservation process
        this.startAppointmentReservation();
      } else {
      // Otherwise, there is no need to start the reservation
      // and it just shows the payment and summary components
      // by setting hasLoaded to true
        this.ui.hasLoaded = true;
      }
    },

    startAppointmentReservation() {
      // User interface controls
      this.ui.hasLoaded = false;
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.scheduling
        .startReservation()
        .then((res) => {
          const data = {
            id: res.id,
            uuid: res.uuid,
          };

          this.setAppointment(data);
          this.setReservationStartTime(res.reservationStartTime);

          this.formDataPersonal.email.disabled = false;
        })
        .catch((err) => this.handleReservationError(err))
        .finally(() => {
          this.ui.hasLoaded = true;
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    completeAppointmentReservation() {
      if (
        (!this.logged && this.$v.$invalid)
        || (this.logged && this.$v.formDataTermsConditions.$invalid)
        || !this.validatePaymentData()
      ) {
        this.showErrorMessage();
        return;
      }

      let data = null;
      let preReservationFn = !this.logged
        ? () => Promise.resolve({ id: 0 })
        : () => Promise.resolve({ id: this.payment.id });

      // User is logged: payment logic
      if (this.logged) {
        // Generic payment data
        data = {
          agendaId: this.appointment.id,
          agendaUuid: this.appointment.uuid,
          creditCard: null,
          installments: this.payment.installments,
          isShareMedicalHistory: this.isShareMedicalHistory,
          voucher: this.voucher,
          paymentMethod: this.payment.method.value,
        };

        // Credit card payment data
        if (this.payment.method.name === 'creditCard') {
          data.creditCard = null;

          if (!this.payment.id) {
            const createApiObject = (form) => {
              const data = {};
              Object.keys(form).forEach((key) => {
                data[key] = form[key].value;
              });

              return data;
            };

            const paymentInfo = createApiObject(this.payment.data);

            const [cardMonth, cardYear] = paymentInfo.expirationDate.split('/');
            paymentInfo.expirationDate = `${cardMonth}${cardYear.slice(-2)}`;
            paymentInfo.cardNumber = paymentInfo.cardNumber.replace(/\D/gi, '');

            const {
              'name': holderName,
              'cardNumber': number,
              expirationDate,
              'cvc': cvv,
            } = paymentInfo;

            const creditCardData = {
              holderName,
              number,
              expirationDate,
              cvv,
              isDefault: 0,
            };

            const saveNewCreditCard = payment(this.headers);
            preReservationFn = () => saveNewCreditCard.create(creditCardData);
          }
        }
      }

      // User is NOT logged: payment logic
      if (!this.logged) {
        // Generic payment data
        const createApiObject = (form) => {
          const data = {};
          Object.keys(form).forEach((key) => {
            data[key] = form[key].value;
          });

          return data;
        };

        const personalInfo = createApiObject(this.formDataPersonal);
        const addressInfo = createApiObject(this.formDataAddress);

        const [day, month, year] = personalInfo.birthdate.split('/');
        personalInfo.birthdate = `${year}-${month}-${day}`;
        personalInfo.cpf = personalInfo.cpf.replace(/\D/gi, '');
        personalInfo.phoneNumber = personalInfo.phoneNumber.replace(/\D/gi, '');
        personalInfo.ddi = this.formDataPersonal.ddi.options
          .find(
            (option) => option.iso === this.formDataPersonal.ddi.value,
          )
          .code;

        addressInfo.cep = addressInfo.cep.replace(/\D/gi, '');

        const codeValue = JSON.parse(window.localStorage.getItem('code')) || { code: null };

        data = {
          agendaId: this.appointment.uuid,
          personalInfo,
          addressInfo,
          paymentInfo: { installments: this.payment.installments },
          code: codeValue['code'],
          isShareMedicalHistory: this.isShareMedicalHistory,
          voucher: this.payment.voucher,
          paymentMethod: this.payment.method.value,
        };

        // Credit Card Payment Data
        if (this.payment.method.name === 'creditCard') {
          const paymentInfo = createApiObject(this.payment.data);

          const [cardMonth, cardYear] = paymentInfo.expirationDate.split('/');
          paymentInfo.expirationDate = `${cardMonth}${cardYear.slice(-2)}`;
          paymentInfo.cardNumber = paymentInfo.cardNumber.replace(/\D/gi, '');
          paymentInfo.cpf = '';

          data.paymentInfo = paymentInfo;
        }
      }

      this.ui.isLoading = true;
      this.ui.isSubmitting = true;
      this.toggleProgressBar();

      preReservationFn()
        .then(({ id }) => {
          if (this.payment.method.name === 'creditCard' && this.logged) {
            data.creditCard = id;
          }

          if (!this.patient.wasUnlogged && this.logged) {
            delete data.agendaUuid;
          }
        })
        .then(() => this.scheduling.completeReservation(data))
        .then((res) => {
          const name = 'display_name' in res.doctor && res.doctor['display_name']
            ? res.doctor['display_name']
            : res.doctor.user.name;

          const creationDate = res['created_at'];

          this.setDoctor({ name });
          this.setAppointment({ uuid: res.uuid, creationDate });
          this.completeStep(3);
        })
        .catch((err) => {
          let msg = 'Ocorreu um erro.';

          if (typeof err === 'string') {
            msg = err;
          } else if (
            typeof err.errors === 'object'
            && 'errors' in err.errors
            && Array.isArray(err.errors.errors)
            && err.errors.errors.length > 0
          ) {
            msg = String(err.errors.errors[0]);
          } else {
            msg = this.findInObject(err, 'errors') || 'Ocorreu um erro.';
          }

          if (/.*(cpf).*(sendo usado).*/gi.test(msg)) {
            msg = `Este CPF já está em uso e vinculado a um outro endereço de e-mail.
              Por favor, adicione o seu endereço de e-mail cadastrado ou confirme se
              seu CPF está correto.`;
          }

          if (/.*(pagamento).*(n(ã|a)o).*(aprovado).*/gi.test(msg)) {
            msg = `Pagamento não aprovado. Por favor, entre em contato com sua
              operadora de cartão de crédito.`;
          }

          if (/.*(usu(a|á)rio).*(n(a|ã)o).*(tem).*(agendas).*(dispon(í|i)veis).*/gi.test(msg)) {
            msg = `Você não está elegível para esse tipo de consulta.
            Caso o problema persista, entre em contato com a central de atendimento.`;
          }

          this.handleError(new Error(msg));
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.ui.isSubmitting = false;
          this.toggleProgressBar(false);
        });
    },

    handleReservationError(error) {
      let errorMessage = null;

      if (typeof error === 'string') {
        errorMessage = error;
      } else {
        errorMessage = 'errors' in error
          ? this.findErrorMessage(error.errors)[0]
          : 'Não foi possível realizar a reserva de seu agendamento.';
      }

      if (/(?=.*enderec|ço)(?=.*cadastrado)/gi.test(errorMessage)) {
        const fn = () => {
          this.setSavedAppointment();
          this.ui.showAddressForm = true;
          this.closeModalDialog();
        };

        this.openModalDialog({
          type: 'confirmation',
          title: 'Atenção',
          text: `Para prosseguir com o agendamento de uma consulta,
            é necessário ter o endereço cadastrado.
            Deseja cadastrar agora?
          `,
          cancelText: 'Cancelar',
          confirmText: 'Cadastrar',
          size: 'sm',
          fn,
          cancelFn: () => {
            this.resetUserScheduling();
            this.$router.push({ name: this.initialRoute });
          },
        });
      } else {
        const fn = () => {
          this.resetUserScheduling();
          this.$router.push({ name: this.initialRoute });
          this.closeModalDialog();
        };

        if (/.*(limite).*(agendamentos).*(atingido).*/gi.test(errorMessage)) {
          errorMessage = `Você não está elegível para esse tipo de consulta.
            Caso o problema persista, entre em contato com a central de atendimento.`;
        }

        this.handleError(new Error(errorMessage), null, fn);
      }
    },

    findErrorMessage(object) {
      let errorMessage = null;

      const recursiveSearch = (object) => {
        for (const value in object) {
          if (value === 'errors') {
            errorMessage = object[value];
            break;
          } else {
            recursiveSearch(object[value]);
          }
        }
      };

      if (Array.isArray(object)) {
        object.forEach((element) => {
          if (typeof element === 'object') {
            recursiveSearch(element);
          } else {
            errorMessage = element;
          }
        });
      } else {
        recursiveSearch(object);
      }

      return errorMessage;
    },

    validateUser: debounce(function () {
      const email = this.formDataPersonal.email.value;
      this.ui.isLoading = true;
      this.ui.modalActive = false;
      this.toggleProgressBar();

      this.login = login(email);
      this.login
        .checkUser(this.partner.uuid)
        .then((res) => {
          if (res.cpf) {
            this.setPatient({ ...res });
            this.ui.isDisabled = true;
            this.ui.modalActive = true;

            return;
          }

          this.openModalDialog({
            type: 'alert',
            title: 'Erro',
            text: `Encontramos um erro no seu cadastro. Por favor, entre em contato
              através do <strong>${this.partner.contactEmail}</strong> para solicitar
              a regularização.`,
            confirmText: 'Fechar',
            size: 'md',
            fn: this.closeModalDialog,
          });
        })
        .catch(() => {
          this.ui.modalActive = false;
          this.enableForm(this.formDataPersonal);
          this.formDataAddress.cep.disabled = false;

          this.formDataPersonal.emailConfirmation.value = null;
          this.formDataPersonal.emailConfirmation.hidden = false;
          this.formDataPersonal.ddi.value = 'br';
          this.formDataPersonal.phoneNumber.class = 'form__field--35';
          this.formDataPersonal.birthdate.class = 'form__field--50';

          this.ui.disablePaymentForm = false;

          this.fillUserData({ ...this.patient, ...this.address });
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    }, 1000),

    loginUser(data) {
      this.ui.modalActive = false;
      this.ui.hasLoaded = false;
      this.ui.isLoading = true;
      const decodedData = VueJwtDecode.decode(data.token);

      if (!/ROLE_USER/.test(decodedData.roles)) {
        this.handleError('Erro de permissão');
        return;
      }

      this.setLoginStatus(true);
      this.setSession(
        {
          token: data.token,
          username: decodedData.username,
          roles: decodedData.roles,
        },
      );

      profile(this.headers)
        .fetch()
        .then((res) => {
          profile(this.headers)
            .fetchPicture()
            .then((v) => {
              if (v) {
                res.profileImage = `${
                  process.env.VUE_APP_BASE_API
                }/s3/file/user/${
                  res.id
                }/profile_picture?${
                  Date.now()
                }`;
              }
              // delete res.cpf;
              delete res.rg;
              this.setUser(res);
            })
            .finally(() => {
              this.ui.hasLoaded = true;
              this.ui.isLoading = false;
            });
        });

      this.getUserAddress()
        .then(() => {
          this.setPatient({ wasUnlogged: true });
          this.$router.push({ name: 'UserSchedulingCheckout' });
        })
        .catch(() => {
          this.handleError(new Error('Antes de continuar, por favor, cadastre seu endereço.'));
          this.$router.push({ name: 'UserProfileAddress' });
        });
    },

    getUserAddress() {
      const getters = userAddress(this.headers);

      return new Promise((resolve, reject) => {
        getters.get()
          .then((res) => {
            if (res) {
              resolve();
              return;
            }

            reject();
          })
          .catch(reject);
      });
    },

    getAddress(cep) {
      const enableAddressForm = () => {
        Object.keys(this.formDataAddress).forEach((key) => {
          this.formDataAddress[key].disabled = false;
        });
      };

      this.toggleProgressBar();

      Object.keys(this.formDataAddress).forEach((key) => {
        if (key !== 'cep') {
          this.formDataAddress[key].value = null;
        }
      });

      address({ cep })
        .getAddress()
        .then((res) => {
          Object.keys(res).forEach((key) => {
            if (key !== 'uf') {
              this.formDataAddress[key].value = res[key] || null;
              this.formDataAddress[key].disabled = true;
            }
          });

          this.formDataAddress.uf.disabled = true;
          this.formDataAddress.uf.value = res.uf.toUpperCase();

          Object.keys(this.formDataAddress).forEach((key) => {
            if (!this.formDataAddress[key].value && key !== 'cep') {
              this.formDataAddress[key].disabled = false;
            }
          });

          this.formDataAddress.cep.disabled = false;
        })
        .catch((err) => {
          if (err.message === 'CEP não encontrado') {
            this.openModalDialog({
              type: 'confirmation',
              title: 'CEP não encontrado',
              text: 'Deseja pesquisar um outro CEP ou editar o endereço manualmente?',
              cancelText: 'Pesquisar',
              confirmText: 'Editar',
              size: 'sm',
              fn: () => {
                enableAddressForm();
                this.closeModalDialog();
              },
            });
          }
        })
        .finally(() => {
          this.toggleProgressBar(false);
        });
    },

    validatePaymentData() {
      if (this.payment?.method?.name === 'creditCard' && this.payment?.data) {
        const values = [];
        const keys = Object.keys(this.payment.data);

        keys.forEach((key) => {
          values.push(this.payment.data[key].isValid);
        });

        return values.every((v) => Boolean(v));
      }

      return Boolean(this.payment?.method?.name);
    },

    showErrorMessage() {
      const {
        formDataPersonal,
        formDataAddress,
        formDataTermsConditions,
      } = this.$v;

      const formData = [];

      if (!this.logged) {
        formData.push(formDataPersonal);
        formData.push(formDataAddress);
      }

      if (this.payment?.method?.name === 'creditCard') {
        formData.push(this.payment.validations);
      }

      formData.push(formDataTermsConditions);

      const msg = this.getPaymentErrorMessage(formData)
        ? this.getPaymentErrorMessage(formData)
        : 'É necessário escolher um método de pagamento.';

      this.handleError(new Error(`${msg}`));
    },

    fillUserData(data) {
      const formPersonal = [
        'email',
        'emailConfirmation',
        'gender',
        'name',
        'cpf',
        'phoneNumber',
        'birthdate',
      ];

      const formAddress = [
        'cep',
        'number',
        'complement',
      ];

      Object.keys(data).forEach((key) => {
        if (data[key]) {
          if (formPersonal.includes(key)) {
            this.formDataPersonal[key].value = data[key];
          }

          if (formAddress.includes(key)) {
            this.formDataAddress[key].value = data[key];
          }
        }
      });
    },

    resetUserScheduling() {
      this.resetFormData(this.formDataPersonal);
      this.resetFormData(this.formDataAddress);

      this.resetScheduling();
      this.resetProgressTrackerStep();
      [1, 2, 3].forEach((step) => this.completeProgressTrackerStep({ step, status: false }));
    },

    closeAddressForm() {
      this.resetUserScheduling();
      this.ui.showAddressForm = false;
      this.$router.push({ name: this.initialRoute });
    },
  },
};
</script>
