<template>
  <div class="appointment-payment__summary !mb-0">
    <h5 class="appointment-payment__title">
      Resumo do agendamento
    </h5>

    <dl class="summary">
      <dt class="summary__term">
        Especialidade
      </dt>
      <dd class="summary__def">
        {{ appointment.specialty.name | toSpecialtyName }}
      </dd>

      <dt class="summary__term">
        Data e horário
      </dt>
      <dd class="summary__def">
        {{ summaryDatetime }}
      </dd>

      <template v-if="office.address">
        <dt class="summary__term">
          Endereço
        </dt>
        <dd class="summary__def">
          {{ office.address }}
        </dd>
      </template>

      <dt class="summary__term">
        Método de pagamento
      </dt>
      <dd class="summary__def">
        <template v-if="methodName">
          {{ payment.method.label }}
          {{ payment.method.name === 'prepaid' ? partner.name : '' }}
        </template>
        <template v-else>
          Escolha um método de pagamento
        </template>
      </dd>

      <template v-if="methodName !== 'prepaid' && methodName !== 'voucher'">
        <dt class="summary__term">
          Valor da consulta
        </dt>
        <dd class="summary__def">
          {{ payment.installments }}x
          de
          R$ {{ payment.price | toMoney(payment.installments) }}
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import toSpecialtyName from '@/filters/specialtyName';
import toMoney from '@/filters/money';

export default {
  name: 'AppointmentSchedulingSummaryBox',

  filters: {
    toSpecialtyName,
    toMoney,
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('scheduling', ['appointment', 'office', 'payment']),

    methodName() {
      return this.payment?.method?.name;
    },

    summaryDatetime() {
      const [year, month, day] = this.appointment.date.split('-');
      const [hour, min] = this.appointment.time.split(':');

      return format(
        new Date(year, month - 1, day, hour, min),
        'iiii\',\' d \'de\' MMMM \'de\' yyyy \'às\' HH\':\'mm',
        { locale: ptBr },
      );
    },
  },
};
</script>
