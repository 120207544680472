import http from '../httpConfig';

const voucherValidation = ({
  healthPlacePartner = null,
} = {}) => {
  const partnerUuid = healthPlacePartner;

  const validateEligibility = (voucher = '') => new Promise((resolve, reject) => {
    const url = `/health_place_partner/${partnerUuid}/health_place_prepaid_voucher/name/${voucher}`;

    http.get(url)
      .then((res) => {
        const { code, data } = res.data;

        if (Number(code) === 200) {
          resolve(String(data?.['is_used']) === 'false');
        }
      })
      .catch((err) => {
        if (Number(err?.response?.data?.code) === 404) {
          resolve(false);
          return;
        }

        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
          && typeof err.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  return {
    validateEligibility,
  };
};

export default voucherValidation;
