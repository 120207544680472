<template>
  <article class="profile-tab__content">
    <ui-form
      :ref="'form-profile'"
      class="profile__form"
      :form-data="formData"
      :sending="ui.isLoading"
      :api-errors.sync="apiErrors"
      @valid="formValid = $event"
    />

    <ui-button
      :disabled="ui.isLoading || !formValid"
      width="auto"
      class="mx-auto w-64 mt-4"
      label="Salvar endereço"
      @click="submit"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formData from '@/data/user/address';
import searchAddress from '@/api/appointmentScheduling/address';
import address from '@/api/user/address';
import formUtils from '@/mixins/formUtils';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import completeStep from '@/mixins/completeStep';
import uiButton from '@/components/ui/Button.vue';
import uiForm from '@/components/ui/form/_form.vue';
import Loader from '@/components/ui/Loader.vue';

export default {
  name: 'UserProfileAddress',

  components: {
    uiForm,
    uiButton,
    Loader,
  },

  mixins: [
    formUtils,
    handleError,
    handleSuccess,
    completeStep,
  ],

  data() {
    return {
      formData,
      formValid: false,
      apiErrors: {},
      saveMethod: null,
      addressId: null,
      ui: {
        isLoading: false,
        hasSaved: true,
      },
      address: null,
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  watch: {
    'formData.cep.value': {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (
          newValue !== oldValue
          && newValue.length === 9
          && !this.ui.hasSaved
        ) {
          this.searchAddress(newValue);
        }
      },
    },
  },

  created() {
    this.destroyFormData();
  },

  mounted() {
    this.address = address(this.headers);
    this.getUserAddress();
  },

  destroyed() {
    this.destroyFormData();
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    submit() {
      const { city, cep, ...data } = this.expandForm(this.formData);

      const address = {
        city: { name: city },
        cep: cep.replace('-', ''),
        ...data,
      };

      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.address
        .save(this.saveMethod, address, this.addressId)
        .then(() => {
          this.saveMethod = 'put';
          this.handleSuccess('Endereço salvo com sucesso');
          this.$emit('saved');
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });

      return false;
    },

    searchAddress(cep) {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      Object.keys(this.formData).forEach((field) => {
        if (field !== 'cep') {
          this.formData[field].value = null;
        }
      });

      ['street', 'district', 'city', 'uf'].forEach((field) => {
        this.formData[field].disabled = true;
      });

      searchAddress({ cep })
        .getAddress()
        .then((res) => {
          delete res.cep;

          Object.keys(res).forEach((key) => {
            this.formData[key].value = res[key];
          });

          Object.keys(this.formData).forEach((key) => {
            if (!this.formData[key].value) {
              this.formData[key].disabled = false;
            }
          });
        })
        .catch((err) => {
          if (err.message === 'CEP não encontrado') {
            Object.keys(this.formData).forEach((key) => {
              if (key !== 'cep') {
                this.formData[key].value = null;
              }
            });

            this.openModalDialog({
              type: 'confirmation',
              title: 'CEP não encontrado',
              text: 'Deseja pesquisar um outro CEP ou editar o endereço manualmente?',
              cancelText: 'Pesquisar',
              confirmText: 'Editar',
              size: 'sm',
              fn: () => {
                Object.keys(this.formData).forEach((key) => {
                  this.formData[key].disabled = false;
                });

                this.closeModalDialog();
              },
            });
          }
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    getUserAddress() {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.address
        .get()
        .then((res) => {
          if (res) {
            const {
              id,
              cep,
              city,
              district,
              complement,
              street,
              number,
              uf,
            } = res;

            this.saveMethod = 'put';
            this.addressId = id;

            this.formData.cep.value = cep;
            this.formData.city.value = city.name;
            this.formData.district.value = district;
            this.formData.complement.value = complement;
            this.formData.street.value = street;
            this.formData.number.value = number;
            this.formData.uf.value = uf.name;

            return;
          }

          this.saveMethod = 'post';
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);

          this.$nextTick(() => {
            this.ui.hasSaved = false;
          });
        });
    },

    destroyFormData() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key].value = null;
      });
    },
  },
};
</script>
