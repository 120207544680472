import { required, email, sameAs } from 'vuelidate/lib/validators';
import { isBefore, isValid } from 'date-fns';
import { cpf } from 'cpf-cnpj-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import countries from '@/data/countries';

const formData = {
  email: {
    name: 'email',
    type: 'email',
    label: 'E-mail',
    placeholder: 'joao@silva.com.br',
    lazy: false,
    disabled: true,
    value: null,
    order: 1,
  },
  emailConfirmation: {
    name: 'emailConfirmation',
    type: 'email',
    label: 'Confirmar e-mail',
    placeholder: 'joao@silva.com.br',
    lazy: false,
    disabled: true,
    value: null,
    order: 2,
  },
  name: {
    name: 'name',
    type: 'text',
    label: 'Nome Completo',
    placeholder: 'João Silva',
    lazy: true,
    disabled: true,
    value: null,
    order: 3,
  },
  cpf: {
    name: 'cpf',
    type: 'tel',
    label: 'CPF',
    placeholder: '000.000.000-00',
    mask: ['###.###.###-##'],
    lazy: true,
    class: 'form__field--50',
    disabled: true,
    value: null,
    order: 4,
  },
  gender: {
    name: 'gender',
    type: 'radio',
    label: 'Gênero',
    lazy: true,
    disabled: true,
    value: null,
    class: 'form__field--50',
    options: [
      { value: 'M', label: 'Masculino' },
      { value: 'F', label: 'Feminino' },
    ],
    order: 5,
  },
  ddi: {
    name: 'ddi',
    type: 'select',
    label: 'DDI',
    class: 'form__field--15',
    lazy: true,
    disabled: true,
    value: null,
    options: countries,
    order: 6,
  },
  phoneNumber: {
    name: 'phoneNumber',
    type: 'tel',
    label: 'Celular',
    placeholder: '(00) 0000-0000',
    mask: ['(##) 9####-####'],
    class: 'form__field--35',
    lazy: true,
    disabled: true,
    value: null,
    order: 7,
  },
  birthdate: {
    name: 'birthdate',
    type: 'tel',
    label: 'Data de nascimento',
    placeholder: 'dd/mm/YYYY',
    mask: ['##/##/####'],
    class: 'form__field--50',
    lazy: true,
    disabled: true,
    value: null,
    order: 8,
  },
};

const validations = {
  name: {
    value: {
      required,
    },
  },
  email: {
    value: {
      required,
      email,
    },
  },
  emailConfirmation: {
    value: {
      required,
      email,
      sameAs: sameAs(function () {
        return this.formDataPersonal.email.value;
      }),
    },
  },
  cpf: {
    value: {
      required,
      isValid: (value) => cpf.isValid(value),
    },
  },
  gender: {
    value: {
      required,
      isValid: (value) => value === 'M' || value === 'F',
    },
  },
  ddi: {
    value: {
      required,
    },
  },
  phoneNumber: {
    value: {
      required,
      isValid(value) {
        if (value) {
          const { iso } = this.formDataPersonal.ddi.options.find(
            (option) => option.iso === this.formDataPersonal.ddi.value,
          );

          return isValidPhoneNumber(value, String(iso).toUpperCase());
        }

        return false;
      },
    },
  },
  birthdate: {
    value: {
      required,
      isValid: (value = null) => {
        if (value && value.length === 10) {
          const [day, month, year] = value.split('/');
          const birthdate = new Date(year, month - 1, day);

          return isValid(birthdate) && !isBefore(new Date(), birthdate);
        }

        return false;
      },
    },
  },
};

export { formData, validations };
