<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <modal @close="close">
      <template v-slot:title>
        Confirmação de identidade
      </template>

      <template v-slot:body>
        <div class="login-confirmation">
          <p class="login-confirmation__text">
            Informe seu Voucher para confirmar sua elegibilidade
            à consulta pré-paga.
          </p>

          <form @submit.prevent="validateUser">
            <div class="login-confirmation__wrapper login-confirmation__wrapper--row">
              <field
                :ref="'voucher-input'"
                v-model="voucher"
                :disabled.sync="ui.isLoading"
                type="text"
                class="prepaid__email"
                placeholder="Informe seu voucher"
              />
            </div>

            <div class="login-confirmation__footer">
              <div class="login-confirmation__button login-confirmation__button--larger">
                <ui-button
                  :disabled="ui.isLoading || $v.voucher.$invalid"
                  label="Validar voucher"
                  @click="validateUser"
                />
              </div>
            </div>
          </form>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import voucherValidation from '@/api/appointmentScheduling/voucher';
import handleError from '@/mixins/handleError';
import Modal from '@/components/ui/Modal.vue';
import Field from '@/components/ui/Field.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'VoucherConfirmation',

  mixins: [handleError],

  components: {
    Modal,
    Field,
    UiButton,
  },

  validations: {
    voucher: {
      required,
    },
  },

  data() {
    return {
      ui: {
        step: 1,
        isLoading: false,
      },
      voucher: null,
      code: null,
      isRegisteredUser: false,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
  },

  created() {
    this.$v.voucher.$reset();
  },

  destroyed() {
    this.voucher = null;
    this.voucherValidation = null;
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'completeProgressTrackerStep',
      'resetProgressTrackerStep',
      'openModalDialog',
      'closeModalDialog',
    ]),

    close() {
      this.$emit('close');
    },

    validateUser() {
      const prepaidApi = voucherValidation({
        healthPlacePartner: this.partner.uuid,
      });

      this.toggleProgressBar();
      this.ui.isLoading = true;

      prepaidApi
        .validateEligibility(this.voucher)
        .then((isVoucherValid) => {
          if (isVoucherValid) {
            this.$emit('voucher-confirmed', { voucher: this.voucher });
          } else {
            this.close();
            const msg = `O voucher informado não é elegível para este tipo
              de consulta. Verifique o código informado e, caso o problema persista,
              entre em contato com a central de atendimento.`;
            const err = new Error(msg);
            this.handleError(err);
          }
        })
        .catch((err) => {
          this.close();
          this.handleError(err);
        })
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.prepaid {
  &__email {
    @apply flex flex-row items-center justify-center w-full #{!important};

    div {
      @apply w-64 #{!important};
      width: 100%;

      input {
        @apply h-12 w-64 p-2 #{!important};
        width: 100%;

        &:active,
        &:focus {
          @apply bg-gray-100 #{!important};
        }
      }
    }
  }
}

.blend {
  &-enter {
    opacity: 0;
    display: none;
    height: auto;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      height: auto;
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
