<template>
  <div
    :class="{ 'field--hidden': hidden }"
    class="field"
  >
    <label
      v-if="label"
      :for="`form-field-${_uid}`"
      :class="{ 'field__label--disabled': isDisabled }"
      class="field__label"
    >
      {{ label }}
    </label>

    <div class="field__field">
      <img
        v-if="isValid || isInvalid"
        class="field__icon"
        :src="require(`@/assets/img/icons/${icon}`)"
      >

      <template v-if="['text', 'email', 'tel', 'search', 'url', 'number'].includes(type)">
        <input
          :id="`form-field-${_uid}`"
          v-model="localValue"
          v-mask="fieldMask"
          :type="type"
          :class="{
            'field__input--valid': isValid,
            'field__input--invalid': isInvalid,
            'field__input--disabled': isDisabled,
          }"
          class="field__input"
          :disabled="isDisabled"
          :placeholder="placeholder"
          @paste.prevent="onPaste"
        >
      </template>

      <template v-if="type === 'password'">
        <input
          :id="`form-field-${_uid}`"
          v-model="localValue"
          v-mask.sync="fieldMask"
          type="password"
          :class="{
            'field__input--valid': isValid,
            'field__input--invalid': isInvalid,
            'field__input--disabled': isDisabled,
          }"
          class="field__input"
          :disabled="isDisabled"
          :placeholder="placeholder"
          @paste.prevent="onPaste"
        >
        <!-- button: show password -->
        <!-- <div v-if="showPasswordIcon === true && field.value" class="viewPassword">
          <div v-if="!showPassword">
            <span
              @click="showPassword = !showPassword; field.type = 'text';"
              class="clickable viewPassword__icon"
            >
              <dr-icon
                name="visibilityOn"
                fill="#56bdd2"
                color="#56bdd2"
              />
            </span>
          </div>
          <div v-else>
            <span
              @click="showPassword = !showPassword; field.type = 'password';"
              class="clickable viewPassword__icon"
            >
              <dr-icon
                name="visibilityOff"
                fill="#56bdd2"
                color="#56bdd2"
              />
            </span>
          </div>
        </div> -->
        <!-- /button: show password -->
      </template>

      <template v-if="type === 'select'">
        <select
          :id="_uid"
          v-model.lazy="localValue"
          :disabled="isDisabled"
          :class="{
            'field__input--valid': isValid,
            'field__input--invalid': isInvalid,
            'field__input--disabled': isDisabled,
          }"
          class="field__input"
        >
          <option
            v-if="placeholder"
            disabled
            selected
            :value="null"
          >
            {{ placeholder }}
          </option>

          <option
            v-for="(option, index) in options"
            :key="`select-option-${index}`"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>

        <i class="icon icon-caret field__select-icon" />
      </template>

      <template v-if="type === 'radio'">
        <div class="flex mt-2">
          <label
            v-for="(radio, index) in options"
            :key="`select-radio-${index}`"
            :id="_uid"
            :class="{
              'field__input--valid': isValid,
              'field__input--invalid': isInvalid,
              'field__input--disabled': isDisabled,
            }"
            class="field__radio mr-4"
          >
            <input
              v-model.lazy="localValue"
              type="radio"
              :disabled="isDisabled"
              :value="radio.value"
            >
            <i class="field__radio--icon" />
            {{ radio.label }}
          </label>
        </div>
      </template>
    </div>

    <div
      v-show="message"
      :class="{
        'field__msg--valid': isValid,
        'field__msg--invalid': isInvalid,
      }"
      class="field__msg"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
import { mask } from 'ke-the-mask';

export default {
  name: 'Field',

  directives: {
    mask,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    type: {
      type: String,
      required: true,
      default: () => 'text',
    },

    value: {
      type: [String, Number, Object, Boolean],
      required: false,
      default: () => null,
    },

    options: {
      type: Array,
      required: false,
      default: () => null,
    },

    radios: {
      type: Array,
      required: false,
      default: () => null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    label: {
      type: String,
      required: false,
      default: () => null,
    },

    placeholder: {
      type: String,
      required: false,
      default: () => null,
    },

    mask: {
      type: [Array, String, Object, Function],
      required: false,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    isInvalid: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    message: {
      type: String,
      required: false,
      default: () => null,
    },

    hidden: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      localValue: null,
    };
  },

  computed: {
    icon() {
      return this.isValid
        ? 'ico-input-valid.svg'
        : 'ico-input-invalid.svg';
    },

    isDisabled() {
      return this.disabled || null;
    },

    fieldMask() {
      if (this.mask) {
        return this.mask;
      }

      return {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      };
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.localValue = value;
      },
    },

    localValue: {
      deep: true,
      immediate: false,
      handler(value) {
        this.$emit('change', value);
      },
    },
  },

  methods: {
    onPaste(evt) {
      const { selectionStart, selectionEnd } = evt.target;
      const initial = evt.target.value.slice(0, selectionStart, selectionEnd);
      const final = evt.target.value.slice(selectionEnd, evt.target.value.length);
      const pasted = evt.clipboardData.getData('Text');

      this.localValue = `${initial}${pasted}${final}`;
      const pos = this.localValue.indexOf(final);

      this.$nextTick(() => evt.target.setSelectionRange(pos, pos));
    },
  },
};
</script>
