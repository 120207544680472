<template>
  <section class="payment-method-picker">
    <h4 class="form__title">
      Método de pagamento
    </h4>

    <field
      v-model="method"
      type="select"
      placeholder="Escolha o método de pagamento"
      :options="paymentOptions"
      :disabled.sync="disabled"
    />

    <div class="wrapper">
      <transition
        name="fade"
        :duration="{ enter: 500, leave: 0 }"
      >
        <keep-alive>
          <component
            v-if="method"
            :is="payment.method.component"
            @reset-payment-method="resetPaymentMethod"
          />
        </keep-alive>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Field from '@/components/ui/Field.vue';
import creditCard from '@/components/appointmentScheduling/paymentMethods/creditCard.vue';
import boleto from '@/components/appointmentScheduling/paymentMethods/boleto.vue';
import pix from '@/components/appointmentScheduling/paymentMethods/pix.vue';
import voucher from '@/components/appointmentScheduling/paymentMethods/voucher.vue';
import prepaid from '@/components/appointmentScheduling/paymentMethods/prepaid.vue';

export default {
  name: 'PaymentMethodPicker',

  components: {
    Field,
    creditCard,
    boleto,
    pix,
    voucher,
    prepaid,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      method: null,
    };
  },

  computed: {
    ...mapGetters('partner', ['paymentMethods']),
    ...mapGetters('scheduling', ['payment']),

    paymentOptions() {
      return this.paymentMethods.map(({ label, value }) => ({ label, value }));
    },
  },

  watch: {
    method: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          const method = this.paymentMethods.find(({ value }) => value === newValue);
          this.setPayment({ method });
        } else {
          this.setPayment({ method: null });
        }
      },
    },
  },

  methods: {
    ...mapActions('scheduling', ['setPayment']),

    resetPaymentMethod() {
      // this.method = null;
    },

  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 1.5rem 0;
  width: 100%;

  .appointment-payment__col, .form {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
</style>
