import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('ui', ['openModalDialog', 'closeModalDialog']),

    handleSuccess(msg = null) {
      let message = msg || 'Ocorreu um erro';

      if (
        typeof message === 'object'
        && 'message' in message
      ) {
        message = message.message;
      }

      this.openModalDialog({
        type: 'alert',
        title: 'Sucesso',
        text: message,
        confirmText: 'Fechar',
        size: 'sm',
        fn: () => this.closeModalDialog(),
      });
    },
  },
};
