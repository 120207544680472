export default `
<h3>
  TERMOS E CONDIÇÕES DE USO DR. CONECTA.
</h3>

<p>
  Para iniciar a utilização da plataforma Dr. Conecta, disponível por aplicativo e website, necessário a leitura e
  confirmação deste Termo de Uso, leia com bastante atenção.
</p>

<p>
  Os Termos relativos aos serviços da plataforma Dr. Conecta estabelecem as condições gerais de uso e são de propriedade
  da empresa Dr. Conecta Tecnologia em Saúde S.A.
</p>

<p>
  Os serviços oferecidos pelo aplicativo Dr. Conecta são interativos e possibilitam acesso de informações a respeito de
  Médicos e Clínicas de Saúde, bem como serviços de agendamento de consultas e exames laboratoriais.
</p>

<p>
  Ao utilizar os serviços do Dr. Conecta, o usuário declara sob as penas da Lei que está de acordo com as disposições
  deste Termo, considerando-se sempre a versão na data da utilização, podendo este Termo ser modificado a critério do
  aplicativo Dr. Conecta a critério da empresa.
</p>

<ol>
  <li>
    Para utilizar o aplicativo Dr. Conecta, por aplicativo ou por website, o usuário deverá ter no mínimo 18 (dezoito)
    anos de idade, menores de 18 anos deverão ser cadastrados na conta dos pais ou responsáveis e por eles acompanhados
    em consulta. Uma vez atendido os requisitos e acessando o aplicativo, o Usuário automaticamente manifesta plena
    aceitação em relação a todos os termos e as condições ora estabelecidos, e concorda que estes consistem no acordo
    integral entre ele e a sociedade Dr. Conecta Tecnologia em Saúde S.A., sociedade limitada inscrita no CNPJ
    33.780.224/0001-12 o qual consiste no detentor de todos os direitos sobre o Site.

    <ol>
      <li>
        Ao acessar o aplicativo, o Usuário declara ser maior de 18 anos ou ser responsável pelos menores cadastrados sob
        sua inteira responsabilidade, e, aceita fornecer os seguintes dados: nome completo, e-mail, telefone celular,
        CPF e senha pessoal; para a ativação desse cadastro. O Usuário deverá inserir um código enviado por SMS no
        telefone celular informado.
      </li>

      <li>
        O Dr. Conecta é um aplicativo que oferece apenas serviços de agendamento online de consultas e exames de saúde.
        Não prestamos nenhum serviço assistencial de saúde. Para emergências e/ou ambulâncias entre em contato com o
        Corpo de Bombeiros (193) ou serviço de atendimento de urgência de sua cidade (192).
      </li>
    </ol>
  </li>

  <li>
    É exclusivamente reservado ao Dr. Conecta o direito de modificar o presente acordo, bem como interromper ou
    suspender o acesso a Plataforma (website e/ou aplicativo), a qualquer tempo, sem necessidade de prévio aviso.
  </li>

  <li>
    O Usuário deverá se manter atualizado em relação às modificações referentes ao Acordo, acessando-o periodicamente
    por meio da Plataforma (website e/ou aplicativo). O uso contínuo da Plataforma (website e/ou aplicativo) caracteriza
    a aceitação dos termos e condições do Acordo que vierem a sofrer alterações.
  </li>

  <li>
    É reservado exclusivamente ao Dr. Conecta o direito de impedir o acesso do usuário da Plataforma (website e/ou
    aplicativo), a qualquer tempo e sem prévia notificação, provisória ou definitivamente, caso sejam violados os termos
    e condições do presente TERMO ou norma legal aplicável, sem prejuízo da responsabilidade cível e criminal do usuário
    decorrente da respectiva violação. Da mesma forma, consiste em direito irrenunciável do usuário cancelar o seu
    cadastramento na Plataforma (website e/ou aplicativo), a qualquer tempo e sem qualquer custo.
  </li>

  <li>
    O nome de usuário e a senha de acesso a Plataforma (website e/ou aplicativo), são pessoais e intransferíveis, sendo
    de responsabilidade exclusiva do usuário e vedada a disponibilização a terceiros, o usuário se compromete a manter
    tais informações como confidenciais e, consequentemente, impedir que terceiros acessem o sistema utilizando tais
    informações.
  </li>

  <li>
    Em caso de extravio das informações ou de acesso de terceiros não autorizados, bem como sobre qualquer outra
    suspeita ou conhecimento acerca de falha de segurança, o usuário deverá informar imediatamente a empresa.
  </li>

  <li>
    A Plataforma Dr. Conecta não tem por objeto a publicidade de médicos ou produtos farmacológicos, ranking ou
    prestação de serviços médicos diretos (incluindo exames médicos); tão somente consiste em ferramenta de
    intermediação entre o usuário, médicos e clínicas de saúde previamente cadastrados (em conjunto, os “Prestadores”
    ou, individualmente, o “Prestador”), a ser utilizada estritamente para o agendamento e pagamento de consultas
    médicas e exames laboratoriais. Dessa forma, a Plataforma (website e/ou aplicativo) disponibilizará ao usuário
    informações gerais a respeito dos Prestadores, bem como funcionalidades de agendamento e intermediação de pagamentos
    por consultas médicas e exames laboratoriais.
  </li>

  <li>
    É vedado ao usuário agendar por meio da Plataforma (website e/ou aplicativo) atendimentos médicos, consultas e/ou
    exames médicos de caráter emergencial, sendo certo que quaisquer danos causados ao usuário em decorrência do
    descumprimento da presente condição deverão ser suportados exclusivamente por ele.
  </li>

  <li>
    A Plataforma Dr. Conecta, na qualidade de mero intermediador de serviços, jamais será responsabilizada por quaisquer
    fatos ou atos resultantes direta ou indiretamente das contratações de serviços intermediadas por meio do site,
    incluindo, mas sem se limitar a: erro médico, negligência médica, erros em exames, implicações tributárias
    envolvendo os serviços de saúde prestados, má qualidade de atendimento, insuficiência de informações, bem como danos
    de qualquer natureza causados por prestadores, inclusive danos morais e lucros cessantes.
  </li>

  <li>
    A Plataforma Dr. Conecta jamais será responsável perante o Usuário pelas informações constantes no aplicativo acerca
    dos Prestadores, e nem, tampouco, pelo grau de experiência profissional e qualificação deles. A Plataforma Dr.
    Conecta apenas se compromete a enviar seus melhores esforços para credenciar Prestadores aptos a executar atividades
    médicas por meio de validação de CRM ativo e outras informações profissionais informadas no cadastro dos
    Prestadores. Da mesma forma, jamais poderá ser responsabilizado pelas consequências decorrentes do descumprimento
    das obrigações relacionadas às consultas agendadas por meio da Plataforma (website e/ou aplicativo), incluindo
    cancelamento e atrasos.
  </li>

  <li>
    A Plataforma Dr. Conecta não é responsável pela definição e variação de preços de consultas e exames, sendo essa
    atribuição responsabilidade exclusiva dos Prestadores. A Plataforma tão somente define a taxa de intermediação
    cobrada do usuário por consulta ou exame agendado e este valor é sempre exibido no recibo de pagamento emitido pelo
    Dr. Conecta.
  </li>

  <li>
    O conteúdo e os serviços disponibilizados na Plataforma (website e/ou aplicativo) são oferecidos "tais como são" e
    sem garantia de nenhuma natureza.
  </li>

  <li>
    O Dr. Conecta concorda e reconhece que, pelos mais diversos fatores, e principalmente por se tratar de um ambiente
    eletrônico e virtual condicionado ao pleno acesso a Plataforma (website e/ou aplicativo) por parte do Usuário e dos
    Prestadores, a Plataforma (website e/ou aplicativo) poderá apresentar falhas, defeitos, incorreções e/ou
    interrupções, razão pela qual a Plataforma Dr. Conecta não garante o pleno funcionamento e a plena disponibilidade
    do site e do aplicativo, simultaneamente ou não, não se responsabilizando por eventuais danos causados ao usuário em
    decorrência de tal circunstância, seja de que natureza for, inclusive danos decorrentes de eventual cancelamento de
    consultas por falhas da Plataforma.
  </li>

  <li>
    A Plataforma Dr. CONCETA tão somente se compromete a envidar seus melhores esforços para sanar qualquer anormalidade
    no funcionamento dentro da menor brevidade possível.
  </li>

  <li>
    O usuário é exclusivamente o responsável, perante a Plataforma Dr. Conecta e/ou terceiros, por todas as práticas
    diretas e indiretas envolvendo o uso da Plataforma, incluindo o pagamento por exames e consultas médicas e a
    disponibilização das informações necessárias ao pagamento, como por exemplo número do Cartão de Crédito, nome
    completo, endereço, etc. (se possível elencar os dados que serão pedidos)
  </li>
</ol>

<h4>
  INSTRUÇÕES GERAIS PARA AGENDAMENTO DE CONSULTAS MÉDICAS
</h4>

<p>
  O agendamento de consultas médicas por meio da Plataforma seguirá as seguintes etapas consecutivas:
</p>

<ol>
  <li>
    Uma vez cadastrado, o usuário deverá realizar o login, utilizando o e-mail e senha de acesso;
  </li>

  <li>
    O usuário buscará os Prestadores, informando a especialidade médica e região próxima à qual deseja encontrá-los;
  </li>

  <li>
    Com base na respectiva pesquisa, a Plataforma apresentará uma lista de Prestadores cadastrados, indicando suas
    respectivas disponibilidades de agenda, sendo o critério de ordenamento dos Prestadores definido exclusivamente pela
    Plataforma;
  </li>

  <li>
    O usuário escolherá o Prestador e o horário de agenda disponível que desejar, passando à tela de fornecimento de
    dados;
  </li>

  <li>
    O usuário será encaminhado para a tela de pagamento;
  </li>

  <li>
    O usuário pagará o valor da consulta estabelecido pelo Prestador, acrescido da taxa de intermediação aplicada pela
    utilização da Plataforma.
  </li>

  <li>
    O agendamento ou a disponibilização de cada consulta só será confirmado mediante comprovação de pagamento; caso não
    haja pagamento confirmado, o agendamento será automaticamente cancelado, sem qualquer prejuízo ao usuário;
  </li>

  <li>
    O comprovante de pagamento e o código de confirmação da consulta serão disponibilizados no próprio aplicativo e/ou
    website.
  </li>
</ol>

<h4>
  INSTRUÇÕES GERAIS PARA AQUISIÇÃO E/OU AGENDAMENTO DE EXAMES MÉDICOS
</h4>

<p>
  O agendamento de exames médicos por meio da Plataforma seguirá as seguintes etapas consecutivas:
</p>

<ol>
  <li>
    Uma vez cadastrado, o usuário deverá realizar o login, utilizando o e-mail e senha de acesso;
  </li>

  <li>
    O usuário deverá enviar foto da receita médica correspondente ao(s) exame(s) que deseja realizar, selecionando um
    dos meios de transmissão de arquivos disponibilizados pelo aplicativo;
  </li>

  <li>
    O usuário informará seus critérios para geração de cotação de exames, tais como: localização e prioridade (melhor
    preço, distância ou avaliação);
  </li>

  <li>
    Com base na respectiva pesquisa, a Plataforma apresentará uma lista de Prestadores cadastrados, indicando suas
    respectivas disponibilidades - sendo o critério de ordenamento dos Prestadores definido exclusivamente pela
    Plataforma;
  </li>

  <li>
    O usuário escolherá o Prestador e, em se tratando de exame agendado, o horário de agenda disponível que desejar;
  </li>

  <li>
    O usuário será encaminhado para a tela de pagamento;
  </li>

  <li>
    O usuário pagará o valor do exame médico estabelecido pelo Prestador, acrescido da taxa de intermediação aplicada
    pela utilização da Plataforma.
  </li>

  <li>
    O agendamento ou a disponibilização de cada exame só será confirmado mediante comprovação de pagamento; caso não
    haja pagamento confirmado, o agendamento será automaticamente cancelado, sem qualquer prejuízo ao usuário;
  </li>

  <li>
    O comprovante de pagamento e o código de confirmação da consulta serão disponibilizados no próprio aplicativo e/ou
    website.
  </li>
</ol>

<h4>
  REAGENDAMENTO OU CANCELAMENTO DE CONSULTAS E EXAMES
</h4>

<ol>
  <li>
    Quando se tratar de consulta médica ou exame o objeto de agendamento, o usuário poderá reagendar e/ou desmarcar
    definitivamente a respectiva consulta ou exame por meio do app em até 48 (quarenta e oito) horas antes da respectiva
    data agendada.
  </li>

  <li>
    Na hipótese de desistência da consulta ou exame agendado com até 48 (quarenta e oito) horas de antecedência da
    respectiva data agendada, será estornado ao usuário o valor da consulta médica, no prazo máximo de 5 (cinco) dias
    úteis do pedido de estorno. Em nenhuma hipótese haverá estorno da taxa de intermediação paga em favor da Plataforma
    Dr. Conecta, já que o simples agendamento de consulta ou exame por meio da Plataforma enseja a efetivação da
    prestação dos serviços nele disponibilizados, e a incidência da referida taxa de intermediação consiste em
    contraprestação devida para a cobertura dos custos e das despesas de administração e manutenção da Plataforma.
  </li>

  <li>
    Após o prazo mínimo de 48 (quarenta e oito) horas antes de cada consulta ou exame agendado, será cobrado do usuário
    um valor correspondente a 50% do valor da consulta ou exame. Caso o usuário opte em reagendar o atendimento, ele
    terá um desconto de valor similar ao retido no agendamento anterior, desde que o novo agendamento ocorra em até 30
    dias.
  </li>

  <li>
    Na hipótese de exames médicos disponibilizados por Prestadores sem necessidade de agendamento, o usuário poderá
    cancelá-los por meio do Site em até 7 (sete) dias após a respectiva compra. Nesse caso, será estornado ao usuário o
    valor do exame médico, no prazo máximo de 5 (cinco) dias úteis a partir da solicitação de cancelamento do usuário, e
    em nenhuma hipótese haverá estorno da respectiva taxa de intermediação paga em favor da Plataforma, já que o simples
    agendamento do exame por meio da Plataforma enseja a efetiva prestação dos serviços nele disponibilizados, e a
    referida taxa de intermediação consiste em contraprestação devida para a cobertura dos custos e das despesas de
    administração e manutenção da Plataforma.
  </li>
</ol>

<h4>
  INSTRUÇÕES PARA REALIZAÇÃO DE CONSULTAS E EXAMES
</h4>

<ol>
  <li>
    O usuário deverá comparecer ao estabelecimento do Prestador sempre munido de documento de identidade e receita
    médica (quando aplicável).
  </li>

  <li>
    Na data da consulta ou exame agendado, o usuário deverá comparecer ao estabelecimento do Prestador com antecedência
    mínima de 30 (trinta) minutos.
  </li>

  <li>
    Todos e quaisquer exames não agendáveis deverão ser realizados dentro do prazo de validade estabelecidos por cada
    Prestador.
  </li>

  <li>
    Na hipótese de realização de exames que ensejem preparo específico (ex.: jejum), o usuário será integralmente
    responsável por se informar sobre tal preparo e realizá-lo. A Plataforma Dr. Conecta tão somente se compromete a
    envidar seus melhores esforços para enviar comunicação prévia ao usuário para informá-lo e lembrá-lo sobre o
    respectivo preparo.
  </li>

  <li>
    Todos e quaisquer exames não agendáveis deverão ser realizados dentro do prazo de validade estabelecidos por cada
    Prestador, respeitando o prazo máximo de até 30 dias.
  </li>
</ol>

<h4>
  PAGAMENTO PELOS SERVIÇOS CONTRATADOS NO SITE E TAXA DE INTERMEDIAÇÃO
</h4>

<ol>
  <li>
    A remuneração pela contratação do Prestador será paga eletronicamente pelo usuário por meio da Plataforma de empresa
    de meios de pagamento contratada.
  </li>

  <li>
    Para poder utilizar os serviços da empresa de meio de pagamentos contratada, o usuário deverá previamente aceitar
    seus termos e condições de uso, os quais poderão ser modificados esporadicamente pelo mesmo, sendo obrigação do
    usuário atualizar-se quanto às modificações que ocorrerem em tais termos e condições no momento da efetivação do
    pagamento.
  </li>

  <li>
    Sem, de modo algum, limitar a aplicação dos termos e condições da empresa de meio de pagamentos contratada, o
    Usuário concorda que a Plataforma Dr. Conecta cobrará uma taxa de intermediação sobre o preço de cada consulta ou
    exame agendado por meio da Plataforma, a qual estará incluída no valor total cobrado por cada consulta ou exame.
  </li>

  <li>
    A taxa de intermediação da Plataforma para agendamento de consultas médicas será no valor fixo de R$ 30,00 (trinta
    reais).
  </li>

  <li>
    A taxa de intermediação da Plataforma para agendamento de exames será 30% (trinta por cento) do valor do exame.
  </li>

  <li>
    O Usuário concorda em pagar por todos e quaisquer serviços intermediados por meio da Plataforma utilizando seu nome,
    cartão de crédito ou outras formas de pagamento. Todos os pagamentos feitos através de cartão de crédito estarão
    sujeitos à autorização e aprovação da administradora do cartão.
  </li>

  <li>
    A Plataforma DR. Conecta jamais será responsável por possíveis interrupções nos serviços de pagamentos de empresas
    contratadas, nem pelas falhas e/ou danos e prejuízos que o uso serviços possam gerar ao usuário.
  </li>

  <li>
    Toda discrepância, erro, omissão e/ou inconveniente referente ao sistema, forma e/ou instituição de pagamento será
    de exclusiva responsabilidade do usuário e das instituições de pagamento, não cabendo imputação alguma a Plataforma
    Dr. Conecta. Ao optar por utilizar o serviço de controle de pagamentos, a Plataforma Dr. Conecta não tem como evitar
    ocasionais falhas das referidas instituições e dos sistemas bancários e/ou administradoras de cartão de crédito.
  </li>

  <li>
    A Plataforma Dr. Conecta não se responsabiliza por falhas e/ou danos e prejuízos causados pelo uso dos serviços do
    site da empresa de meio de pagamentos contratada. Caso ocorram problemas devidos ao seu uso, o usuário deverá
    informar a empresa pelos meios previstos neste Acordo, não gerando obrigação para que a Plataforma Dr. Conecta
    resolva tais problemas.
  </li>
</ol>

<h4>
  DO COMPARTILHAMENTO DE EXAMES
</h4>

<ol>
  <li>
    O Usuário concorda com o compartilhamento de exames ao médico, desde que, mediante sua expressa autorização,
    isentando a plataforma de qualquer responsabilidade pela divulgação indevida destes exames que forem regularmente
    compartilhados por autorização do Usuário.
  </li>

  <li>
    O Usuário concorda, desde já, com o compartilhamento de suas receitas farmacêuticas e prescrições com os
    laboratórios de exames e laboratórios farmacêuticos cadastrados no sistema.
  </li>
</ol>

<h4>
  PROPRIEDADE INTELECTUAL
</h4>

<ol>
  <li>
    O Usuário concorda que todo o conteúdo e todas as funcionalidades disponíveis no app são, e continuarão sendo, de
    propriedade exclusiva da Plataforma Dr. Conecta, seus anunciantes e/ou licenciados, e que os mesmos se encontram
    protegidos como direitos autorais, marcas, segredos comerciais e outros direitos referentes a propriedade
    intelectual e/ou industrial previstos na Legislação Brasileira.
  </li>

  <li>
    O conteúdo da Plataforma (website e/ou aplicativo), incluindo textos, imagens, áudio e/ou vídeos, não poderá ser
    distribuído, modificado, alterado, transmitido, reutilizado ou reenviado a terceiros pelo Usuário, seja para qual
    finalidade for, sem a prévia e expressa autorização da Plataforma por escrito.
  </li>

  <li>
    É expressamente vedado ao Usuário utilizar os dados ou o conteúdo da Plataforma (website e/ou aplicativo) ou
    respectivos sites, para criar compilações e bases de dados de qualquer tipo sem a permissão por escrito da
    Plataforma Dr. Conecta. Sendo assim, é proibido o uso do conteúdo ou materiais da Plataforma (website e/ou
    aplicativo) e respectivos sites para qualquer propósito que não esteja expressamente autorizado neste Acordo.
  </li>
</ol>

<h4>
  POLÍTICA DE PRIVACIDADE
</h4>

<ol>
  <li>
    O Usuário concorda e reconhece que a Plataforma Dr. Conecta poderá armazenar informações e dados relacionados à sua
    identidade que forem necessários para o seu cadastramento na Plataforma (website e/ou aplicativo), bem como para a
    realização das atividades próprias da Plataforma, tais como: endereço de e-mail, endereços de IP, informações de
    data e horário de acesso, dentre outros (os “Dados Pessoais”). Esses Dados Pessoais poderão ser mantidos pela
    Plataforma Dr. Conecta pelo tempo necessário ao bom desenvolvimento das atividades inerentes ao app, e mesmo após o
    eventual encerramento da conta do usuário.
  </li>

  <li>
    Os Dados Pessoais deverão ser tratados como confidenciais, contudo, a Plataforma Dr. Conecta poderá revelar tais
    informações a terceiros sem o consentimento de Usuário quando

    <ol type="i">
      <li>
        seja determinado por lei ou por decisão judicial;
      </li>

      <li>
        para cooperar com autoridades governamentais em investigações;
      </li>

      <li>
        para proteger a propriedade intelectual ou direitos da Plataforma Dr. Conecta;
      </li>

      <li>
        para executar alguma das obrigações ou compromissos vinculados direta ou indiretamente com este Acordo ou o uso
        da Plataforma Dr. Conecta.
      </li>
    </ol>
  </li>

  <li>
    A Plataforma Dr. Conecta poderá também usar os Dados Pessoais identificados e modificá-los de modo a não os manter
    pessoais, combinando-os com outras informações sobre outros indivíduos (juntando sua informação com a de outras
    pessoas) ou através da supressão de características (como nome) que tornem os Dados Pessoais não identificáveis. A
    Plataforma Dr. Conecta poderá utilizar ou compartilhar com terceiros os Dados Pessoais não identificáveis, do modo
    que entender mais conveniente.
  </li>

  <li>
    O usuário concorda e aceita que a Plataforma Dr. Conecta possa compartilhar Dados Pessoais com os Prestadores e
    outros fornecedores relacionados ao Site, como empresas de transporte, call centers ou empresas de atendimento ao
    cliente, dentre outros. Nessas circunstâncias, existe a necessidade de compartilhar Dados Pessoais com tais
    empresas, e estas estão autorizadas a recompilar, receber e utilizar ditas informações para as finalidades ora
    previstas ou conforme requerido por lei.
  </li>

  <li>
    A Plataforma pode utilizar sistema de armazenamento mediante cookies, para que o acesso aos Dados Pessoais, quando
    da mudança de páginas, seja realizado com maior agilidade. Em alguns casos, os cookies também podem ajudar a
    identificar o usuário, sem necessidade de solicitar ologin de acesso novamente. Estes cookies são pequenos arquivos
    enviados pela página visitada e armazenados no disco rígido do computador do Usuário, ocupando pouco espaço.
    Cumpre-se informar ao usuário, que utilizando opções de seu navegador, pode ele limitar ou restringir o envio
    de cookies de acordo com a sua vontade, ainda que seja desaconselhável restringir totalmente tal envio.
  </li>

  <li>
    Os cookies também poderão coletar informações sobre as preferências e interesses do usuário. Caso isto ocorra, tais
    informações serão utilizadas exclusivamente com fins estatísticos, para melhorar os serviços prestados por
    intermédio do Plataforma. A Plataforma Dr. Conecta aplicará, o máximo possível, procedimentos de separação de
    informações de modo que os titulares dos dados não sejam identificados.
  </li>

  <li>
    Os Dados Pessoais também poderão ser utilizados com o propósito de personalizar e melhorar a Plataforma, assim como
    para estabelecer perfis específicos para fins promocionais, comerciais e publicitários ou que permitam mapear
    hábitos do Usuário. Para tanto, o usuário deverá estar ciente que todos os Dados Pessoais fornecidos poderão ser
    coletados e armazenados em base de dados de titularidade da empresa.
  </li>

  <li>
    Ao fornecer Dados Pessoais por meio do Plataforma, o usuário aceita e concorda que a Plataforma Dr. Conecta se
    comunique com ele por qualquer meio de comunicação existente no presente ou que venha a ser implementado no futuro,
    inclusive por meio de chamadas telefônicas, correio eletrônico, SMS, via postal, para enviar informações e
    divulgações sobre produtos e serviços da Plataforma Dr. Conecta, bem como qualquer outra informação que a Plataforma
    Dr. Conecta considere, a seu exclusivo critério, que possa ser de interesse do usuário, tais como ofertas de
    parceiros. Caso o usuário não queira receber determinadas mensagens, poderá desabilitar o recebimento delas na
    funcionalidade “alterar cadastro” (p. ex.: newsletters, campanhas de conscientização e recomendações de check-up
    médicos).
  </li>

  <li>
    Na Plataforma Dr. Conecta existem links que direcionam o Usuário para sites fora do controle do Dr. Conecta, tais
    como links de anunciantes, patrocinadores e parceiros. O Usuário reconhece que, ao clicar em qualquer um dos links
    mencionados, os sites aos quais for direcionado não se encontram controlados pelo Dr. Conecta. Nesses casos, outros
    termos de uso e política de privacidade serão aplicáveis, o que exclui a responsabilidade do Dr. Conecta.
  </li>
</ol>

<h4>
  DOS DADOS E DAS INFORMAÇOES PESSOAIS COLETADAS PELA PLATAFORMA DR. CONECTA
</h4>

<ol>
  <li>
    A plataforma Dr. Conecta utiliza-se da “TECNOLOGIA DE COOKIES” (dados no computador/aparelho móvel do Usuário) para
    permitir sua correta identificação, além de melhorar a qualidade das informações oferecidas em sua plataforma para
    os Usuários. O uso regular de cookies é uma prática aceitável na indústria, pois permite o armazenamento de
    informações importantes, como, por exemplo, os acessos do Usuário na plataforma Dr. Conecta, para posteriormente não
    ser apresentado um conteúdo repetido ou ultrapassado. Se não for a vontade do Usuário receber cookies, ou o mesmo
    desejar uma notificação prévia antes de seu uso, ele deverá programar, a seu critério, seu Web browser com estas
    opções, caso seu browser disponha do recurso. Vale ressaltar que caso o Usuário realize este bloqueio, a experiência
    de uso poderá ser comprometida.
  </li>

  <li>
    Além das informações pessoais fornecidas, a Plataforma Dr. Conecta tem a capacidade tecnológica de recolher outras
    informações técnicas, como por exemplo, o endereço de protocolo de Internet do Usuário, o sistema operacional do
    computador ou do celular, o tipo de browser e os websites de referência.
  </li>

  <li>
    A plataforma Dr. Conecta não fornecerá as informações do Usuário (e de seus pacientes) a terceiros sem prévia
    autorização do mesmo, com exceção de casos já previamente autorizados pelo presente Termo e para responder às
    solicitações ou perguntas de entidades governamentais, ou nos casos onde, de boa-fé, a Dr. Conecta entender que é
    necessária a sua divulgação, com o propósito de responder às reclamações em que há alegação de que o conteúdo
    submetido à plataforma Dr. Conecta infringe direitos de terceiros ou a legislação em vigor, ou seja, necessária para
    a proteção de direitos, propriedades e/ou segurança da plataforma Dr. Conecta, de seus USUÁRIOS e/ou do público em
    geral.
  </li>

  <li>
    A Dr. Conecta poderá, a seu critério, fazer uso das informações armazenadas nos seus bancos de dados, exceto com
    relação aos dados protegidos por sigilo legal, conforme descrito acima, por um prazo razoável, sem que exceda os
    requisitos ou limitações legais, para dirimir quaisquer disputas, solucionar problemas e garantir os direitos do Dr.
    Conecta, assim como os termos e condições presentes na Política de Privacidade.
  </li>

  <li>
    A plataforma Dr. Conecta deverá também, a seu critério, examinar as informações armazenadas nos seus bancos de
    dados, exceto com relação aos dados protegidos por sigilo legal, com o propósito de identificar Usuários com
    múltiplas identidades ou pseudônimos para fins legais e/ou de segurança. Em outra hipótese, se a Dr. Conecta for
    obrigada por lei, ordem judicial ou outro processo legal, a divulgar alguma informação pessoal do Usuário, não
    hesitará em cooperar com estes agentes, inclusive em relação aos dados protegidos por sigilo legal. Assim, por meio
    deste instrumento, o Usuário autoriza a plataforma a divulgar toda e qualquer informação contida no SOFTWARE para
    atender aos fins acima indicados, desde que sejam embasados em pedidos judiciais.
  </li>

  <li>
    As informações anônimas cedidas pelo Usuário, registradas devido ao uso do sistema (com exceção ao conteúdo de
    mensagens pessoais), servirão como insumos para o mapeamento de informações de mercado e formação de estatísticas da
    plataforma Dr. Conecta e/ou parceiros autorizados. Através do cadastramento, uso e fornecimento de informações à Dr.
    Conecta, o Usuário deliberadamente aceita o presente Termo e as condições previstas na Política de Privacidade sobre
    o uso de suas informações. No entanto, a plataforma Dr. Conecta ressalta que não acessará quaisquer informações de
    pacientes e os seus prontuários, cujo sigilo é protegido pela legislação brasileira e pelas resoluções de categoria
    profissional, exceto mediante ordem judicial.
  </li>

  <li>
    As informações cedidas pelo Usuário que o torna pessoalmente identificável têm como objetivo fazer com que os
    Usuários da Plataforma Dr. Conecta se relacionem melhor com as ferramentas disponíveis no SOFTWARE. Informações
    adicionais coletadas pela plataforma Dr. Conecta através da análise da navegação de cada Usuário e que não o torne
    identificável pessoalmente são de propriedade exclusiva da Dr. Conecta, que pode usar essas informações do modo que
    melhor julgar apropriada. Além disso, as informações fornecidas são usadas para: (i) administrar a conta dos
    Usuários a fim de customizar cada vez mais as ferramentas disponíveis na plataforma Dr. Conecta; e (ii) comunicar
    novidades e atualizações. A Dr. Conecta ainda pode retransmitir mensagens de parceiros, através da tecnologia opt-in
    (autorização para receber comunicações).
  </li>

  <li>
    O Usuário que não concordar com o presente Termo e proceder com o cancelamento de seu CADASTRO, removerá seus dados
    para futuras consultas, mas esta remoção não funcionará de maneira retroativa, ou seja, as ações anteriores à essa
    decisão continuarão armazenados e com uso de acordo com os interesses da plataforma Dr. Conecta respeitando a
    legislação vigente.
  </li>

  <li>
    Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades
    e operações do SOFTWARE na plataforma Dr. Conecta, bem como nas contas ou seus bancos de dados. Qualquer
    intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as
    proibições estipuladas neste Termo, tornarão o responsável passível das ações legais pertinentes, bem como das
    sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.
  </li>
</ol>

<h4>
  DO CONSENTIMENTO PARA TRATAMENTO DE DADOS
</h4>

<ol>
  <li>
    Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o
    tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de
    Proteção de Dados Pessoais (LGPD).
  </li>

  <li>
    Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda que a “DR. CONECTA TECNOLOGIA
    EM SAÚDE S.A.”, pessoa jurídica de direito privado, inscrita no CNPJ 33.780.224/0001-12, sediada à Avenida
    Brigadeiro Luís Antônio, 4553 – Jd. Paulista, Cep 01401-002, e-mail: atendimento@drconecta.com.br, doravante
    denominada Controladora, tome decisões referentes ao tratamento de seus dados pessoais, dados referentes as empresas
    em que atuem os usuários ou dados necessários ao usufruto de serviços ofertados, bem como realize o tratamento de
    tais dados, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização,
    acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou
    controle da informação, modificação, comunicação, transferência, difusão ou extração.
  </li>
</ol>

<h4>
  DOS DADOS PESSOAIS
</h4>

<ol>
  <li>
    A Dr. Conecta, controladora, fica autorizada a tomar decisões referentes ao tratamento e a realizar o tratamento dos
    seguintes dados pessoais do Titular:

    <ul>
      <li>Nome completo;</li>
      <li>Data de nascimento;</li>
      <li>Número e imagem da Carteira de Identidade (RG);</li>
      <li>Número e imagem do Cadastro de Pessoas Físicas (CPF);</li>
      <li>Número e imagem da Carteira Nacional de Habilitação (CNH);</li>
      <li>Fotografia;</li>
      <li>Estado civil;</li>
      <li>Nível de instrução ou escolaridade;</li>
      <li>Endereço completo;</li>
      <li>Números de telefone, WhatsApp e endereços de e-mail;</li>
      <li>Nome de usuário e senha específicos para uso dos serviços do Controlador;</li>
      <li>Comunicação, verbal e escrita, mantida entre o Titular e o Controlador.</li>
      <li>Exames laboratoriais e histórico de consultas.</li>
    </ul>
  </li>

  <li>
    Além disso, a Dr. Conecta, controladora, fica autorizada a tomar decisões referentes ao tratamento e a realizar o
    tratamento dos seguintes dados inseridos pelo Titular.
  </li>
</ol>

<h4>
  FINALIDADES DO TRATAMENTO DOS DADOS
</h4>

<ol>
  <li>
    O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:

    <ul>
      <li>Possibilitar que a Dr. Conecta identifique e entre em contato com o Titular para fins de relacionamento
        comercial.</li>
      <li>Possibilitar que a Dr. Conecta elabore contratos comerciais e emita cobranças contra o Titular.</li>
      <li>Possibilitar que a Dr. Conecta envie ou forneça ao Titular seus produtos e serviços, de forma remunerada ou
        gratuita.</li>
      <li>Possibilitar que a Dr. Conecta estruture, teste, promova e faça propaganda de produtos e serviços,
        personalizados ou não ao perfil do Titular.</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados em Pesquisas de Mercado;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados na inscrição, divulgação, premiação dos interessados
        participantes de Eventos, Prêmios ou Concursos;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados na elaboração de catálogos e na Curadoria de autores;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados na elaboração de relatórios e emissão de produtos e
        serviços;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados emissão de Notas Fiscais e documentos financeiros
        correlatos;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados para facilitar a prestação de serviços diversos além dos
        primariamente contratados, desde que o cliente também demonstre interesse em contratar novos serviços;</li>
      <li>Possibilitar que a Dr. Conecta utilize tais dados para manter banco de dados de profissionais do mercado para
        facilitar o contato em futuros convites para eventos;</li>
      <li>Cruzamento e análise de dados estatísticos de saúde de forma anonimizada.</li>
    </ul>
  </li>
</ol>

<h4>
  COMPARTILHAMENTO DOS DADOS
</h4>

<ol>
  <li>
    A Dr. Conecta fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados,
    caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas
    pela Lei nº 13.709.
  </li>
</ol>

<h4>
  SEGURANÇA DE DADOS
</h4>

<ol>
  <li>
    A Dr. Conecta responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger
    os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração,
    comunicação ou qualquer forma de tratamento inadequado ou ilícito.
  </li>

  <li>
    Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade Nacional de Proteção
    de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
  </li>
</ol>

<h4>
  TÉRMINO DO TRATAMENTO DOS DADOS
</h4>

<ol>
  <li>
    A Dr. Conecta, poderá manter e tratar os dados pessoais do Titular durante todo o período em que os mesmos forem
    pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de
    associação ao indivíduo, poderão ser mantidos por período indefinido.
  </li>

  <li>
    O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam eliminados os
    dados pessoais não anonimizados do Titular. O usuário fica ciente de que poderá ser inviável à Dr. Conecta continuar
    o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.
  </li>

  <li>
    A Dr. Conecta se resguarda ao direito de preservar dados, sempre que determinado por Lei ou pelo Conselho Federal de
    Medicina.
  </li>
</ol>

<h4>
  DISPOSIÇÕES FINAIS
</h4>

<ol>
  <li>
    O Usuário poderá contatar a Plataforma Dr. Conecta para tratar de questões referentes a Plataforma, incluindo
    dúvidas, reclamações e sugestões, por meio do endereço eletrônico: suporte@drconecta.com.br. A resposta da
    Plataforma Dr. Conecta em relação às demandas do Usuário deverá ser realizada em até 2(dois) dias.
  </li>

  <li>
    A propriedade da Plataforma poderá ser cedida e transferida a qualquer tempo pela empresa a terceiros, sem
    necessidade de anuência do usuário.
  </li>

  <li>
    Cada cláusula, item, alínea, período e frase deste Acordo constitui um compromisso ou disposição independente e
    distinto. Sempre que possível, cada cláusula deste Acordo deverá ser interpretada de modo a se tornar válida e
    eficaz à luz da lei aplicável. Caso alguma das cláusulas deste Acordo seja considerada ilícita, dita cláusula deverá
    ser julgada separadamente do restante do Acordo, e todas as demais cláusulas continuarão em pleno vigor.
  </li>

  <li>
    As partes contratantes elegem o foro da Comarca de São Paulo/SP, para a resolução de quaisquer controvérsias
    oriundas deste Acordo, renunciando a todo e qualquer outro, por mais privilegiado que seja.
  </li>
</ol>

<p>
  Seja bem-vindo em nossa plataforma,
</p>

<p>
  Atenciosamente Equipe Dr.Conecta.
</p>
`;
