<template>
  <label
    class="field__text"
    :class="validations"
    :for="fieldId"
  >
    <input
      :id="fieldId"
      v-model="field.value"
      type="email"
      :disabled="sending || field.disabled"
      @change="validations.$reset()"
      @focus="validations.$reset()"
      @blur="validations.$touch()"
    >
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
    };
  },
};
</script>
