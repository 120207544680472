<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      v-if="ui.localActive"
      @close="
        ui.localActive = false;
        $emit('closed', true);
      "
    >
      <template v-slot:title>
        Confirmação de {{ ui.step === 3 ? 'cadastro' : 'login' }}
      </template>

      <template v-slot:body>
        <transition name="blend">
          <template v-if="ui.step === 1">
            <div class="login-confirmation">
              <p class="login-confirmation__text">
                Identificamos que você já possui cadastro na nossa plataforma.
                Para prosseguir, te enviaremos um código para confirmar sua identidade.
                Selecione abaixo como deseja receber o código:
              </p>

              <div class="login-confirmation__wrapper">
                <div
                  v-for="(option, index) of options.filter((item) => item.label)"
                  :key="`confirmation-otpion-${index}`"
                  :class="{
                    'login-confirmation__type--selected':
                      JSON.stringify(option) === JSON.stringify(chosenOption)
                  }"
                  class="login-confirmation__type"
                  @click="!ui.isLoading && (chosenOption = option)"
                >
                  <h3 class="login-confirmation__title">
                    {{ option.text }}
                  </h3>

                  <p class="login-confirmation__text--sm">
                    {{ option.label }}
                  </p>
                </div>
              </div>

              <div
                v-if="ui.showCaptcha >= 3"
                class="login-confirmation__footer"
              >
                <div class="login-confirmation__button login-confirmation__button--larger">
                  <vue-recaptcha
                    ref="recaptcha"
                    size="checkbox"
                    :sitekey="captchaKey"
                    :load-recaptcha-script="true"
                    @verify="ui.isCaptchaVerified = true"
                  />
                </div>
              </div>

              <div class="login-confirmation__footer">
                <div class="login-confirmation__button login-confirmation__button--larger">
                  <ui-button
                    :disabled="
                      ui.isLoading
                        || !chosenOption
                        || (ui.showCaptcha >= 3 && !ui.isCaptchaVerified)
                    "
                    label="Enviar código de confirmação"
                    @click="sendConfirmationCode"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="ui.step === 2">
            <div class="login-confirmation">
              <p class="login-confirmation__text">
                Digite abaixo o código recebido por e-mail ou SMS:
              </p>

              <div class="login-confirmation__wrapper login-confirmation__wrapper--row">
                <input
                  :ref="'confirmation-code'"
                  v-model="code"
                  v-mask="['######']"
                  type="tel"
                  class="login-confirmation__code"
                  placeholder="●●●●●●"
                >
              </div>

              <div class="login-confirmation__footer">
                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading"
                    color="secondary"
                    label="Reenviar código"
                    @click="resetLoginConfirmation"
                  />
                </div>

                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading || !code || code.length < 6"
                    label="Confirmar"
                    @click="verifyConfirmationCode(code)"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="ui.step === 3">
            <div class="login-confirmation">
              <p class="login-confirmation__text">
                Digite abaixo o código recebido por SMS:
              </p>

              <div class="login-confirmation__wrapper login-confirmation__wrapper--row">
                <input
                  :ref="'confirmation-code'"
                  v-model="code"
                  v-mask="['######']"
                  type="tel"
                  class="login-confirmation__code"
                  placeholder="●●●●●●"
                >
              </div>

              <div class="login-confirmation__footer">
                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading"
                    color="secondary"
                    label="Reenviar código"
                    @click="resendConfirmationToken"
                  />
                </div>

                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading || !code || code.length < 6"
                    label="Confirmar"
                    @click="verifyToken(code)"
                  />
                </div>
              </div>
            </div>
          </template>
        </transition>
      </template>
    </modal>
  </transition>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'ke-the-mask';
import login from '@/api/appointmentScheduling/login';
import registerConfirmation from '@/api/user/registerConfirmation';
import handleError from '@/mixins/handleError';
import Modal from '@/components/ui/Modal.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'LoginConfirmation',

  directives: { mask },

  mixins: [handleError],

  components: {
    Modal,
    UiButton,
    VueRecaptcha,
  },

  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: () => false,
    },

    stepTo: {
      type: Number,
      default: null,
    },

    user: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      registerConfirmation: null,
      localPatient: null,
      login: null,
      ui: {
        step: 1,
        height: 0,
        isLoading: false,
        showCaptcha: 0,
        isCaptchaVerified: false,
        localActive: false,
      },
      chosenOption: null,
      options: [
        {
          text: 'por e-mail',
          value: 'email',
          label: null,
        },
        {
          text: 'por SMS',
          value: 'sms',
          label: null,
        },
      ],
      code: null,
      captchaKey: process.env.VUE_APP_GOOGLE_CAPTCHA,
    };
  },

  computed: {
    ...mapGetters('scheduling', ['patient']),
    ...mapGetters('partner', ['partner']),
  },

  watch: {
    'ui.step': {
      deep: false,
      immediate: true,
      handler(value) {
        if (value === 1) {
          this.ui.showCaptcha += 1;
        }

        if (value === 2) {
          this.$nextTick(() => {
            this.ui.isLoading = false;
            this.$refs['confirmation-code'].focus();
          });
        }
      },
    },

    isActive: {
      deep: false,
      immediate: true,
      handler(value) {
        this.ui.localActive = value;
        this.resetLoginConfirmation();
        if (this.stepTo === 3) {
          this.ui.step = this.stepTo;
          this.login = this.login ? this.login : login(this.user.email);
          // this.sendToken();
        }
      },
    },

    localPatient: {
      deep: true,
      immediate: true,
      handler(patient) {
        if (patient) {
          this.options[0].label = patient.email;
          this.options[1].label = this.maskPhone(patient.contactPhone);
          this.login = login(patient.email);
        }
      },
    },
  },

  created() {
    this.localPatient = this.user ? this.user : this.patient;
    this.registerConfirmation = registerConfirmation();
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    getStepHeight(element) {
      this.ui.height = getComputedStyle(element).height;
    },

    styleStep(element) {
      element.style.height = this.ui.height;
    },

    adjustStep(element) {
      element.classList.toggle('login-confirmation--absolute');
      element.style.height = 'auto';
    },

    maskPhone(number) {
      if (number) {
        const ddd = `(${number.slice(0, 2)})`;
        const show = number.slice(-4);
        return `${ddd} ${'*'.repeat(5)}-${show}`;
      }

      return number;
    },

    resetLoginConfirmation() {
      this.ui.step = 1;
      this.code = null;
      this.chosenOption = null;
      this.ui.isCaptchaVerified = false;
    },

    sendConfirmationCode() {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.login
        .sendConfirmationCode(this.chosenOption.value.toUpperCase(), this.partner.uuid)
        .then(() => {
          this.ui.step = 2;
        })
        .catch(this.handleError)
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    sendToken() {
      this.ui.isLoading = true;
      this.login
        .sendConfirmationCode('SMS', this.partner.uuid)
        .catch(this.handleError)
        .finally(() => {
          this.ui.isLoading = false;
        });
    },

    verifyConfirmationCode(code) {
      this.toggleProgressBar();
      this.ui.isLoading = true;

      this.login
        .confirmLogin(code)
        .then((data) => {
          this.$emit('login-confirmed', data);
        })
        .catch((err) => {
          if (/Código inválido/.test(err.message)) {
            this.openModalDialog({
              type: 'confirmation',
              title: 'Erro',
              text: 'O código informado é inválido.',
              cancelText: 'Tentar novamente',
              confirmText: 'Reenviar código',
              fn: () => {
                this.resetLoginConfirmation();
                this.closeModalDialog();
              },
            });
            return;
          }

          this.handleError(err.message);
        })
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        });
    },

    resendConfirmationToken() {
      this.toggleProgressBar();
      this.ui.isLoading = true;

      this.registerConfirmation
        .resendConfirmationToken('sms', this.localPatient.email)
        .then(() => {
          this.code = null;
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        });
    },

    verifyToken(token) {
      this.toggleProgressBar();
      this.ui.isLoading = true;

      this.registerConfirmation
        .validateToken(
          'cpf' in this.localPatient ? this.localPatient.cpf.replace(/[.-]/g, '') : null,
          'email' in this.localPatient ? this.localPatient.email : null,
          token,
        )
        .then((data) => this.$emit('login-confirmed', data))
        .catch((err) => {
          if (err) {
            this.openModalDialog({
              type: 'confirmation',
              title: 'Erro',
              text: 'O código informado é inválido.',
              cancelText: 'Tentar novamente',
              confirmText: 'Reenviar código',
              fn: () => {
                this.resetLoginConfirmation();
                this.closeModalDialog();
              },
            });

            return;
          }

          this.handleError(err);
        })
        .finally(() => {
          this.ui.isLoading = true;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>

<style lang="scss">
.blend {
  &-enter {
    opacity: 0;
    display: none;
    height: auto;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      height: auto;
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
