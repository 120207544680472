var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field",class:{ 'field--hidden': _vm.hidden }},[(_vm.label)?_c('label',{staticClass:"field__label",class:{ 'field__label--disabled': _vm.isDisabled },attrs:{"for":("form-field-" + _vm._uid)}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"field__field"},[(_vm.isValid || _vm.isInvalid)?_c('img',{staticClass:"field__icon",attrs:{"src":require(("@/assets/img/icons/" + _vm.icon))}}):_vm._e(),(['text', 'email', 'tel', 'search', 'url', 'number'].includes(_vm.type))?[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.fieldMask),expression:"fieldMask"}],staticClass:"field__input",class:{
          'field__input--valid': _vm.isValid,
          'field__input--invalid': _vm.isInvalid,
          'field__input--disabled': _vm.isDisabled,
        },attrs:{"id":("form-field-" + _vm._uid),"disabled":_vm.isDisabled,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.fieldMask),expression:"fieldMask"}],staticClass:"field__input",class:{
          'field__input--valid': _vm.isValid,
          'field__input--invalid': _vm.isInvalid,
          'field__input--disabled': _vm.isDisabled,
        },attrs:{"id":("form-field-" + _vm._uid),"disabled":_vm.isDisabled,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.fieldMask),expression:"fieldMask"}],staticClass:"field__input",class:{
          'field__input--valid': _vm.isValid,
          'field__input--invalid': _vm.isInvalid,
          'field__input--disabled': _vm.isDisabled,
        },attrs:{"id":("form-field-" + _vm._uid),"disabled":_vm.isDisabled,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}})]:_vm._e(),(_vm.type === 'password')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask.sync",value:(_vm.fieldMask),expression:"fieldMask",modifiers:{"sync":true}}],staticClass:"field__input",class:{
          'field__input--valid': _vm.isValid,
          'field__input--invalid': _vm.isInvalid,
          'field__input--disabled': _vm.isDisabled,
        },attrs:{"id":("form-field-" + _vm._uid),"type":"password","disabled":_vm.isDisabled,"placeholder":_vm.placeholder},domProps:{"value":(_vm.localValue)},on:{"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}})]:_vm._e(),(_vm.type === 'select')?[_c('select',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.localValue),expression:"localValue",modifiers:{"lazy":true}}],staticClass:"field__input",class:{
          'field__input--valid': _vm.isValid,
          'field__input--invalid': _vm.isInvalid,
          'field__input--disabled': _vm.isDisabled,
        },attrs:{"id":_vm._uid,"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.localValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.placeholder)?_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('option',{key:("select-option-" + index),domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2),_c('i',{staticClass:"icon icon-caret field__select-icon"})]:_vm._e(),(_vm.type === 'radio')?[_c('div',{staticClass:"flex mt-2"},_vm._l((_vm.options),function(radio,index){return _c('label',{key:("select-radio-" + index),staticClass:"field__radio mr-4",class:{
            'field__input--valid': _vm.isValid,
            'field__input--invalid': _vm.isInvalid,
            'field__input--disabled': _vm.isDisabled,
          },attrs:{"id":_vm._uid}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.localValue),expression:"localValue",modifiers:{"lazy":true}}],attrs:{"type":"radio","disabled":_vm.isDisabled},domProps:{"value":radio.value,"checked":_vm._q(_vm.localValue,radio.value)},on:{"change":function($event){_vm.localValue=radio.value}}}),_c('i',{staticClass:"field__radio--icon"}),_vm._v(" "+_vm._s(radio.label)+" ")])}),0)]:_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.message),expression:"message"}],staticClass:"field__msg",class:{
      'field__msg--valid': _vm.isValid,
      'field__msg--invalid': _vm.isInvalid,
    }},[_vm._v(" "+_vm._s(_vm.message)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }