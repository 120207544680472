const countries = [
  {
    name: 'Afeganistão',
    iso: 'af',
    flag: '🇦🇫',
    code: '93',
    mask: '### ### ####',
    placeholder: '070 123 4567',
  },
  {
    name: 'Albânia',
    iso: 'al',
    flag: '🇦🇱',
    code: '355',
    mask: '### ### ####',
    placeholder: '067 212 3456',
  },
  {
    name: 'Argélia',
    iso: 'dz',
    flag: '🇩🇿',
    code: '213',
    mask: '#### ## ## ##',
    placeholder: '0551 23 45 67',
  },
  {
    name: 'Samoa Americana',
    iso: 'as',
    flag: '🇦🇸',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(684) 733-1234',
  },
  {
    name: 'Andorra',
    iso: 'ad',
    flag: '🇦🇩',
    code: '376',
    mask: '### ###',
    placeholder: '312 345',
  },
  {
    name: 'Angola',
    iso: 'ao',
    flag: '🇦🇴',
    code: '244',
    mask: '### ### ###',
    placeholder: '923 123 456',
  },
  {
    name: 'Anguilla',
    iso: 'ai',
    flag: '🇦🇮',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(264) 235-1234',
  },
  {
    name: 'Antígua e Barbuda',
    iso: 'ag',
    flag: '🇦🇬',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(268) 464-1234',
  },
  {
    name: 'Argentina',
    iso: 'ar',
    flag: '🇦🇷',
    code: '54',
    mask: '### ##-####-####',
    placeholder: '011 15-2345-6789',
  },
  {
    name: 'Armênia',
    iso: 'am',
    flag: '🇦🇲',
    code: '374',
    mask: '### ######',
    placeholder: '077 123456',
  },
  {
    name: 'Aruba',
    iso: 'aw',
    flag: '🇦🇼',
    code: '297',
    mask: '### ####',
    placeholder: '560 1234',
  },
  {
    name: 'Ascension Island',
    iso: 'sh-ac',
    flag: '🇸🇭',
    code: '247',
    mask: '#####',
    placeholder: '40123',
  },
  {
    name: 'Austrália',
    iso: 'au',
    flag: '🇦🇺',
    code: '61',
    mask: '#### ### ###',
    placeholder: '0412 345 678',
  },
  {
    name: 'Áustria',
    iso: 'at',
    flag: '🇦🇹',
    code: '43',
    mask: '#### ######',
    placeholder: '0664 123456',
  },
  {
    name: 'Azerbaijão',
    iso: 'az',
    flag: '🇦🇿',
    code: '994',
    mask: '### ### ## ##',
    placeholder: '040 123 45 67',
  },
  {
    name: 'Bahamas',
    iso: 'bs',
    flag: '🇧🇸',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(242) 359-1234',
  },
  {
    name: 'Bahrain',
    iso: 'bh',
    flag: '🇧🇭',
    code: '973',
    mask: '#### ####',
    placeholder: '3600 1234',
  },
  {
    name: 'Bangladesh',
    iso: 'bd',
    flag: '🇧🇩',
    code: '880',
    mask: '#####-######',
    placeholder: '01812-345678',
  },
  {
    name: 'Barbados',
    iso: 'bb',
    flag: '🇧🇧',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(246) 250-1234',
  },
  {
    name: 'Belarus',
    iso: 'by',
    flag: '🇧🇾',
    code: '375',
    mask: '# ### ###-##-##',
    placeholder: '8 029 491-19-11',
  },
  {
    name: 'Bélgica',
    iso: 'be',
    flag: '🇧🇪',
    code: '32',
    mask: '#### ## ## ##',
    placeholder: '0470 12 34 56',
  },
  {
    name: 'Belize',
    iso: 'bz',
    flag: '🇧🇿',
    code: '501',
    mask: '###-####',
    placeholder: '622-1234',
  },
  {
    name: 'Benin',
    iso: 'bj',
    flag: '🇧🇯',
    code: '229',
    mask: '## ## ## ##',
    placeholder: '90 01 12 34',
  },
  {
    name: 'Bermuda',
    iso: 'bm',
    flag: '🇧🇲',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(441) 370-1234',
  },
  {
    name: 'Butão',
    iso: 'bt',
    flag: '🇧🇹',
    code: '975',
    mask: '## ## ## ##',
    placeholder: '17 12 34 56',
  },
  {
    name: 'Bolívia',
    iso: 'bo',
    flag: '🇧🇴',
    code: '591',
    mask: '########',
    placeholder: '71234567',
  },
  {
    name: 'Bósnia e Herzegóvina',
    iso: 'ba',
    flag: '🇧🇦',
    code: '387',
    mask: '### ### ###',
    placeholder: '061 123 456',
  },
  {
    name: 'Botswana',
    iso: 'bw',
    flag: '🇧🇼',
    code: '267',
    mask: '## ### ###',
    placeholder: '71 123 456',
  },
  {
    name: 'Brasil',
    iso: 'br',
    flag: '🇧🇷',
    code: '55',
    mask: '(##) 9####-####',
    placeholder: '(11) 96123-4567',
  },
  {
    name: 'Território do Oceano Índico Britânico',
    iso: 'io',
    flag: '🇮🇴',
    code: '246',
    mask: '### ####',
    placeholder: '380 1234',
  },
  {
    name: 'British Virgin Islands',
    iso: 'vg',
    flag: '🇻🇬',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(284) 300-1234',
  },
  {
    name: 'Brunei',
    iso: 'bn',
    flag: '🇧🇳',
    code: '673',
    mask: '### ####',
    placeholder: '712 3456',
  },
  {
    name: 'Bulgária',
    iso: 'bg',
    flag: '🇧🇬',
    code: '359',
    mask: '### ### ###',
    placeholder: '048 123 456',
  },
  {
    name: 'Burkina Faso',
    iso: 'bf',
    flag: '🇧🇫',
    code: '226',
    mask: '## ## ## ##',
    placeholder: '70 12 34 56',
  },
  {
    name: 'Burundi',
    iso: 'bi',
    flag: '🇧🇮',
    code: '257',
    mask: '## ## ## ##',
    placeholder: '79 56 12 34',
  },
  {
    name: 'Camboja',
    iso: 'kh',
    flag: '🇰🇭',
    code: '855',
    mask: '### ### ###',
    placeholder: '091 234 567',
  },
  {
    name: 'Camarões',
    iso: 'cm',
    flag: '🇨🇲',
    code: '237',
    mask: '# ## ## ## ##',
    placeholder: '6 71 23 45 67',
  },
  {
    name: 'Canadá',
    iso: 'ca',
    flag: '🇨🇦',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(506) 234-5678',
  },
  {
    name: 'Cabo Verde',
    iso: 'cv',
    flag: '🇨🇻',
    code: '238',
    mask: '### ## ##',
    placeholder: '991 12 34',
  },
  {
    name: 'Caribe Holandês',
    iso: 'bq',
    flag: '🇧🇶',
    code: '599',
    mask: '### ####',
    placeholder: '318 1234',
  },
  {
    name: 'Ilhas Caimã',
    iso: 'ky',
    flag: '🇰🇾',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(345) 323-1234',
  },
  {
    name: 'República Centro-Africana',
    iso: 'cf',
    flag: '🇨🇫',
    code: '236',
    mask: '## ## ## ##',
    placeholder: '70 01 23 45',
  },
  {
    name: 'Chade',
    iso: 'td',
    flag: '🇹🇩',
    code: '235',
    mask: '## ## ## ##',
    placeholder: '63 01 23 45',
  },
  {
    name: 'Chile',
    iso: 'cl',
    flag: '🇨🇱',
    code: '56',
    mask: '(#) #### ####',
    placeholder: '(2) 2123 4567',
  },
  {
    name: 'China',
    iso: 'cn',
    flag: '🇨🇳',
    code: '86',
    mask: '### #### ####',
    placeholder: '131 2345 6789',
  },
  {
    name: 'Ilha Christmas',
    iso: 'cx',
    flag: '🇨🇽',
    code: '61',
    mask: '#### ### ###',
    placeholder: '0412 345 678',
  },
  {
    name: 'Ilhas Cocos (Keeling)',
    iso: 'cc',
    flag: '🇨🇨',
    code: '61',
    mask: '#### ### ###',
    placeholder: '0412 345 678',
  },
  {
    name: 'Colômbia',
    iso: 'co',
    flag: '🇨🇴',
    code: '57',
    mask: '### #######',
    placeholder: '321 1234567',
  },
  {
    name: 'Comores',
    iso: 'km',
    flag: '🇰🇲',
    code: '269',
    mask: '### ## ##',
    placeholder: '321 23 45',
  },
  {
    name: 'Congo',
    iso: 'cg',
    flag: '🇨🇩',
    code: '243',
    mask: '#### ### ###',
    placeholder: '0991 234 567',
  },
  {
    name: 'República do Congo',
    iso: 'cd',
    flag: '🇨🇬',
    code: '242',
    mask: '## ### ####',
    placeholder: '06 123 4567',
  },
  {
    name: 'Ilhas Cook',
    iso: 'ck',
    flag: '🇨🇰',
    code: '682',
    mask: '## ###',
    placeholder: '71 234',
  },
  {
    name: 'Costa Rica',
    iso: 'cr',
    flag: '🇨🇷',
    code: '506',
    mask: '#### ####',
    placeholder: '8312 3456',
  },
  {
    name: 'Costa do Marfim',
    iso: 'ci',
    flag: '🇨🇮',
    code: '225',
    mask: '## ## ## ##',
    placeholder: '01 23 45 67',
  },
  {
    name: 'Croácia',
    iso: 'hr',
    flag: '🇭🇷',
    code: '385',
    mask: '### ### ####',
    placeholder: '092 123 4567',
  },
  {
    name: 'Cuba',
    iso: 'cu',
    flag: '🇨🇺',
    code: '53',
    mask: '## #######',
    placeholder: '05 1234567',
  },
  {
    name: 'Curaçao',
    iso: 'cw',
    flag: '🇨🇼',
    code: '599',
    mask: '# ### ####',
    placeholder: '9 518 1234',
  },
  {
    name: 'Chipre',
    iso: 'cy',
    flag: '🇨🇾',
    code: '357',
    mask: '## ######',
    placeholder: '96 123456',
  },
  {
    name: 'República Tcheca',
    iso: 'cz',
    flag: '🇨🇿',
    code: '420',
    mask: '### ### ###',
    placeholder: '601 123 456',
  },
  {
    name: 'Dinamarca',
    iso: 'dk',
    flag: '🇩🇰',
    code: '45',
    mask: '## ## ## ##',
    placeholder: '32 12 34 56',
  },
  {
    name: 'Djibouti',
    iso: 'dj',
    flag: '🇩🇯',
    code: '253',
    mask: '## ## ## ##',
    placeholder: '77 83 10 01',
  },
  {
    name: 'Dominica',
    iso: 'dm',
    flag: '🇩🇲',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(767) 225-1234',
  },
  {
    name: 'República Dominicana',
    iso: 'do',
    flag: '🇩🇴',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(809) 234-5678',
  },
  {
    name: 'Equador',
    iso: 'ec',
    flag: '🇪🇨',
    code: '593',
    mask: '### ### ####',
    placeholder: '099 123 4567',
  },
  {
    name: 'Egito',
    iso: 'eg',
    flag: '🇪🇬',
    code: '20',
    mask: '#### ### ####',
    placeholder: '0100 123 4567',
  },
  {
    name: 'El Salvador',
    iso: 'sv',
    flag: '🇸🇻',
    code: '503',
    mask: '#### ####',
    placeholder: '7012 3456',
  },
  {
    name: 'Guinea Equatorial',
    iso: 'gq',
    flag: '🇬🇶',
    code: '240',
    mask: '### ### ###',
    placeholder: '222 123 456',
  },
  {
    name: 'Guiana',
    iso: 'gy',
    flag: '🇬🇾',
    code: '592',
    mask: '### ####',
    placeholder: '609 1234',
  },
  {
    name: 'Eritreia',
    iso: 'er',
    flag: '🇪🇷',
    code: '291',
    mask: '## ### ###',
    placeholder: '07 123 456',
  },
  {
    name: 'Estônia',
    iso: 'ee',
    flag: '🇪🇪',
    code: '372',
    mask: '#### ####',
    placeholder: '5123 4567',
  },
  {
    name: 'Eswatini',
    iso: 'sz',
    flag: '🇸🇿',
    code: '268',
    mask: '#### ####',
    placeholder: '7612 3456',
  },
  {
    name: 'Etiópia',
    iso: 'et',
    flag: '🇪🇹',
    code: '251',
    mask: '### ### ####',
    placeholder: '091 123 4567',
  },
  {
    name: 'Ilhas Malvinas',
    iso: 'fk',
    flag: '🇫🇰',
    code: '500',
    mask: '#####',
    placeholder: '51234',
  },
  {
    name: 'Ilhas Faroé',
    iso: 'fo',
    flag: '🇫🇴',
    code: '298',
    mask: '######',
    placeholder: '211234',
  },
  {
    name: 'Fiji',
    iso: 'fj',
    flag: '🇫🇯',
    code: '679',
    mask: '### ####',
    placeholder: '701 2345',
  },
  {
    name: 'Finlândia',
    iso: 'fi',
    flag: '🇫🇮',
    code: '358',
    mask: '### #######',
    placeholder: '041 2345678',
  },
  {
    name: 'França',
    iso: 'fr',
    flag: '🇫🇷',
    code: '33',
    mask: '## ## ## ## ##',
    placeholder: '06 12 34 56 78',
  },
  {
    name: 'Guiana Francesa',
    iso: 'gf',
    flag: '🇬🇫',
    code: '594',
    mask: '#### ## ## ##',
    placeholder: '0694 20 12 34',
  },
  {
    name: 'Polinesia Francesa',
    iso: 'pf',
    flag: '🇵🇫',
    code: '689',
    mask: '## ## ## ##',
    placeholder: '87 12 34 56',
  },
  {
    name: 'Gabão',
    iso: 'ga',
    flag: '🇬🇦',
    code: '241',
    mask: '## ## ## ##',
    placeholder: '06 03 12 34',
  },
  {
    name: 'Gambia',
    iso: 'gm',
    flag: '🇬🇲',
    code: '220',
    mask: '### ####',
    placeholder: '301 2345',
  },
  {
    name: 'Georgia',
    iso: 'ge',
    flag: '🇬🇪',
    code: '995',
    mask: '### ## ## ##',
    placeholder: '555 12 34 56',
  },
  {
    name: 'Alemanha',
    iso: 'de',
    flag: '🇩🇪',
    code: '49',
    mask: '##### #######',
    placeholder: '01512 3456789',
  },
  {
    name: 'Gana',
    iso: 'gh',
    flag: '🇬🇭',
    code: '233',
    mask: '### ### ####',
    placeholder: '023 123 4567',
  },
  {
    name: 'Gibraltar',
    iso: 'gi',
    flag: '🇬🇮',
    code: '350',
    mask: '########',
    placeholder: '57123456',
  },
  {
    name: 'Grécia',
    iso: 'gr',
    flag: '🇬🇷',
    code: '30',
    mask: '### ### ####',
    placeholder: '691 234 5678',
  },
  {
    name: 'Greenland',
    iso: 'gl',
    flag: '🇬🇱',
    code: '299',
    mask: '## ## ##',
    placeholder: '22 12 34',
  },
  {
    name: 'Grenada',
    iso: 'gd',
    flag: '🇬🇩',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(473) 403-1234',
  },
  {
    name: 'Guadalupe',
    iso: 'gp',
    flag: '🇬🇵',
    code: '590',
    mask: '#### ## ## ##',
    placeholder: '0690 00 12 34',
  },
  {
    name: 'Guam',
    iso: 'gu',
    flag: '🇬🇺',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(671) 300-1234',
  },
  {
    name: 'Guatemala',
    iso: 'gt',
    flag: '🇬🇹',
    code: '502',
    mask: '#### ####',
    placeholder: '5123 4567',
  },
  {
    name: 'Guernsey',
    iso: 'gg',
    flag: '🇬🇬',
    code: '44',
    mask: '##### ######',
    placeholder: '07781 123456',
  },
  {
    name: 'Guiné',
    iso: 'gn',
    flag: '🇬🇳',
    code: '244',
    mask: '### ## ## ##',
    placeholder: '601 12 34 56',
  },
  {
    name: 'Guiné-Bissau',
    iso: 'gw',
    flag: '🇬🇼',
    code: '245',
    mask: '### ### ###',
    placeholder: '955 012 345',
  },
  {
    name: 'Haiti',
    iso: 'ht',
    flag: '🇭🇹',
    code: '509',
    mask: '## ## ####',
    placeholder: '34 10 1234',
  },
  {
    name: 'Honduras',
    iso: 'hn',
    flag: '🇭🇳',
    code: '504',
    mask: '####-####',
    placeholder: '9123-4567',
  },
  {
    name: 'Hong Kong',
    iso: 'hk',
    flag: '🇭🇰',
    code: '852',
    mask: '#### ####',
    placeholder: '5123 4567',
  },
  {
    name: 'Hungria',
    iso: 'hu',
    flag: '🇭🇺',
    code: '36',
    mask: '## ## ### ####',
    placeholder: '06 20 123 4567',
  },
  {
    name: 'Islândia',
    iso: 'is',
    flag: '🇮🇸',
    code: '354',
    mask: '### ####',
    placeholder: '611 1234',
  },
  {
    name: 'Índia',
    iso: 'in',
    flag: '🇮🇳',
    code: '91',
    mask: '###### #####',
    placeholder: '081234 56789',
  },
  {
    name: 'Indonesia',
    iso: 'id',
    flag: '🇮🇩',
    code: '62',
    mask: '####-###-###',
    placeholder: '0812-345-678',
  },
  {
    name: 'Irã',
    iso: 'ir',
    flag: '🇮🇷',
    code: '98',
    mask: '#### ### ####',
    placeholder: '0912 345 6789',
  },
  {
    name: 'Iraque',
    iso: 'iq',
    flag: '🇮🇶',
    code: '964',
    mask: '#### ### ####',
    placeholder: '0791 234 5678',
  },
  {
    name: 'Irlanda',
    iso: 'ie',
    flag: '🇮🇪',
    code: '353',
    mask: '### ### ####',
    placeholder: '085 012 3456',
  },
  {
    name: 'Isle of Man',
    iso: 'im',
    flag: '🇮🇲',
    code: '44',
    mask: '##### ######',
    placeholder: '07924 123456',
  },
  {
    name: 'Israel',
    iso: 'il',
    flag: '🇮🇱',
    code: '972',
    mask: '###-###-####',
    placeholder: '050-234-5678',
  },
  {
    name: 'Itália',
    iso: 'it',
    flag: '🇮🇹',
    code: '39',
    mask: '### ### ####',
    placeholder: '312 345 6789',
  },
  {
    name: 'Jamaica',
    iso: 'jm',
    flag: '🇯🇲',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(876) 210-1234',
  },
  {
    name: 'Japão',
    iso: 'jp',
    flag: '🇯🇵',
    code: '81',
    mask: '###-###-####',
    placeholder: '090-1234-5678',
  },
  {
    name: 'Jersey',
    iso: 'je',
    flag: '🇯🇪',
    code: '44',
    mask: '##### ######',
    placeholder: '07797 712345',
  },
  {
    name: 'Jordânia',
    iso: 'jo',
    flag: '🇯🇴',
    code: '962',
    mask: '## #### ####',
    placeholder: '07 9012 3456',
  },
  {
    name: 'Cazaquistão',
    iso: 'kz',
    flag: '🇰🇿',
    code: '7',
    mask: '# (###) ### ####',
    placeholder: '8 (771) 000 9998',
  },
  {
    name: 'Quênia',
    iso: 'ke',
    flag: '🇰🇪',
    code: '254',
    mask: '#### ######',
    placeholder: '0712 123456',
  },
  {
    name: 'Kiribati',
    iso: 'ki',
    flag: '🇰🇮',
    code: '686',
    mask: '########',
    placeholder: '72001234',
  },
  {
    name: 'Kosovo',
    iso: 'xk',
    flag: '🇽🇰',
    code: '383',
    mask: '### ### ###',
    placeholder: '043 201 234',
  },
  {
    name: 'Kuwait',
    iso: 'kw',
    flag: '🇰🇼',
    code: '965',
    mask: '### #####',
    placeholder: '500 12345',
  },
  {
    name: 'Kyrgysztan',
    iso: 'kg',
    flag: '🇰🇬',
    code: '996',
    mask: '#### ### ###',
    placeholder: '0700 123 456',
  },
  {
    name: 'Laos',
    iso: 'la',
    flag: '🇱🇦',
    code: '856',
    mask: '## ## ### ###',
    placeholder: '020 23 123 546',
  },
  {
    name: 'Latvia',
    iso: 'lv',
    flag: '🇱🇻',
    code: '371',
    mask: '## ### ###',
    placeholder: '21 234 567',
  },
  {
    name: 'Líbano',
    iso: 'lb',
    flag: '🇱🇧',
    code: '961',
    mask: '## ### ###',
    placeholder: '71 123 456',
  },
  {
    name: 'Lesotho',
    iso: 'ls',
    flag: '🇱🇸',
    code: '266',
    mask: '#### ####',
    placeholder: '5012 3456',
  },
  {
    name: 'Liberia',
    iso: 'lr',
    flag: '🇱🇷',
    code: '231',
    mask: '### ### ####',
    placeholder: '077 012 3456',
  },
  {
    name: 'Líbia',
    iso: 'ly',
    flag: '🇱🇾',
    code: '218',
    mask: '###-#######',
    placeholder: '091-2345678',
  },
  {
    name: 'Liechtenstein',
    iso: 'li',
    flag: '🇱🇮',
    code: '423',
    mask: '### ### ###',
    placeholder: '660 234 567',
  },
  {
    name: 'Lituânia',
    iso: 'lt',
    flag: '🇱🇹',
    code: '370',
    mask: '(#-###) #####',
    placeholder: '(8-612) 34567',
  },
  {
    name: 'Luxemburgo',
    iso: 'lu',
    flag: '🇱🇺',
    code: '352',
    mask: '### ### ###',
    placeholder: '628 123 456',
  },
  {
    name: 'Macêdonia',
    iso: 'mk',
    flag: '🇲🇰',
    code: '389',
    mask: '### ### ###',
    placeholder: '072 345 678',
  },
  {
    name: 'Macau',
    iso: 'mo',
    flag: '🇲🇴',
    code: '853',
    mask: '#### ####',
    placeholder: '6612 3456',
  },
  {
    name: 'Madagascar',
    iso: 'mg',
    flag: '🇲🇬',
    code: '261',
    mask: '### ## ### ##',
    placeholder: '032 12 345 67',
  },
  {
    name: 'Malawi',
    iso: 'mw',
    flag: '🇲🇼',
    code: '265',
    mask: '#### ## ## ##',
    placeholder: '0991 23 45 67',
  },
  {
    name: 'Malásia',
    iso: 'my',
    flag: '🇲🇾',
    code: '60',
    mask: '###-### ####',
    placeholder: '012-345 6789',
  },
  {
    name: 'Maldivas',
    iso: 'mv',
    flag: '🇲🇻',
    code: '960',
    mask: '###-####',
    placeholder: '771-2345',
  },
  {
    name: 'Mali',
    iso: 'ml',
    flag: '🇲🇱',
    code: '223',
    mask: '## ## ## ##',
    placeholder: '65 01 23 45',
  },
  {
    name: 'Malta',
    iso: 'mt',
    flag: '🇲🇹',
    code: '356',
    mask: '#### ####',
    placeholder: '9696 1234',
  },
  {
    name: 'Ilhas Marshall',
    iso: 'mh',
    flag: '🇲🇭',
    code: '692',
    mask: '###-####',
    placeholder: '235-1234',
  },
  {
    name: 'Martinique',
    iso: 'mq',
    flag: '🇲🇶',
    code: '596',
    mask: '#### ## ## ##',
    placeholder: '0696 20 12 34',
  },
  {
    name: 'Mauritânia',
    iso: 'mr',
    flag: '🇲🇷',
    code: '222',
    mask: '## ## ## ##',
    placeholder: '22 12 34 56',
  },
  {
    name: 'Mauritius',
    iso: 'mu',
    flag: '🇲🇺',
    code: '230',
    mask: '#### ####',
    placeholder: '5251 2345',
  },
  {
    name: 'Mayotte',
    iso: 'yt',
    flag: '🇾🇹',
    code: '262',
    mask: '#### ## ## ## ##',
    placeholder: '0639 01 23 45',
  },
  {
    name: 'México',
    iso: 'mx',
    flag: '🇲🇽',
    code: '52',
    mask: '### ### ####',
    placeholder: '222 123 4567',
  },
  {
    name: 'Micronesia',
    iso: 'fm',
    flag: '🇫🇲',
    code: '691',
    mask: '### ####',
    placeholder: '350 1234',
  },
  {
    name: 'Moldova',
    iso: 'md',
    flag: '🇲🇩',
    code: '373',
    mask: '#### ## ###',
    placeholder: '0621 12 345',
  },
  {
    name: 'Monaco',
    iso: 'mc',
    flag: '🇲🇨',
    code: '377',
    mask: '## ## ## ## ##',
    placeholder: '06 12 34 56 78',
  },
  {
    name: 'Mongolia',
    iso: 'mn',
    flag: '🇲🇳',
    code: '976',
    mask: '#### ####',
    placeholder: '8812 3456',
  },
  {
    name: 'Montenegro',
    iso: 'me',
    flag: '🇲🇪',
    code: '382',
    mask: '### ### ###',
    placeholder: '067 622 901',
  },
  {
    name: 'Montserrat',
    iso: 'ms',
    flag: '🇲🇸',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(664) 492-3456',
  },
  {
    name: 'Marrocos',
    iso: 'ma',
    flag: '🇲🇦',
    code: '212',
    mask: '####-######',
    placeholder: '0650-123456',
  },
  {
    name: 'Moçambique',
    iso: 'mz',
    flag: '🇲🇿',
    code: '258',
    mask: '## ### ####',
    placeholder: '82 123 4567',
  },
  {
    name: 'Myanmar',
    iso: 'mm',
    flag: '🇲🇲',
    code: '95',
    mask: '## ### ####',
    placeholder: '09 212 3456',
  },
  {
    name: 'Namibia',
    iso: 'na',
    flag: '🇳🇦',
    code: '264',
    mask: '### ### ####',
    placeholder: '081 123 4567',
  },
  {
    name: 'Nauru',
    iso: 'nr',
    flag: '🇳🇷',
    code: '674',
    mask: '### ####',
    placeholder: '555 1234',
  },
  {
    name: 'Nepal',
    iso: 'np',
    flag: '🇳🇵',
    code: '977',
    mask: '###-#######',
    placeholder: '984-1234567',
  },
  {
    name: 'Holanda',
    iso: 'nl',
    flag: '🇳🇱',
    code: '31',
    mask: '## ########',
    placeholder: '06 12345678',
  },
  {
    name: 'New Caledonia',
    iso: 'nc',
    flag: '🇳🇨',
    code: '687',
    mask: '##.##.##',
    placeholder: '75.12.34',
  },
  {
    name: 'Nova Zelândia',
    iso: 'nz',
    flag: '🇳🇿',
    code: '64',
    mask: '### ### ####',
    placeholder: '021 123 4567',
  },
  {
    name: 'Nicaragua',
    iso: 'ni',
    flag: '🇳🇮',
    code: '505',
    mask: '#### ####',
    placeholder: '8123 4567',
  },
  {
    name: 'Niger',
    iso: 'ne',
    flag: '🇳🇪',
    code: '227',
    mask: '## ## ## ##',
    placeholder: '93 12 34 56',
  },
  {
    name: 'Niue',
    iso: 'nu',
    flag: '🇳🇺',
    code: '683',
    mask: '### ####',
    placeholder: '888 4012',
  },
  {
    name: 'Ilhas Norfolk',
    iso: 'nf',
    flag: '🇳🇫',
    code: '672',
    mask: '# #####',
    placeholder: '3 81234',
  },
  {
    name: 'Coreia do Norte',
    iso: 'kp',
    flag: '🇰🇵',
    code: '850',
    mask: '#### ### ####',
    placeholder: '0192 123 4567',
  },
  {
    name: 'Northern Mariana Islands',
    iso: 'mp',
    flag: '🇲🇵',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(670) 234-5678',
  },
  {
    name: 'Noruega',
    iso: 'no',
    flag: '🇳🇴',
    code: '47',
    mask: '### ## ###',
    placeholder: '406 12 345',
  },
  {
    name: 'Oman',
    iso: 'om',
    flag: '🇴🇲',
    code: '968',
    mask: '#### ####',
    placeholder: '9212 3456',
  },
  {
    name: 'Paquistão',
    iso: 'pk',
    flag: '🇵🇰',
    code: '92',
    mask: '### #######',
    placeholder: '031 2345678',
  },
  {
    name: 'Palau',
    iso: 'pw',
    flag: '🇵🇼',
    code: '680',
    mask: '### ####',
    placeholder: '620 1234',
  },
  {
    name: 'Palestina',
    iso: 'ps',
    flag: '🇵🇸',
    code: '970',
    mask: '#### ### ###',
    placeholder: '0599 123 456',
  },
  {
    name: 'Panamá',
    iso: 'pa',
    flag: '🇵🇦',
    code: '507',
    mask: '####-####',
    placeholder: '6123-4567',
  },
  {
    name: 'Papua Nova Guiné',
    iso: 'pg',
    flag: '🇵🇬',
    code: '675',
    mask: '#### ####',
    placeholder: '7012 3456',
  },
  {
    name: 'Paraguai',
    iso: 'py',
    flag: '🇵🇾',
    code: '595',
    mask: '#### ######',
    placeholder: '0961 456789',
  },
  {
    name: 'Peru',
    iso: 'pe',
    flag: '🇵🇪',
    code: '51',
    mask: '### ### ###',
    placeholder: '912 345 678',
  },
  {
    name: 'Filipinas',
    iso: 'ph',
    flag: '🇵🇭',
    code: '63',
    mask: '#### ### ####',
    placeholder: '0905 132 4567',
  },
  {
    name: 'Polônia',
    iso: 'pl',
    flag: '🇵🇱',
    code: '48',
    mask: '### ### ###',
    placeholder: '512 345 678',
  },
  {
    name: 'Portugal',
    iso: 'pt',
    flag: '🇵🇹',
    code: '351',
    mask: '### ### ###',
    placeholder: '912 345 678',
  },
  {
    name: 'Porto Rico',
    iso: 'pr',
    flag: '🇵🇷',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(787) 234-5678',
  },
  {
    name: 'Qatar',
    iso: 'qa',
    flag: '🇶🇦',
    code: '974',
    mask: '#### ####',
    placeholder: '3312 3456',
  },
  {
    name: 'Réunion',
    iso: 're',
    flag: '🇷🇪',
    code: '262',
    mask: '#### ## ## ##',
    placeholder: '0692 12 34 56',
  },
  {
    name: 'Rússia',
    iso: 'ru',
    flag: '🇷🇺',
    code: '7',
    mask: '# (###) ###-##-##',
    placeholder: '8 (912) 345-67-89',
  },
  {
    name: 'Romênia',
    iso: 'ro',
    flag: '🇷🇴',
    code: '40',
    mask: '#### ### ###',
    placeholder: '0712 034 567',
  },
  {
    name: 'Ruanda',
    iso: 'rw',
    flag: '🇷🇼',
    code: '250',
    mask: '#### ### ###',
    placeholder: '0720 123 456',
  },
  {
    name: 'São Bartolomeu',
    iso: 'bl',
    flag: '🇧🇱',
    code: '590',
    mask: '#### ## ## ##',
    placeholder: '0690 00 12 34',
  },
  {
    name: 'Santa Helena',
    iso: 'sh-hl',
    flag: '🇸🇭',
    code: '290',
    mask: '#####',
    placeholder: '51234',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso: 'kn',
    flag: '🇰🇳',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(869) 765-2917',
  },
  {
    name: 'Saint Lucia',
    iso: 'lc',
    flag: '🇱🇨',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(758) 284-5678',
  },
  {
    name: 'Saint Martin',
    iso: 'mf',
    flag: '🇲🇫',
    code: '590',
    mask: '#### ## ## ##',
    placeholder: '0690 00 12 34',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso: 'pm',
    flag: '🇵🇲',
    code: '508',
    mask: '### ## ##',
    placeholder: '055 12 34',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'vc',
    flag: '🇻🇨',
    code: '508',
    mask: '(###) ###-####',
    placeholder: '(784) 430-1234',
  },
  {
    name: 'Samoa',
    iso: 'ws',
    flag: '🇼🇸',
    code: '685',
    mask: '## #####',
    placeholder: '72 12345',
  },
  {
    name: 'San Marino',
    iso: 'sm',
    flag: '🇸🇲',
    code: '378',
    mask: '## ## ## ##',
    placeholder: '66 66 12 12',
  },
  {
    name: 'Arábia Saudita',
    iso: 'sa',
    flag: '🇸🇦',
    code: '966',
    mask: '### ### ####',
    placeholder: '051 234 5678',
  },
  {
    name: 'Senegal',
    iso: 'sn',
    flag: '🇸🇳',
    code: '221',
    mask: '## ### ## ##',
    placeholder: '70 123 45 67',
  },
  {
    name: 'Sérvia',
    iso: 'rs',
    flag: '🇷🇸',
    code: '381',
    mask: '### #######',
    placeholder: '060 1234567',
  },
  {
    name: 'Seychelles',
    iso: 'sc',
    flag: '🇸🇨',
    code: '248',
    mask: '# ### ###',
    placeholder: '2 510 132',
  },
  {
    name: 'Sierra Leone',
    iso: 'sl',
    flag: '🇸🇱',
    code: '232',
    mask: '(###) ######',
    placeholder: '(025) 132546',
  },
  {
    name: 'Singapura',
    iso: 'sg',
    flag: '🇸🇬',
    code: '65',
    mask: '#### ####',
    placeholder: '8123 4567',
  },
  {
    name: 'Eslováquia',
    iso: 'sk',
    flag: '🇸🇰',
    code: '421',
    mask: '#### ### ###',
    placeholder: '0912 123 456',
  },
  {
    name: 'Eslovênia',
    iso: 'si',
    flag: '🇸🇮',
    code: '386',
    mask: '### ### ###',
    placeholder: '031 234 567',
  },
  {
    name: 'Ilhas Salomão',
    iso: 'sb',
    flag: '🇸🇧',
    code: '677',
    mask: '## #####',
    placeholder: '74 21234',
  },
  {
    name: 'Somália',
    iso: 'so',
    flag: '🇸🇴',
    code: '252',
    mask: '# #######',
    placeholder: '7 1123456',
  },
  {
    name: 'África do Sul',
    iso: 'za',
    flag: '🇿🇦',
    code: '27',
    mask: '### ### ####',
    placeholder: '071 132 4567',
  },
  {
    name: 'Coreia do Sul',
    iso: 'kr',
    flag: '🇰🇷',
    code: '82',
    mask: '###-####-####',
    placeholder: '010-2000-0000',
  },
  {
    name: 'Sudão do Sul',
    iso: 'ss',
    flag: '🇸🇸',
    code: '211',
    mask: '#### ### ###',
    placeholder: '0977 123 456',
  },
  {
    name: 'Espanha',
    iso: 'es',
    flag: '🇪🇸',
    code: '34',
    mask: '### ## ## ##',
    placeholder: '612 34 56 78',
  },
  {
    name: 'Sri Lanka',
    iso: 'lk',
    flag: '🇱🇰',
    code: '94',
    mask: '### ### ####',
    placeholder: '071 234 5678',
  },
  {
    name: 'Sudão',
    iso: 'sd',
    flag: '🇸🇩',
    code: '249',
    mask: '### ### ####',
    placeholder: '091 123 1234',
  },
  {
    name: 'Suriname',
    iso: 'sr',
    flag: '🇸🇷',
    code: '597',
    mask: '###-####',
    placeholder: '741-2345',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso: 'sj',
    flag: '🇸🇯',
    code: '47',
    mask: '### ## ###',
    placeholder: '412 34 567',
  },
  {
    name: 'Suécia',
    iso: 'se',
    flag: '🇸🇪',
    code: '46',
    mask: '###-### ## ##',
    placeholder: '070-123 45 67',
  },
  {
    name: 'Suíça',
    iso: 'ch',
    flag: '🇨🇭',
    code: '41',
    mask: '### ### ## ##',
    placeholder: '078 123 45 67',
  },
  {
    name: 'Síria',
    iso: 'sy',
    flag: '🇸🇾',
    code: '963',
    mask: '#### ### ###',
    placeholder: '0944 567 890',
  },
  {
    name: 'Taiwan',
    iso: 'tw',
    flag: '🇹🇼',
    code: '886',
    mask: '#### ### ###',
    placeholder: '0912 345 678',
  },
  {
    name: 'Taijikstan',
    iso: 'tj',
    flag: '🇹🇯',
    code: '992',
    mask: '### ## ####',
    placeholder: '917 12 3456',
  },
  {
    name: 'Tanzânia',
    iso: 'tz',
    flag: '🇹🇿',
    code: '256',
    mask: '#### ### ###',
    placeholder: '0621 234 567',
  },
  {
    name: 'Tailândia',
    iso: 'th',
    flag: '🇹🇭',
    code: '66',
    mask: '### ### ####',
    placeholder: '081 234 5678',
  },
  {
    name: 'Timor Leste',
    iso: 'tl',
    flag: '🇹🇱',
    code: '670',
    mask: '#### ####',
    placeholder: '7721 2345',
  },
  {
    name: 'Togo',
    iso: 'tg',
    flag: '🇹🇬',
    code: '228',
    mask: '## ## ## ##',
    placeholder: '90 11 23 45',
  },
  {
    name: 'Tokelau',
    iso: 'tk',
    flag: '🇹🇰',
    code: '690',
    mask: '####',
    placeholder: '7290',
  },
  {
    name: 'Tonga',
    iso: 'to',
    flag: '🇹🇴',
    code: '676',
    mask: '### ####',
    placeholder: '771 5123',
  },
  {
    name: 'Trinidad e Tobago',
    iso: 'tt',
    flag: '🇹🇹',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(868) 291-1234',
  },
  {
    name: 'Tunísia',
    iso: 'tn',
    flag: '🇹🇳',
    code: '216',
    mask: '## ### ###',
    placeholder: '20 123 456',
  },
  {
    name: 'Turquia',
    iso: 'tr',
    flag: '🇹🇷',
    code: '90',
    mask: '#### ### ## ##',
    placeholder: '0501 234 56 78',
  },
  {
    name: 'Turquemenistão',
    iso: 'tm',
    flag: '🇹🇲',
    code: '993',
    mask: '# ## ######',
    placeholder: '8 66 123456',
  },
  {
    name: 'Turks and Caicos Islands',
    iso: 'tc',
    flag: '🇹🇨',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(649) 231-1234',
  },
  {
    name: 'Tuvalu',
    iso: 'tv',
    flag: '🇹🇻',
    code: '688',
    mask: '## ####',
    placeholder: '90 1234',
  },
  {
    name: 'Ilhas Virgens',
    iso: 'vi',
    flag: '🇻🇮',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(340) 642-1234',
  },
  {
    name: 'Uganda',
    iso: 'ug',
    flag: '🇺🇬',
    code: '256',
    mask: '#### ######',
    placeholder: '0712 345678',
  },
  {
    name: 'Ucrânia',
    iso: 'ua',
    flag: '🇺🇦',
    code: '380',
    mask: '### ### ####',
    placeholder: '050 123 4567',
  },
  {
    name: 'Emirados Árabes Unidos',
    iso: 'ae',
    flag: '🇦🇪',
    code: '971',
    mask: '## ### ####',
    placeholder: '50 123 4567',
  },
  {
    name: 'Reino Unido',
    iso: 'gb',
    flag: '🇬🇧',
    code: '44',
    mask: '##### ######',
    placeholder: '07400 123456',
  },
  {
    name: 'Estados Unidos',
    iso: 'us',
    flag: '🇺🇸',
    code: '1',
    mask: '(###) ###-####',
    placeholder: '(201) 555-0123',
  },
  {
    name: 'Uruguai',
    iso: 'uy',
    flag: '🇺🇾',
    code: '598',
    mask: '### ### ###',
    placeholder: '094 231 324',
  },
  {
    name: 'Usbequistão',
    iso: 'uz',
    flag: '🇺🇿',
    code: '998',
    mask: '# ## ### ## ##',
    placeholder: '8 91 234 56 78',
  },
  {
    name: 'Vanuatu',
    iso: 'vu',
    flag: '🇻🇺',
    code: '678',
    mask: '### ####',
    placeholder: '591 2345',
  },
  {
    name: 'Cidade do Vaticano',
    iso: 'va',
    flag: '🇻🇦',
    code: '379',
    mask: '### ### ####',
    placeholder: '312 345 6789',
  },
  {
    name: 'Venezuela',
    iso: 've',
    flag: '🇻🇪',
    code: '58',
    mask: '####-#######',
    placeholder: '0412-1234567',
  },
  {
    name: 'Vietnã',
    iso: 'vn',
    flag: '🇻🇳',
    code: '84',
    mask: '### ### ## ##',
    placeholder: '091 234 56 78',
  },
  {
    name: 'Wallis and Futuna',
    iso: 'wf',
    flag: '🇼🇫',
    code: '681',
    mask: '## ## ##',
    placeholder: '50 12 34',
  },
  {
    name: 'Saara Ocidental',
    iso: 'eh',
    flag: '🇪🇭',
    code: '212',
    mask: '####-######',
    placeholder: '0650-123456',
  },
  {
    name: 'Iêmen',
    iso: 'ye',
    flag: '🇾🇪',
    code: '967',
    mask: '#### ### ###',
    placeholder: '0712 345 678',
  },
  {
    name: 'Zâmbia',
    iso: 'zm',
    flag: '🇿🇲',
    code: '260',
    mask: '### #######',
    placeholder: '095 5123456',
  },
  {
    name: 'Zimbábue',
    iso: 'zw',
    flag: '🇿🇼',
    code: '263',
    mask: '### ### ####',
    placeholder: '071 234 5678',
  },
  {
    name: 'Ilhas Åland',
    iso: 'ax',
    flag: '🇦🇽',
    code: '358',
    mask: '### #######',
    placeholder: '041 2345678',
  },
];

countries.sort((a, b) => a.name.localeCompare(b.name));
const index = countries.findIndex((country) => country.iso === 'br');
countries.splice(0, 0, countries.splice(index, 1)[0]);

export default countries.map(
  (item) => ({
    label: `+${item.code} ${item.flag}    ${item.name}`,
    value: item.iso,
    mask: item.mask,
    code: item.code,
    iso: item.iso,
    placeholder: item.placeholder,
  }),
);
