<style lang="scss" scoped>
label {
  @apply relative
}
.viewPassword {
  @apply absolute text-neutral block;
  right: 5px;
  bottom: 5px;
}
</style>

<template>
  <label
    class="field__text"
    :class="validations"
    :for="fieldId"
  >
    <a
      v-if="field.value"
      title="exibir/ocultar"
      class="viewPassword"
      @click="showPassword = !showPassword; localType = localType === 'text' ? 'password' : 'text'"
    >
      <i :class="!showPassword ? 'icss-eye-slash' : 'icss-eye'" />
    </a>
    <input
      :id="fieldId"
      v-model="field.value"
      :type="localType"
      :minlength="field.minlength"
      :maxlength="field.maxlength"
      :disabled="sending || field.disabled"
      @input="validations.$reset()"
      @blur="validations.$touch()"
    >
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
      showPassword: false,
      localType: 'password',
    };
  },
};
</script>
