<template>
  <label
    class="new_field__select"
    :for="fieldId"
  >
    <select
      :id="fieldId"
      v-model="field.value"
      :disabled="sending || field.disabled"
      @blur="validations ? validations.$touch() : ''"
    >
      <option
        :disabled="field.required"
        :value="null"
      >{{ field.placeholder || 'Selecione' }}</option>
      <option
        v-for="option in field.options"
        :key="`option${option.value}`"
        :value="option.value"
      >{{ option.label }}</option>
    </select>
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: [Object, Array],
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
    };
  },
};
</script>
