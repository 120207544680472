import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('ui', ['openModalDialog', 'closeModalDialog']),

    handleError(err = null, btn = null, cb = null) {
      const confirmText = btn || 'Fechar';
      const fn = cb || this.closeModalDialog;
      let errorMessage = err || 'Ocorreu um erro';

      if (typeof errorMessage === 'object' && 'message' in errorMessage) {
        errorMessage = errorMessage.message;
      }

      this.openModalDialog({
        type: 'alert',
        title: 'Erro',
        text: errorMessage,
        confirmText,
        size: 'sm',
        fn,
        cancelFn: fn,
      });
    },
  },
};
