import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { lastDayOfMonth, isAfter, isValid } from 'date-fns';

const formData = {
  cardNumber: {
    name: 'cardNumber',
    type: 'tel',
    label: 'Número do cartão',
    placeholder: '0000 0000 0000 0000',
    mask: ['#### #### #### ####'],
    lazy: true,
    disabled: true,
    value: null,
    order: 1,
  },
  expirationDate: {
    name: 'expirationDate',
    type: 'tel',
    label: 'Expira em',
    placeholder: 'mm/YY',
    mask: ['##/##'],
    class: 'form__field--80',
    lazy: true,
    disabled: true,
    value: null,
    order: 2,
  },
  cvc: {
    name: 'cvc',
    type: 'tel',
    label: 'CVC',
    placeholder: '000',
    mask: ['###'],
    class: 'form__field--20',
    lazy: true,
    disabled: true,
    value: null,
    order: 3,
  },
  name: {
    name: 'name',
    type: 'text',
    label: 'Nome (como está no cartão)',
    placeholder: 'João Silva',
    lazy: true,
    disabled: true,
    value: null,
    order: 4,
  },
  installmentsNumber: {
    name: 'installmentsNumber',
    type: 'select',
    label: 'Opções de parcelamento',
    placeholder: 'Escolha uma opção',
    options: [],
    disabled: true,
    value: null,
    order: 5,
  },
};

const validations = {
  cardNumber: {
    value: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(19),
    },
  },
  expirationDate: {
    value: {
      required,
      isValid: (value = null) => {
        if (value && value.length === 5) {
          const [month, year] = value.split('/');
          const dueDate = lastDayOfMonth(new Date(`20${year}`, month - 1, 1, 0, 0, 0));

          return isValid(dueDate) && isAfter(dueDate, new Date());
        }

        return false;
      },
    },
  },
  cvc: {
    value: {
      required,
      minLength: minLength(3),
    },
  },
  name: {
    value: {
      required,
    },
  },
  installmentsNumber: {
    value: {
      required,
    },
  },
};

export { formData, validations };
