import http from '../httpConfig';

const address = (
  {
    cep,
    latitude,
    longitude,
    address,
  } = {
    cep: '',
    latitude: '',
    longitude: '',
    address: '',
  },
) => {
  const apiKey = process.env.VUE_APP_GOOGLE_MAPS;
  const gmap = `https://maps.google.com/maps/api/geocode/json?key=${apiKey}`;
  const addressEndpoint = `https://viacep.com.br/ws/${String(cep).replace(/\D/g, '')}/json`;
  const cepEndpoint = `${gmap}&address=${latitude},${longitude}&sensor=false`;
  const mapEndpoint = `${gmap}&address=${address}&sensor=false`;
  const coordsEndpoint = `${gmap}&address=${cep}&sensor=false`;

  const getAddress = () => new Promise((resolve, reject) => {
    http.get(addressEndpoint)
      .then((res) => {
        if ('erro' in res.data) {
          reject(new Error('CEP não encontrado'));
          return;
        }

        const {
          cep,
          logradouro: street,
          bairro: district,
          localidade: city,
          uf,
        } = res.data;

        resolve({
          cep,
          street,
          district,
          city,
          uf,
        });
      })
      .catch(reject);
  });

  const getCep = () => new Promise((resolve, reject) => {
    http.get(cepEndpoint)
      .then((res) => {
        const { results } = res.data;

        if (
          results.length > 0
          && 'address_components' in results[0]
        ) {
          const code = results[0]['address_components']
            .find((item) => item.types.includes('postal_code'));

          resolve(code['long_name']);

          return;
        }

        reject();
      })
      .catch(reject);
  });

  const getCoords = () => new Promise((resolve, reject) => {
    http.get(coordsEndpoint)
      .then((res) => {
        const { results } = res.data;

        if (
          results.length > 0
          && 'geometry' in results[0]
        ) {
          const { lat, lng } = results[0].geometry.location;

          resolve({ latitude: lat, longitude: lng });

          return;
        }

        reject();
      })
      .catch(reject);
  });

  const getMapAddress = () => new Promise((resolve, reject) => {
    const endpoint = address ? mapEndpoint : cepEndpoint;

    http.get(endpoint)
      .then((res) => {
        const address = res.data.results[0]['formatted_address'];
        const latitude = res.data.results[0].geometry.location.lat;
        const longitude = res.data.results[0].geometry.location.lng;

        resolve({ address, latitude, longitude });
      })
      .catch(reject);
  });

  return {
    getAddress,
    getCep,
    getCoords,
    getMapAddress,
  };
};

export default address;
