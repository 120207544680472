import http from '../httpConfig';

const profile = (headers) => {
  const fetch = () => new Promise((resolve, reject) => {
    http.get('/user', headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  const update = (data) => new Promise((resolve, reject) => {
    http.put('/user', data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });

  const fetchPicture = () => new Promise((resolve, reject) => {
    http.get('/user/profile_picture', headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  const uploadPicture = (data) => new Promise((resolve, reject) => {
    function getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => formData.append(key, object[key]));
      return formData;
    }
    const fileHeaders = JSON.parse(JSON.stringify(headers));
    fileHeaders.headers['Content-Type'] = `multipart/form-data; charset=utf-8; boundary=${Math.random().toString().substr(2)}`;

    http.post('/user/profile_picture', getFormData(data), fileHeaders)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });

  return {
    fetch,
    update,
    fetchPicture,
    uploadPicture,
  };
};

export default profile;
