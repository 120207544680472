<template>
  <label
    class="field__text"
    :class="validations"
    :for="fieldId"
  >
    <div class="flex mt-2">
      <label
        class="field__radio mr-4"
        v-for="(radio, index) in field.radios"
        :key="`select-radio-${index}`"
      >
        <input
          type="radio"
          v-model.lazy="field.value"
          :disabled="field.disabled"
          :value="radio.value"
        >
        <i class="field__radio--icon" />
        {{ radio.label }}
      </label>
    </div>
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
import { mask } from 'ke-the-mask';

export default {
  directives: {
    mask,
  },

  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
    };
  },
};
</script>
