import { render, staticRenderFns } from "./_email.vue?vue&type=template&id=50ec9f6e&"
import script from "./_email.vue?vue&type=script&lang=js&"
export * from "./_email.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports