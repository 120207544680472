<style lang="scss" scoped>
.prepaid {
  &__title {
    @apply text-lg font-semibold font-text my-4 mt-8;
    color: var(--color-text);
  }

  &__text {
    @apply text-base;
    color: var(--color-text);
    line-height: 1.8;
  }
}
</style>

<template>
  <section class="appointment-payment__col prepaid">
    <h3 class="prepaid__title">
      Instruções para agendamento Benefício {{ partner.name }}
    </h3>

    <p class="prepaid__text mt-6">
      Ao concluir esse agendamento você receberá um e-mail de
      confirmação da transação. Você não precisará fazer nada
      em relação ao pagamento, apenas esteja atento as
      nofificações para o início de sua consulta.
    </p>

    <transition name="fade">
      <prepaid-confirmation
        v-if="showConfirmation"
        @close="close"
        @eligibilityConfirmed="sendcode"
      />
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <loader
        v-if="ui.isLoading"
        message="Aguarde um instante enquanto validamos sua elegibilidade."
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import prepaidConfirmation from '@/components/appointmentScheduling/paymentMethods/prepaidConfirmation.vue';
import prepaidValidation from '@/api/appointmentScheduling/prepaid';
import Loader from '@/components/ui/Loader.vue';

export default {
  name: 'Prepaid',

  components: {
    prepaidConfirmation,
    Loader,
  },

  data() {
    return {
      ui: {
        step: 1,
        isLoading: false,
      },
      showConfirmation: false,
    };
  },

  mounted() {
    this.showConfirmation = this.patient.cpf === null;

    if (!this.showConfirmation) {
      this.validateUserElegibility();
    }
  },

  unmounted() {
    this.showConfirmation = false;
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('scheduling', ['patient']),
    ...mapGetters('user', ['logged', 'user']),
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'completeProgressTrackerStep',
      'resetProgressTrackerStep',
      'openModalDialog',
      'closeModalDialog',
    ]),
    ...mapActions('scheduling', ['resetScheduling', 'setPatient']),

    close() {
      this.showConfirmation = false;
      this.$emit('reset-payment-method');
    },

    validateUserElegibility() {
      const prepaidApi = prepaidValidation({
        healthPlacePartner: this.partner.uuid,
        userEmail: this.patient.email,
        userCpf: this.patient.cpf,
      });

      this.toggleProgressBar();
      this.ui.isLoading = true;

      prepaidApi.validateEligibility()
        .then((e) => {
          if (!e.status) {
            this.close();
            const msg = `Você não está elegível para esse tipo de consulta.
              Verifique o e-mail informado e, caso o problema persista,
              entre em contato com a central de atendimento.`;
            const err = new Error(msg);
            this.handleError(err);
          } else {
            this.close();
          }
        })
        .catch(() => {
          this.close();
          const msg = `Você não está elegível para esse tipo de consulta.
            Verifique o e-mail informado e, caso o problema persista,
            entre em contato com a central de atendimento.`;
          const err = new Error(msg);
          this.handleError(err);
        })
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        });
    },

  },
};
</script>
