<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      v-if="ui.localActive"
      @close="$emit('close')"
    >
      <template v-slot:title>
        Termo de compromisso de adesão ao Health Place
      </template>

      <template v-slot:body>
        <article
          class="terms-conditions"
          v-html="termsConditions"
        />
      </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';
import termsConditions from '@/data/terms';

export default {
  name: 'TermsConditions',

  components: {
    Modal,
  },

  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },

  data() {
    return {
      termsConditions,
      ui: {
        localActive: false,
      },
    };
  },

  watch: {
    isActive: {
      deep: false,
      immediate: true,
      handler(value) {
        this.ui.localActive = value;
      },
    },
  },
};
</script>
