<style lang="scss">
.new_field {
  textarea, input, select, option {
    @apply border border-primary outline-none rounded-full px-4 h-8 text-sm w-full;
    transition: box-shadow 200ms;
    &:focus {
      box-shadow: 0 0 5px 0 var(--color-primary);
      &+strong {
        @apply text-primary;
      }
    }
  }

  input {
    @apply h-8;
  }

  @apply flex flex-col-reverse p-0;
  strong {
    @apply mb-2 ml-2 text-xs font-normal text-neutral inline;
  }
  &--invalid {
    textarea, input, select, option {
      @apply border-red;
      &+strong {
        @apply text-red;
      }
    }
  }
  &--valid {
    textarea, input, select, option {
      @apply border-green;
      &+strong {
        @apply text-green;
      }
    }
  }
  &__wrapper {
    @apply mb-4 ;
    @screen mobile {
      @apply w-full;
    }
  }
  &__errors {
    @apply text-orange m-1 ml-2 text-xs;
  }
  &__textarea {
    @apply relative;
    &.clear {
      textarea {
        @apply pr-6;
      }
    }
    p {
      @apply text-xs mt-1
    }
    i {
      @apply text-orange;
      position: absolute !important;
      right: 5px;
      top: 2rem;
    }
  }
  &__select {
    select {
      @apply h-8 #{!important};
      appearance: none;
      background: white
        var(--select-list-caret)
        no-repeat
        right 0.5rem top calc(50% + 2px);
      text-overflow: ".";

      option {
        @apply p-0 #{!important};
        text-overflow: ellipsis;
      }
    }

  }
  &__file {
    >input {
      display: none;
    }
    >div {
      @apply flex items-end mt-1;
      span {
        @apply w-auto rounded-r-none  border border-white;
      }
      input {
        @apply w-full rounded-l-none p-2 border border-red;
      }
    }
    p {
      @apply text-xs text-black  mt-1;
    }
  }
}
</style>

<template>
  <div
    class="new_field__wrapper"
    :class="returnClasses"
  >
    <component
      :is="`field-${field.type}`"
      class="new_field"
      :class="returnClass"
      :field="field"
      :field-name="fieldName"
      :validations="returnValidation"
      :sending="sending"
      :callback.sync="callback"
    />
    <ul
      class="new_field__errors"
    >
      <template v-if="(returnValidation.$invalid && returnValidation.$dirty)">
        <li
          v-for="(error, $index) in returnErrorMsgs"
          :key="'error' + $index + fieldId"
        >
          {{ error }}
        </li>
      </template>
      <template v-if="apiErrors">
        <li
          v-for="(error, $index) in apiErrors[fieldName]"
          :key="'api-error' + $index + fieldId"
        >
          {{ error }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import formUtils from '@/mixins/formUtils';
import Textarea from './_textarea.vue';
import Text from './_text.vue';
import Select from './_select.vue';
import File from './_file.vue';
import Password from './_password.vue';
import Email from './_email.vue';
import Radio from './_radio.vue';
import AutoComplete from './_autocomplete.vue';

export default {
  components: {
    'field-textarea': Textarea,
    'field-text': Text,
    'field-select': Select,
    'field-file': File,
    'field-password': Password,
    'field-email': Email,
    'field-radio': Radio,
    'field-autocomplete': AutoComplete,
  },
  mixins: [formUtils],
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    apiErrors: {
      type: Object,
      default: () => {},
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      fieldId: `field_${this.createUuid()}`,
      hasApiErrors: false,
    };
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(next) {
        if (next[this.fieldName].length > 0) {
          this.hasApiErrors = true;
        }
      },
    },
    sending(next) {
      if (next) {
        this.hasApiErrors = false;
      }
    },
  },
  computed: {
    returnClasses() {
      let str = '';
      if (this.field.classes) {
        str = this.field.classes.join(' ');
      }
      if (this.classes) {
        str = this.classes.join(' ');
      }
      if (this.field.size) {
        str = this.field.size;
      }
      return str;
    },
    returnClass() {
      let className = '';
      if (this.returnValidation.$dirty) {
        className = this.returnValidation.$invalid ? 'field--invalid' : 'field--valid';
      }
      return className;
    },
    returnValidation() {
      if (this.validations) {
        return this.validations[this.fieldName] ? this.validations[this.fieldName].value : null;
      }
      return null;
    },
    returnErrorMsgs() {
      const errors = [];
      if ((this.field.value || '').length === 0 && !this.returnValidation.required && this.returnValidation.$dirty && this.field.type !== 'file') {
        return [this.field.errors.required];
      }
      for (const field in this.returnValidation) {
        if (this.field.errors[field] && this.returnValidation[field] === false && field !== 'required') {
          errors.push(this.field.errors[field]);
        }
      }

      return errors;
    },
  },
};
</script>
