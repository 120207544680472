<template>
  <label
    class="field__textarea"
    :class="field.clear ? 'clear' : ''"
    :for="fieldId"
  >
    <p
      v-if="field.maxlength"
      :class="returnRemaining < 2 ? 'text-orange' : 'text-gray1'"
    >
      {{ returnExceded }}
    </p>
    <!-- <Clear
      v-if="field.clear"
      @clear="field.value = null; validations.$reset()"
    /> -->
    <textarea
      v-if="field.mask"
      :id="fieldId"
      v-model="field.value"
      v-mask="field.vMask"
      :style="'resize: ' + ( field.resize ? field.resize : 'none')"
      :disabled="sending || field.disabled"
      :maxlength="field.maxlength"
      :minlength="field.minlength"
      :rows="field.rows || 3"
      @blur="validations.$touch()"
    />
    <textarea
      v-else
      :id="fieldId"
      v-model="field.value"
      :style="'resize: ' + ( field.resize ? field.resize : 'none')"
      :disabled="sending || field.disabled"
      :maxlength="field.maxlength"
      :minlength="field.minlength"
      :rows="field.rows || 3"
      @blur="validations.$touch()"
    />
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
// import Clear from '@/components/actions/clear';

export default {
  components: {
    // Clear,
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
    };
  },
  computed: {
    returnRemaining() {
      return this.field.maxlength - (this.field.value || '').length;
    },
    returnExceded() {
      const isPlural = this.returnRemaining === 1 ? '' : 's';
      const msg = ` caractere${isPlural} restante${isPlural}`;
      return this.returnRemaining + msg;
    },
  },
};
</script>
