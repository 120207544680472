import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ui', ['progressTracker']),
    ...mapGetters('user', ['logged']),
  },

  methods: {
    ...mapActions('ui', ['completeProgressTrackerStep']),
    ...mapActions('scheduling', ['setAppointment']),

    completeStep(step, medicalSpecialty = null) {
      const routeType = this.logged ? 'logged' : 'unlogged';
      const nextRoute = this.progressTracker
        .find((item) => item.step === step)
        .nextRoute[routeType];

      if (medicalSpecialty) {
        this.completeProgressTrackerStep({ step: 1, status: false });
        this.completeProgressTrackerStep({ step: 2, status: false });
        this.completeProgressTrackerStep({ step: 3, status: false });
        this.setAppointment({ specialty: this.chosenSpecialty });
      }

      this.completeProgressTrackerStep({ step, status: true });
      this.$router.push({ name: nextRoute });
    },
  },
};
