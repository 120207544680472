// import store from '@/store/';
import http from '../httpConfig';

const payment = (
  partnerId,
  doctorOffice,
  startingDatetime,
  medicalSpecialtyId,
) => {
  const endpoint = `/health_place_partner/${partnerId}/agenda`;

  const startReservation = () => new Promise((resolve, reject) => {
    const data = {
      'health_place_partner_agenda': {
        doctorOfficeUuid: doctorOffice,
        agendaAppointmentStart: startingDatetime,
        medicalSpecialtyId,
      },
    };

    http.post(endpoint, data)
      .then((res) => {
        const { 'created_at': reservationStartTime, ...data } = res.data;
        resolve({ ...data, reservationStartTime });
      })
      .catch((err) => {
        reject(new Error(err?.response?.data?.errors ?? err?.response?.data));
      });
  });

  const completeReservation = (
    {
      agendaId = null,
      personalInfo = {},
      addressInfo = {},
      paymentInfo = {},
      code = null,
      isShareMedicalHistory = true,
      voucher = null,
      paymentMethod = null,
    } = {},
  ) => new Promise((resolve, reject) => {
    const url = `${endpoint}/${agendaId}/checkout`;

    const {
      name: userName,
      email: userEmail,
      cpf: userCpf,
      birthdate: userDateOfBirth,
      ddi: userDdiContactPhone,
      phoneNumber: userContactPhone,
      gender: userGender,
    } = personalInfo;

    const {
      street: addressStreet,
      number: addressNumber,
      district: addressDistrict,
      cep: addressCep,
      uf: ufName,
      city: cityName,
    } = addressInfo;

    const {
      name: creditCardHolderName,
      cardNumber: creditCardNumber,
      expirationDate: creditCardExpirationDate,
      cvc: creditCardCvv,
      installmentsNumber: installments,
    } = paymentInfo;

    const data = {
      'checkout_health_place_partner_agenda': {
        userName,
        userEmail,
        userCpf,
        userGender,
        userDateOfBirth,
        userDdiContactPhone,
        userContactPhone,
        addressStreet,
        addressNumber,
        addressDistrict,
        addressCep,
        ufName,
        cityName,
        isShareMedicalHistory: isShareMedicalHistory ? 1 : 0,
        installments: 1,
        paymentMethod,
      },
    };

    if (paymentMethod === 'CARTÃO DE CRÉDITO') {
      data['checkout_health_place_partner_agenda'].creditCardHolderName = creditCardHolderName;
      data['checkout_health_place_partner_agenda'].creditCardNumber = creditCardNumber;
      data['checkout_health_place_partner_agenda'].creditCardExpirationDate = creditCardExpirationDate;
      data['checkout_health_place_partner_agenda'].creditCardCvv = creditCardCvv;
      data['checkout_health_place_partner_agenda'].installments = installments;
    }

    if (code) {
      data['checkout_health_place_partner_agenda'].validityCode = code;
    }

    if (voucher) {
      data['checkout_health_place_partner_agenda'].prepaidVoucher = voucher;
    }

    // apagar
    delete data['checkout_health_place_partner_agenda'].userDdiContactPhone;

    http.put(url, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response.data.errors.children && typeof err.response.data.errors.children === 'object') {
          for (const erro in err.response.data.errors.children) {
            if ('errors' in err.response.data.errors.children[erro]) {
              reject(err.response.data.errors.children[erro].errors[0]);
            }
          }
        }
        reject(new Error());
      });
  });

  return {
    startReservation,
    completeReservation,
  };
};

export default payment;
