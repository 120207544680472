import http from '../httpConfig';

const prepaidValidation = ({
  healthPlacePartner = null,
  userEmail = null,
  userCpf = null,
} = {}) => {
  const partnerUuid = healthPlacePartner;
  const email = userEmail;
  const cpf = userCpf.replace(/\.|-/g, '');

  const makeQueryString = (filters) => filters.reduce(
    (acc, item) => {
      if (item.value) {
        return `${acc.startsWith('?') ? `${acc}&` : '?'}${item.query}=${item.value}`;
      }

      return acc;
    },
    '',
  );

  const validateEligibility = () => new Promise((resolve, reject) => {
    const url = `/health_place_partner/${partnerUuid}/health_place_prepaid_elegible_user`;
    const filters = [{ query: 'userEmail', value: encodeURIComponent(email) }, { query: 'userCpf', value: encodeURIComponent(cpf) }];
    const queryString = makeQueryString(filters);
    const endpoint = `${url}${queryString}`;

    http.get(endpoint)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
          && typeof err.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  const getCode = () => new Promise((resolve, reject) => {
    const url = `/health_place_partner/${partnerUuid}/health_place_prepaid_elegible_user_validity_code`;
    const data = { create: { email } };

    http.post(url, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && typeof err.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response);
      });
  });

  const validateCode = ({ code = null } = {}) => new Promise((resolve, reject) => {
    const url = `/health_place_partner/${partnerUuid}/health_place_prepaid_elegible_user_validity_code/check_validity`;
    const filters = [
      { query: 'email', value: encodeURIComponent(email) },
      { query: 'code', value: code },
    ];
    const queryString = makeQueryString(filters);
    const endpoint = `${url}${queryString}`;

    http.get(endpoint)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && typeof err.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response);
      });
  });

  return {
    validateEligibility,
    getCode,
    validateCode,
  };
};

export default prepaidValidation;
