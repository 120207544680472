import http from '../httpConfig';

const address = (config) => {
  const headers = config;
  const endpoint = '/user/address';

  const get = () => new Promise((resolve, reject) => {
    http.get(endpoint, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
        ) {
          reject(err.response.data.errors);
          return;
        }

        if (typeof err === 'string') {
          reject(err);
          return;
        }

        reject();
      });
  });

  const save = (type, address, id = null) => new Promise((resolve, reject) => {
    const conn = {
      url: `${endpoint}${id ? `/${id}` : ''}`,
      method: type,
      data: { address },
      ...headers,
    };

    http(conn)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
        ) {
          reject(err.response.data);
          return;
        }

        if (typeof err === 'string') {
          reject(err);
          return;
        }

        reject();
      });
  });

  return {
    get,
    save,
  };
};

export default address;
