<style lang="scss" scoped>
.prepaid {
  &__title {
    @apply text-lg font-semibold font-text my-4 mt-8;
    color: var(--color-text);
  }

  &__text {
    @apply text-base;
    color: var(--color-text);
    line-height: 1.8;
  }
}
</style>

<template>
  <section class="appointment-payment__col prepaid">
    <h3 class="prepaid__title">
      Instruções para agendamento através de Voucher
    </h3>

    <p class="prepaid__text mt-6">
      Ao concluir esse agendamento você receberá um e-mail de
      confirmação da transação. Você não precisará fazer nada
      em relação ao pagamento, apenas esteja atento as
      nofificações para o início de sua consulta.
    </p>

    <transition name="fade">
      <voucher-confirmation
        v-if="showConfirmation"
        @close="close"
        @voucher-confirmed="confirmElegibility"
      />
    </transition>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import voucherConfirmation from '@/components/appointmentScheduling/paymentMethods/voucherConfirmation.vue';

export default {
  name: 'Voucher',

  components: {
    voucherConfirmation,
  },

  data() {
    return {
      showConfirmation: false,
    };
  },

  mounted() {
    this.showConfirmation = true;
  },

  activated() {
    this.showConfirmation = true;
  },

  unmounted() {
    this.showConfirmation = false;
  },

  deactivated() {
    this.showConfirmation = false;
  },

  methods: {
    ...mapActions('scheduling', ['setPayment']),

    close() {
      this.showConfirmation = false;
      this.$emit('reset-payment-method');
    },

    confirmElegibility({ voucher }) {
      this.setPayment({ voucher });
    },
  },
};
</script>
