import ufs from '@/data/uf';

const formData = {
  cep: {
    name: 'cep',
    type: 'text',
    label: 'CEP',
    placeholder: '00000-000',
    mask: ['#####-###'],
    lazy: true,
    disabled: false,
    value: null,
    required: true,
    class: 'md:w-6/12 px-4',
  },
  street: {
    name: 'street',
    type: 'text',
    label: 'Rua',
    placeholder: 'Nome da rua',
    lazy: true,
    disabled: true,
    value: null,
    required: true,
    class: 'md:w-6/12 px-4',
  },
  number: {
    name: 'number',
    type: 'text',
    label: 'Número',
    placeholder: '###',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
    class: 'md:w-4/12 px-4',
  },
  complement: {
    name: 'complement',
    type: 'text',
    label: 'Complemento',
    placeholder: 'Apto',
    lazy: true,
    disabled: false,
    value: null,
    required: false,
    class: 'md:w-4/12 px-4',
  },
  district: {
    name: 'district',
    type: 'text',
    label: 'Bairro',
    placeholder: 'Bairro',
    lazy: true,
    disabled: true,
    value: null,
    required: true,
    class: 'md:w-4/12 px-4',
  },
  city: {
    name: 'city',
    type: 'text',
    label: 'Cidade',
    placeholder: 'Cidade',
    lazy: true,
    disabled: true,
    value: null,
    required: true,
    class: 'md:w-6/12 px-4',
  },
  uf: {
    name: 'uf',
    type: 'select',
    label: 'Estado',
    placeholder: 'UF',
    lazy: true,
    disabled: true,
    value: null,
    required: true,
    class: 'md:w-6/12 px-4',
    options: ufs,
  },
};

export default formData;
