<template>
  <label
    class="field__file"
    :for="fieldId"
  >
    <p v-if="field.exts">
      Extensões permitidas:
      <span
        v-for="(ext, $index) of field.exts"
        :key="fieldId + 'ext' + ext"
      >
        {{ ext.toUpperCase() }}{{ $index + 1 !== field.exts.length ? ',' : '.' }}
      </span>
    </p>
    <div>
      <a
        :disabled="sending || field.disabled"
        class="btn btn-neutral"
      >Selecionar</a>
      <input
        :value="fileName"
        :placeholder="returnPlaceholder"
        disabled
        type="text"
      >
    </div>
    <input
      :id="fieldId"
      :accept="field.exts"
      :disabled="sending || field.disabled"
      type="file"
      @change="selectFile($event)"
    >
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
      fileName: null,
      errorPlaceholder: null,
    };
  },
  computed: {
    returnPlaceholder() {
      return this.errorPlaceholder || this.field.placeholder || 'Nenhum arquivo';
    },
  },
  methods: {
    selectFile(ev) {
      if (ev.target.files.length > 0) {
        this.validations.$touch();
        this.fileName = ev.target.files[0].name;
        const val = ev.target.files[0];
        this.field.value = val;
        if (!this.validations.$invalid) {
          this.errorPlaceholder = null;
          return;
        }
        this.errorPlaceholder = 'Formato inválido';
      }
      this.field.value = null;
      this.fileName = '';
    },
  },
};
</script>
