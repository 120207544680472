<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <modal @close="close">
      <template v-slot:title>
        Confirmação de identidade
      </template>

      <template v-slot:body>
        <transition name="blend">
          <template v-if="ui.step === 1">
            <div class="login-confirmation">
              <p class="login-confirmation__text">
                Confira seu endereço de e-mail abaixo e clique em Validar Cadastro
                para receber o código de validação e confirmar sua eligibilidade
                à consulta pré-paga.
              </p>

              <form @submit.prevent="validateUser">
                <div class="login-confirmation__wrapper login-confirmation__wrapper--row">
                  <field
                    :ref="'email-address'"
                    v-model="emailAddress"
                    :disabled="true"
                    type="email"
                    class="prepaid__email"
                    placeholder="Endereço de e-mail"
                  />
                </div>

                <div class="login-confirmation__footer">
                  <div class="login-confirmation__button login-confirmation__button--larger">
                    <ui-button
                      :disabled="ui.isLoading || $v.emailAddress.$invalid"
                      label="Validar cadastro"
                      @click="validateUser"
                    />
                  </div>
                </div>
              </form>
            </div>
          </template>

          <template v-if="ui.step === 2">
            <div class="login-confirmation">
              <p class="login-confirmation__text">
                Digite abaixo o código recebido por e-mail:
              </p>

              <div
                :class="{ 'cursor-not-allowed': ui.isLoading }"
                class="login-confirmation__wrapper login-confirmation__wrapper--row"
              >
                <input
                  :ref="'confirmation-code'"
                  v-model="code"
                  v-mask="mask"
                  :type="inputType"
                  :class="{ 'pointer-events-none': ui.isLoading }"
                  class="login-confirmation__code"
                  placeholder="●●●●●●"
                >
              </div>

              <div class="login-confirmation__footer">
                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading"
                    color="secondary"
                    label="Reenviar código"
                    @click="resendConfirmationCode"
                  />
                </div>

                <div class="login-confirmation__button">
                  <ui-button
                    :disabled="ui.isLoading || !code || code.length < 6"
                    label="Confirmar"
                    @click="validateConfirmationCode"
                  />
                </div>
              </div>
            </div>
          </template>
        </transition>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { mask } from 'ke-the-mask';
import prepaidValidation from '@/api/appointmentScheduling/prepaid';
import login from '@/api/appointmentScheduling/login';
import handleError from '@/mixins/handleError';
import Modal from '@/components/ui/Modal.vue';
import Field from '@/components/ui/Field.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'PrepaidConfirmation',

  directives: { mask },

  mixins: [handleError],

  components: {
    Modal,
    Field,
    UiButton,
  },

  validations: {
    emailAddress: {
      required,
      email,
    },
  },

  data() {
    return {
      ui: {
        step: 1,
        isLoading: false,
      },
      emailAddress: null,
      code: null,
      isRegisteredUser: false,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('scheduling', ['patient']),

    isEmailAddressValid() {
      if (this.$v.emailAddress.$model === null) {
        return null;
      }

      return !this.$v.emailAddress.$invalid;
    },

    isEmailAddressInvalid() {
      if (this.$v.emailAddress.$model === null) {
        return null;
      }

      return this.$v.emailAddress.$invalid;
    },

    initialRoute() {
      return this.logged ? 'UserScheduling' : 'AppointmentScheduling';
    },

    mask() {
      if (this.isRegisteredUser) {
        return ['######'];
      }

      return ['XXXXXX'];
    },

    inputType() {
      if (this.isRegisteredUser) {
        return 'tel';
      }

      return 'text';
    },
  },

  created() {
    this.$v.emailAddress.$reset();
  },

  mounted() {
    this.emailAddress = this.patient.email;
    this.userCpf = this.patient.cpf;
  },

  destroyed() {
    this.emailAddress = null;
    this.prepaidValidation = null;
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'completeProgressTrackerStep',
      'resetProgressTrackerStep',
      'openModalDialog',
      'closeModalDialog',
    ]),
    ...mapActions('scheduling', ['resetScheduling', 'setPatient']),

    close() {
      this.$emit('close');
    },

    validateUser() {
      const prepaidApi = prepaidValidation({
        healthPlacePartner: this.partner.uuid,
        userEmail: this.emailAddress,
        userCpf: this.userCpf,
      });

      this.toggleProgressBar();
      this.ui.isLoading = true;

      prepaidApi.validateEligibility()
        .then(() => {
          const loginApi = login(this.emailAddress);

          this.toggleProgressBar();
          this.ui.isLoading = true;

          loginApi.checkUser(this.partner.uuid)
            .then(() => loginApi.sendConfirmationCode('EMAIL', this.partner.uuid))
            .then(() => {
              this.isRegisteredUser = true;
              this.ui.step = 2;
            })
            .catch(() => {
              this.toggleProgressBar();
              this.ui.isLoading = true;

              prepaidApi.getCode()
                .then(() => {
                  this.ui.step = 2;
                })
                .catch((err) => this.handleError(err))
                .finally(() => {
                  this.toggleProgressBar(false);
                  this.ui.isLoading = false;
                });
            })
            .catch((err) => {
              this.close();
              this.handleError(err);
            })
            .finally(() => {
              this.toggleProgressBar(false);
              this.ui.isLoading = false;
            });
        })
        .catch(() => {
          this.close();
          const msg = `Você não está elegível para esse tipo de consulta.
            Verifique o e-mail informado e, caso o problema persista,
            entre em contato com a central de atendimento.`;
          const err = new Error(msg);
          this.handleError(err);
        })
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
        });
    },

    resendConfirmationCode() {
      const prepaidArgs = {
        healthPlacePartner: this.partner.uuid,
        userEmail: this.emailAddress,
        userCpf: this.userCpf,
      };

      const sendConfirmationFn = this.isRegisteredUser
        ? () => login(this.emailAddress).sendConfirmationCode('EMAIL', this.partner.uuid)
        : () => prepaidValidation(prepaidArgs).getCode();

      this.ui.isLoading = true;
      this.toggleProgressBar();

      sendConfirmationFn()
        .then(() => {
          const dialog = {
            type: 'alert',
            title: 'Sucesso',
            text: 'O código de confirmação foi reenviado com sucesso.',
            confirmText: 'Fechar',
            size: 'sm',
            fn: () => this.closeModalDialog(),
          };
          this.openModalDialog(dialog);
        })
        .catch(() => this.handleError(new Error('Erro ao enviar o código.')))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    validateConfirmationCode() {
      const prepaidArgs = {
        healthPlacePartner: this.partner.uuid,
        userEmail: this.emailAddress,
        userCpf: this.userCpf,
      };
      const confirmationFn = this.isRegisteredUser
        ? () => login(this.emailAddress).confirmLogin(this.code)
        : () => prepaidValidation(prepaidArgs).validateCode({ code: this.code });
      this.ui.isLoading = true;
      this.toggleProgressBar();

      confirmationFn()
        .then((data) => {
          this.setPatient({ email: this.emailAddress });

          if (this.isRegisteredUser) {
            this.$emit('login-confirmed', data);
            return;
          }

          this.$emit('eligibility-confirmed', { code: this.code });
          window.localStorage.setItem('code', JSON.stringify({ code: this.code }));
          this.$emit('close');
        })
        .catch(() => {
          this.openModalDialog({
            type: 'confirmation',
            title: 'Erro',
            text: 'O código informado é inválido.',
            cancelText: 'Tentar novamente',
            confirmText: 'Reenviar código',
            fn: () => {
              this.code = null;
              this.closeModalDialog();
              this.resendConfirmationCode();
            },
          });
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>

<style lang="scss">
.prepaid {
  &__email {
    @apply flex flex-row items-center justify-center w-full #{!important};

    div {
      @apply w-64 #{!important};
      width: 100%;

      input {
        @apply h-12 w-64 p-2 #{!important};
        width: 100%;

        &:active,
        &:focus {
          @apply bg-gray-100 #{!important};
        }
      }
    }
  }
}

.blend {
  &-enter {
    opacity: 0;
    display: none;
    height: auto;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      height: auto;
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
