<template>
  <label
    class="field__text"
    :class="validations"
    :for="fieldId"
  >
    <input
      v-if="field.mask"
      :id="fieldId"
      v-model="field.value"
      v-mask="field.mask"
      type="text"
      :minlength="field.minlength"
      :maxlength="field.maxlength"
      :disabled="sending || field.disabled"
      :placeholder="field.placeholder"
      @input="validations.$reset()"
      @focus="validations.$reset()"
      @blur="validations.$touch()"
    >
    <input
      v-else
      :id="fieldId"
      v-model="field.value"
      type="text"
      :minlength="field.minlength"
      :maxlength="field.maxlength"
      :disabled="sending || field.disabled"
      :placeholder="field.placeholder"
      @input="validations.$reset()"
      @focus="validations.$reset()"
      @blur="validations.$touch()"
    >
    <strong>{{ field.label }}</strong>
  </label>
</template>

<script>
import { mask } from 'ke-the-mask';

export default {
  directives: {
    mask,
  },

  props: {
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
    };
  },
};
</script>
