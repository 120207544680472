<template>
  <label
    class="field__text relative"
    :class="validations"
    :for="fieldId"
  >
    <input
      :id="fieldId"
      v-model="searchString.name"
      v-mask="field.mask"
      type="text"
      :minlength="field.minlength"
      :maxlength="field.maxlength"
      :disabled="sending || field.disabled"
      :placeholder="field.placeholder"
      @input="validations.$reset()"
      @focus="validations.$reset()"
      @blur="validations.$touch()"
    >
    <strong>{{ field.label }}</strong>

    <transition
      name="fade"
      mode="in-out"
    >
      <ul
        v-if="results.length > 0"
        class="field__result-list"
      >
        <li
          v-for="result in results"
          :key="`autocomplete-result-item-${result.id}`"
          class="field__result-item"
          @click="setResult(result)"
        >
          {{ result.name }}
        </li>
      </ul>
    </transition>
  </label>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'ke-the-mask';
import { debounce } from 'vue-debounce';

export default {
  directives: {
    mask,
  },

  props: {
    fieldName: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    validations: {
      type: Object,
      default: null,
    },

    sending: {
      type: Boolean,
      default: false,
    },

    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      fieldId: Math.floor(Math.random() * 1000),
      shouldSearch: true,
      results: [],
      searchString: {
        id: null,
        name: null,
      },
    };
  },

  watch: {
    'searchString.id': {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.$set(this.field, 'value', newValue);
        }
      },
    },

    'searchString.name': {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue && this.shouldSearch) {
          this.search();
          return;
        }

        if (newValue === '') {
          this.results = [];
        }
      },
    },

    shouldSearch: {
      deep: false,
      immediate: false,
      handler(value) {
        if (!value) {
          setTimeout(() => {
            this.shouldSearch = true;
          }, 1000);
        }
      },
    },
  },

  created() {
    this.searchString.name = this.field.value;
    this.shouldSearch = true;
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    search: debounce(function () {
      this.results = [];
      this.field.disabled = true;
      this.toggleProgressBar();

      this.callback(this.searchString.name)
        .then((res) => {
          if (res.length > 0) {
            this.results = res;
            return;
          }

          this.results.push({ id: 0, name: 'Serviço médico não encontrado.' });
        })
        .finally(() => {
          this.field.disabled = false;
          this.toggleProgressBar(false);
        });
    }, 1000),

    setResult(result) {
      if (result.id > 0) {
        this.searchString.name = result.name;
        this.searchString.id = result.id;
        this.results = [];
        this.shouldSearch = false;
        return;
      }

      this.searchString.name = null;
      this.results = [];
    },
  },
};
</script>
