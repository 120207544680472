<template functional>
  <div
    v-scroll-lock="true"
    class="loader"
  >
    <div class="loader__spinner" />
    <div
      class="loader__message"
      v-if="props.message"
    >
      {{ props.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    message: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>
