import {
  required, maxLength, minLength, email, sameAs,
} from 'vuelidate/lib/validators';

export default {
  methods: {
    createUuid() {
      return Math.floor(Math.random() * 1000);
    },
    buildErrors(form) {
      const obj = {};
      for (const field in form) {
        obj[field] = [];
      }
      return obj;
    },
    buildData(form) {
      const obj = {};
      for (const field in form) {
        obj[field] = form[field].value;
      }
      return obj;
    },
    toCamelcase(s) {
      return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
    },
    isObj(v) {
      return Object.prototype.toString.call(v) === '[object Object]';
    },
    flattenObject(obj) {
      const self = this;
      const newObj = {};
      let propname = '';
      const index = Object.keys(obj);
      let i = 0;
      const promise = new Promise(((resolve) => {
        function iterate(obj, propName) {
          for (const prop in obj) {
            propname = propName ? self.toCamelcase(`${propName}.${prop}`) : self.toCamelcase(prop);
            if (self.isObj(obj[prop]) || prop === 'children') {
              iterate(obj[prop], propname);
            } else {
              newObj[propname] = obj[prop];
            }
            if (i === index.length - 1) {
              resolve(newObj);
            }
            i += 1;
          }
        }
        iterate(obj);
      }));
      return promise;
    },
    expandForm(obj) {
      function iterateField(obj, field, value) {
        const parts = field.split('.');
        let o = obj;
        if (parts.length > 1) {
          /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
          for (let i = 0; i < parts.length - 1; i += 1) {
            if (!o[parts[i]]) {
              o[parts[i]] = {};
            }
            o = o[parts[i]];
          }
        }
        o[parts[parts.length - 1]] = value;
      }
      const newObj = {};
      for (const field in obj) {
        iterateField(newObj, field, obj[field].value);
      }
      return newObj;
    },

    buildValidations(form) {
      const obj = {};
      for (const field in form) {
        obj[field] = {
          value: {},
        };
        form[field].errors = form[field].errors || {};
        const targetField = obj[field].value;
        if (form[field].required) {
          targetField.required = required;
          form[field].errors.required = 'Campo obrigatório';
        }
        if (form[field].minlength) {
          targetField.minLength = minLength(form[field].minlength);
          form[field].errors.minLength = form[field].errors.minLength || `Mínimo: ${form[field].minlength}`;
        }
        if (form[field].maxlength) {
          targetField.maxLength = maxLength(form[field].maxlength);
          form[field].errors.maxLength = form[field].errors.maxLength || `Limite: ${form[field].maxlength}`;
        }
        if (form[field].type === 'email') {
          targetField.email = email;
          form[field].errors.email = form[field].errors.email || 'E-mail inválido';
        }
        if (form[field].sameAs) {
          targetField.sameAsValue = sameAs(() => form[form[field].sameAs].value);
          form[field].errors.sameAsValue = form[field].errors.sameAsValue || 'Não confere';
        }
        if (form[field].isValid) {
          targetField.isValid = (v) => form[field].isValid(v);
          form[field].errors.isValid = form[field].errors.isValid || 'Valor inválido';
        }

        if (form[field].isValidPhoneNumber) {
          targetField.isValidPhoneNumber = () => form[field].isValidPhoneNumber(
            form.contactPhone.value, form.ddi.value,
          );
          form[field].errors.isValidPhoneNumber = form[field].errors.isValid || 'Valor inválido';
        }

        if (form[field].exts) {
          targetField.isExt = (v) => {
            if (!v) {
              return false;
            }
            const split = v.name.toLowerCase().split('.');
            form[field].errors.isExt = 'Formato não permitido';
            if (form[field].exts.includes(`.${split[split.length - 1]}`)) {
              return true;
            }
            return false;
          };
        }
      }
      return obj;
    },
    handleApiErrors(err, apiErrors) {
      if (err.response.data.status === 400 || err.response.data.code === 400) {
        // console.log(err.response);
        this.flattenObject(err.response.data.errors.children).then(
          (res) => {
            for (const field in res) {
              this.$set(apiErrors, field.replace(/.children|.errors/g, ''), res[field]);
            }
          },
        );
      }
    },
    populateForm(form, data) {
      for (const field in data) {
        if (form[this.toCamelcase(field)]) {
          form[this.toCamelcase(field)].value = data[field];
        }
      }
      return form;
    },
    clearForm(form) {
      for (const field in form) {
        form[field].value = null;
      }
      return form;
    },
  },
};
